import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  addStoreImage,
  deleteStoreImage,
  fetchStoreById,
  updateAddress,
  updateStore,
  updateStoreOpeningHours,
} from "./stores";
import { queryClient } from "../../../../../../App";

export const storeQueryOptions = (storeId: number | string) =>
  queryOptions({
    queryKey: ["stores", storeId],
    queryFn: () => fetchStoreById(storeId),
  });

export const useUpdateAddressMutation = (addressId: number | string) => {
  return useMutation({
    mutationKey: ["address", addressId],
    mutationFn: updateAddress,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useUpdateStoreMutation = (storeId: number | string) => {
  return useMutation({
    mutationKey: ["store", storeId],
    mutationFn: updateStore,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useUpdateStoreOpeningHoursMutation = (
  storeId: number | string
) => {
  return useMutation({
    mutationKey: ["store", "openinghour", storeId],
    mutationFn: updateStoreOpeningHours,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteStoreImage = (storeId: number, imageId: number) => {
  return useMutation({
    mutationKey: ["store", imageId, storeId],
    mutationFn: deleteStoreImage,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddStoreImage = (storeId: number, catagoryId: number) => {
  return useMutation({
    mutationKey: ["store", catagoryId, storeId],
    mutationFn: addStoreImage,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
