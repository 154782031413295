"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { BadgeProps } from "./Badge.types";

const Badge: React.FC<BadgeProps> = (props) => {
  const {
    className,
    children,
    badgeContent,
    icon,
    iconLeft,
    iconRight,
    size = "large",
    invisible = false,
  } = props;

  let badgeClass = "scale-100 transition ease-in-out duration-300 transform ";
  let badgeSize = size === "large" ? "w-max" : "h-2.5 w-2.5";

  if (size === "large") {
    badgeClass += cs(
      "flex cursor-pointer rounded-full bg-error text-on-error ",
      badgeSize,
      "w-max h-5 items-center gap-1 text-[11px] leading-4 tracking-[0.5px] p-1.5"
    );
  }

  if (size === "small") {
    badgeClass += cs(
      "flex cursor-pointer rounded-full bg-error text-on-error ",
      badgeSize,
      "p-[4px]"
    );
  }

  return (
      <div className="relative inline-flex align-middle shrink-0">
        {children && children}
        <div
          className={cs(
            children && "flex place-content-center items-center absolute box-border leading-none h-5 z-[1] translate-x-2/4 -translate-y-2/4 origin-[100%_0%] px-1.5 py-0 rounded-[10px] scale-100 right-0 top-0",
            badgeClass,
            invisible && "scale-0",
            className
          )}
        >
          {size === "large" && iconLeft && iconLeft}
          {size === "large" && icon && icon}
          {size === "large" && badgeContent && <span className="relative">{badgeContent}</span>}
          {size === "large" && iconRight && iconRight}
        </div>
      </div>
  );
};

export default Badge;
