import { useState, useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import { cs } from "@pnpm-monorepo/core/src/utils";

type BarcodeProps = {
  barcode: string;
  barcodeType?: string;
};

export function Barcode(props: BarcodeProps) {
  const [isValid, setIsValid] = useState<boolean>(false);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      try {
        JsBarcode(svgRef.current, props.barcode, {
          format: props.barcodeType,
        });
        setIsValid(true);
      } catch (error) {
        setIsValid(false);
      }
    }
  }, [props.barcode, props.barcodeType]);

  return (
    <>
      <div
        className={cs(
          "p-4 my-2 rounded-small bg-secondary-container",
          !isValid && "hidden"
        )}
      >
        <svg ref={svgRef} />
      </div>
    </>
  );
}
