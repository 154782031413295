import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import { SystemSettingType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import {
  SystemSettingCommandType,
  useUpdateSettingeMutation,
} from "../../../../utils/data/systemsetting";

const schema = z.object({
  value: z.string().min(1, "Värde är obligatoriskt"),
});

const EditDialog = ({ obj }: { obj: SystemSettingType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useUpdateSettingeMutation(obj.key);

  const { control, handleSubmit, reset } = useForm<SystemSettingCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      value: obj.value,
    },
  });

  const onSubmit: SubmitHandler<SystemSettingCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        key: obj.key,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleEdit} icon={<Icon icon="edit" size={22} />} />
      <Dialog
        icon={<Icon icon="edit" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Uppdatera systeminställning?"
        text={obj.key}
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="mt-2">
          <div className="mb-6 rounded-small text-label-large p-3 bg-secondary-container text-on-secondary-container">
            {obj.description}
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Värde
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="value" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDialog;
