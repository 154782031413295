import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";

import { useAddOffer } from "../-api/queryOptions";
import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";
import { companyCanCreateOfferQueryOptions } from "../../-api/queryOptions";

const AddOfferDialog = ({ companyId }: { companyId: string }) => {
  const [open, setOpen] = useState<boolean>(false);

  const companyCanCreateOfferQuery = useQuery(
    companyCanCreateOfferQueryOptions(companyId)
  );
  const companyCanCreateOffer = companyCanCreateOfferQuery.data;
  const navigate = useNavigate({ from: `/companies/${companyId}/offers` });
  const addOffer = useAddOffer(companyId);

  function handleAddOffer(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    addOffer.mutate(
      {
        companyId: companyId,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/companies/$companyId/offers/$offerId",
            search: (old) => ({
              ...old,
            }),
            params: { companyId: companyId, offerId: data },
          });
          setOpen(false);
        },
      }
    );
  }

  return (
    <>
      {companyCanCreateOffer ? (
        <Button
          iconLeft={<MdAdd size={20} />}
          variant="filled"
          onClick={handleAddOffer}
        >
          Lägg till
        </Button>
      ) : (
        <>
          {!companyCanCreateOfferQuery.isPending && (
            <div className="rounded-medium p-2 px-4 text-body-medium bg-error-container text-on-error-container">
              Erbjudanden kan inte läggas till. Aktiva kontrakt, produkter eller
              butiker saknas.
            </div>
          )}
        </>
      )}
      <Dialog
        icon={<MdAdd />}
        open={open}
        onClose={handleClose}
        headline="Lägg till nytt erbjudande?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={addOffer.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default AddOfferDialog;
