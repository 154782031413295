import { useState } from "react";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  LinkBox,
  Disclosure,
  Icon,
  Button,
  DeviceMockup,
} from "@pnpm-monorepo/core/src/ui/components";

import { ErrorComponent } from "../../../components/ErrorComponent";

import DefaultPendingComponent from "../../../components/PendingComponent";
import { offerQueryOptions } from "./-api/queryOptions";
import { TermsFields } from "./-components/TermsFields";
import { OfferFields } from "./-components/OfferFields";
import { ImageFields } from "./-components/ImageFields";
import { ProductPlaceFields } from "./-components/ProductPlaceFields";

import { SmallDateString } from "../../../utils/renderers";
import StatusChange from "./-components/StatusChange";
import Places from "./-components/Places";

export const Route = createFileRoute("/offers/$offerId/")({
  loader: ({ context: { queryClient }, params: { offerId } }) => {
    queryClient.ensureQueryData(offerQueryOptions(offerId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: OfferComponent,
});

export function OfferComponent() {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState<boolean>(false);
  const uniquePlaceNames = new Set<string>();
  const params = Route.useParams();
  const offerQuery = useSuspenseQuery(offerQueryOptions(params.offerId));
  const offer = offerQuery.data;

  const uniquePlaces = offer.contractProductPlaces?.filter((cpp) => {
    const { place } = cpp;
    if (place && !uniquePlaceNames.has(place.name)) {
      uniquePlaceNames.add(place.name);
      return true;
    }
    return false;
  });

  function copyUrlToClipboard() {
    const url = `https://proof.mobilkupongen.se/${offer.company.companyGuid}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        enqueueSnackbar(
          "Publik länk till erbjudanden (korrektur) kopierad till urklipp"
        );
      })
      .catch((err) => {
        console.error("Failed to copy the URL: ", err);
      });
  }

  return (
    <div className="flex flex-col lg:flex-row w-full gap-4">
      {!edit && (
        <div className="flex flex-col w-full">
          <Card>
            <CardHeader
              title={
                !offer.offerWorthText &&
                !offer.offerPrepositionText &&
                !offer.offerServiceText ? (
                  <span className="">&lt;Ej angivet&gt;</span>
                ) : (
                  <span className="">
                    {offer.offerWorthText} {offer.offerPrepositionText}{" "}
                    {offer.offerServiceText}
                  </span>
                )
              }
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(true)}
                    iconLeft={<Icon icon="edit" size={20} />}
                  >
                    Redigera
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(true)}
                    icon={<Icon icon="edit" />}
                  />
                </>
              }
            />
            <CardContent>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Nummer
                </div>
                <div className="text-body-medium">{offer.number}</div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Företag
                </div>
                <div className="text-body-medium">
                  <LinkBox>
                    <Link
                      to={`/companies/$companyId`}
                      params={{ companyId: offer.company.id }}
                    >
                      {offer.company.number} - {offer.company.name}
                    </Link>
                  </LinkBox>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Produkt
                </div>
                <div className="text-body-medium">
                  {offer.product ? (
                    offer.product.name
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Område
                </div>
                <Places uniquePlaces={uniquePlaces} />
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Butik
                </div>
                <div className="text-body-medium">
                  {offer.stores?.length ? (
                    offer.stores.map((store) => {
                      return <div key={store.id}>{store?.name}</div>;
                    })
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Sorteringsprioritet
                </div>
                <div className="text-body-medium">{offer.priority}</div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Antalet dagar erbjudandet gäller för konsumenten
                </div>
                <div className="text-body-medium">
                  {offer.maxConsumerDays === 0
                    ? offer.maxConsumerDays + " (180)"
                    : offer.maxConsumerDays}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Giltighetstid
                </div>
                <div className="text-body-medium">
                  Fr.o.m{" "}
                  {offer.validFrom ? (
                    <span>
                      <SmallDateString value={offer.validFrom} />
                    </span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}{" "}
                  → T.o.m.{" "}
                  {offer.validTo ? (
                    <span>
                      <SmallDateString value={offer.validTo} />
                    </span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              {offer.pauseFrom && (
                <div className="mb-4">
                  <div className="text-label-medium text-on-surface-variant">
                    Pausat
                  </div>
                  <div className="text-body-medium">
                    Fr.o.m{" "}
                    {offer.pauseFrom ? (
                      <span>
                        <SmallDateString value={offer.pauseFrom} />
                      </span>
                    ) : (
                      <span className="">&lt;Ej angivet&gt;</span>
                    )}{" "}
                    → T.o.m.{" "}
                    {offer.pauseTo ? (
                      <span>
                        <SmallDateString value={offer.pauseTo} />
                      </span>
                    ) : (
                      <span className="">&lt;Ej angivet&gt;</span>
                    )}
                  </div>
                </div>
              )}
              {offer.barcode && (
                <>
                  <div className="mb-4">
                    <div className="text-label-medium text-on-surface-variant">
                      Streckkod
                    </div>
                    <div className="text-body-medium">
                      {offer.barcodeType} - {offer.barcode}
                    </div>
                  </div>
                </>
              )}
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Status
                </div>
                <div className="text-body-medium">{offer.status?.name}</div>
              </div>

              {offer.company.companyGuid && (
                <>
                  <div className="my-4 flex items-center gap-2">
                    <a
                      href={`https://proof.mobilkupongen.se/${offer.company.companyGuid}`}
                      target="_blank"
                      className="text-body-medium"
                    >
                      <LinkBox>
                        Publik länk till erbjudanden (korrektur){" "}
                      </LinkBox>
                    </a>
                    <IconButton
                    size="small"
                      icon={<Icon icon="content_copy" size={20} />}
                      onClick={copyUrlToClipboard}
                    />
                  </div>
                </>
              )}
            </CardContent>
          </Card>

          {/* <StatusChange offer={offer} /> */}
        </div>
      )}
      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Utformning"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <div className="h-2" />
          <Disclosure
            header="Produkt, placering och kategori"
            defaultOpen={true}
          >
            <div className="px-3">
              <ProductPlaceFields offer={offer} />
            </div>
          </Disclosure>
          <Disclosure header="Bild">
            <div className="px-3">
              <ImageFields offer={offer} />
            </div>
          </Disclosure>
          <Disclosure header="Erbjudande">
            <div className="px-3">
              <OfferFields offer={offer} />
            </div>
          </Disclosure>
          <Disclosure header="Villkor">
            <div className="px-3">
              <TermsFields offer={offer} />
            </div>
          </Disclosure>
        </Card>
      )}

      <div className="flex justify-center lg:block mt-4 lg:mt-0">
        <div className="sticky top-[156px]">
          <Card>
            <CardHeader title={`Erbjudande ${offer.number}`} />
            <DeviceMockup {...offer} className="" />
          </Card>
        </div>
      </div>
    </div>
  );
}
