import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
  Value,
} from "@pnpm-monorepo/core/src/ui/components";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { ErrorComponent, createFileRoute } from "@tanstack/react-router";
import React from "react";
import {
  consumerGendersQueryOptions,
  consumerQueryOptions,
  useConsumerMutation,
} from "../-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { SmallDateString } from "../../../../utils/renderers";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";

import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale

import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import { YesNo } from "../../../../utils/renderers";
import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import { ConsumerCommandType } from "../-api/consumers";

dayjs.locale("sv"); // use locale

export const Route = createFileRoute("/consumers/$consumerId/properties")({
  component: ConsumerPropertiesComponent,
  loader: ({ context: { queryClient }, params: { consumerId } }) => {
    queryClient.ensureQueryData(consumerQueryOptions(consumerId));
    queryClient.ensureQueryData(consumerGendersQueryOptions());
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function ConsumerPropertiesComponent() {
  const [edit, setEdit] = React.useState(false);
  const params = Route.useParams();

  const consumerGendersQuery = useQuery(consumerGendersQueryOptions());
  const consumerQuery = useSuspenseQuery(
    consumerQueryOptions(params.consumerId)
  );

  const consumer = consumerQuery.data;

  const mutation = useConsumerMutation(params.consumerId);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...consumer,
      firstName: consumer.firstName ?? "",
      lastName: consumer.lastName ?? "",
      gender: consumer.gender,
      dateOfBirth: consumer.dateOfBirth
        ? dayjs(consumer.dateOfBirth).format("YYYY-MM-DD")
        : undefined,
      mobileNumber: consumer.mobileNumber ?? "",
      addressline1: consumer.addressLine1 ?? "",
      addressline2: consumer.addressLine2 ?? "",
      addressline3: consumer.addressLine3 ?? "",
      addressline4: consumer.addressLine4 ?? "",
    },
  });

  const onSubmit: SubmitHandler<ConsumerCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        dateOfBirth: data.dateOfBirth === "" ? null : data.dateOfBirth,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  return (
    <div className="flex w-full flex-col lg:flex-row ">
      <div className="flex w-full flex-col lg:flex-row ">
        {!edit ? (
          <Card>
            <CardHeader
              title="Övriga uppgifter"
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(true)}
                    iconLeft={<Icon icon="edit" size={20} />}
                  >
                    Redigera
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(true)}
                    icon={<Icon icon="edit" size={20} />}
                  />
                </>
              }
            />

            <CardContent>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Namn
                </div>
                <div className="text-body-medium">
                  {consumer.fullName ? (
                    <span>{consumer.fullName}</span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Kön
                </div>
                <div className="text-body-medium">
                  {consumer.gender ? (
                    <span>{consumer.gender.name}</span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Födelsedatum
                </div>
                <div className="text-body-medium">
                  {consumer.dateOfBirth ? (
                    <span>
                      <SmallDateString value={consumer.dateOfBirth} />
                    </span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Telefon
                </div>
                <div className="text-body-medium">
                  {consumer.mobileNumber ? (
                    <span>{consumer.mobileNumber}</span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Adress
                </div>
                <div className="text-body-medium">
                  {consumer.formattedAddress &&
                  consumer.formattedAddress !== ",  " ? (
                    <span>{consumer.formattedAddress}</span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Jag har läst och accepterat användarvillkoren
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptTermsOfCondition} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Jag har läst och accepterat integritetspolicyn
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptPrivacyPolicy} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Push-notiser
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptPushNotice} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Utskick av nyhetsbrev
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptNewsLetter} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Lagring av geografisk position
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptGeoLocationStorage} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Lagring av hur appen används
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptUserBehaviorStorage} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Personalisering
                </div>
                <div className="text-body-medium">
                  <YesNo value={consumer.acceptPersonalizing} />
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card className="" variant="outlined">
            <CardHeader
              title={"Övriga uppgifter"}
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(false)}
                    iconLeft={<Icon icon="close" size={20} />}
                  >
                    Avsluta redigering
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(false)}
                    icon={<Icon icon="close" />}
                  />
                </>
              }
            />
            <CardContent className=" border-t-[1px] border-outline-variant">
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Förnamn
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="firstName" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Efternamn
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="lastName" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Kön
                </div>
                <div className="pt-2">
                  {!consumerGendersQuery.isPending && (
                    <SelectWrapper
                      name="gender"
                      control={control}
                      className="min-w-32"
                      options={consumerGendersQuery.data}
                      getOptionLabel={(option: Value) => option?.name}
                    />
                  )}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Födelsedatum
                </div>
                <div className="pt-2">
                  <TextFieldWrapper
                    name="dateOfBirth"
                    control={control}
                    variant="date"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Telefon
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="mobileNumber" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Adress
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressline1" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Co
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressline2" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Postnummer
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressline3" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Postort
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressline4" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Jag har läst och accepterat användarvillkoren
                </div>
                <div className="pt-2">
                  <SwitchWrapper
                    name="acceptTermsOfCondition"
                    control={control}
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Jag har läst och accepterat integritetspolicyn
                </div>
                <div className="pt-2">
                  <SwitchWrapper name="acceptPrivacyPolicy" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Push-notiser
                </div>
                <div className="pt-2">
                  <SwitchWrapper name="acceptPushNotice" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Utskick av nyhetsbrev
                </div>
                <div className="pt-2">
                  <SwitchWrapper name="acceptNewsLetter" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Lagring av geografisk position
                </div>
                <div className="pt-2">
                  <SwitchWrapper
                    name="acceptGeoLocationStorage"
                    control={control}
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Lagring av hur appen används
                </div>
                <div className="pt-2">
                  <SwitchWrapper
                    name="acceptUserBehaviorStorage"
                    control={control}
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Personalisering
                </div>
                <div className="pt-2">
                  <SwitchWrapper name="acceptPersonalizing" control={control} />
                </div>
              </div>
            </CardContent>
            <div className="flex justify-end gap-2 p-3">
              <Button
                variant="filled"
                onClick={handleSubmit(onSubmit)}
                disabled={mutation.isPending}
              >
                Spara & stäng
              </Button>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}
