import { Link, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { LinkBox, Table } from "@pnpm-monorepo/core/src/ui/components";

import { ErrorComponent } from "../../../../components/ErrorComponent";
import { companyStoresQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { YesNo } from "../../../../utils/renderers";

import AddNewModal from "./-components/AddNewModal";

export const Route = createFileRoute("/companies/$companyId/stores")({
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyStoresQueryOptions(companyId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyStoresComponent,
});

function CompanyStoresComponent() {
  const params = Route.useParams();
  const companyStoresQuery = useSuspenseQuery(
    companyStoresQueryOptions(params.companyId)
  );
  const companyStores = companyStoresQuery.data;

  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-end">
        <AddNewModal companyId={params.companyId} />
      </div>
      <div className="flex flex-col w-full my-6 text-body-medium">
        {companyStores.length ? (
          <Table.Wrapper>
            <Table.Root>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Namn</Table.HeadCell>
                  <Table.HeadCell>Standardområde</Table.HeadCell>
                  <Table.HeadCell>Aktiv</Table.HeadCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {companyStores?.map((store) => {
                  return (
                    <Table.Row key={store.id}>
                      <Table.RowCell>
                        <Link
                          to={`/companies/$companyId/stores/$storeId`}
                          params={(prev) => ({
                            ...prev,
                            companyId: params.companyId,
                            storeId: store.id,
                          })}
                          search={true}
                        >
                          <LinkBox>{store.name}</LinkBox>
                        </Link>
                      </Table.RowCell>
                      <Table.RowCell>{store.placeName}</Table.RowCell>
                      <Table.RowCell>
                        {<YesNo value={store.active} />}
                      </Table.RowCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </Table.Wrapper>
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large">
            Det finns inga butiker.
          </div>
        )}
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
      </div>
    </div>
  );
}
