"use client";

import * as React from "react";
import { cs } from "../../../utils";

import { InputChipProps } from "./InputChip.types";
import Icon from "../Icon";

const InputChip = React.forwardRef<HTMLButtonElement, InputChipProps>(
  (props, ref) => {
    const {
      className,
      children,
      onClick,
      disabled = false,
      leadingIcon,
    } = props;

    const LeadingIcon = leadingIcon
      ? React.cloneElement(leadingIcon as React.ReactElement, {
          className: cs("text-on-surface-container"),
        })
      : null;

    const LeadingIconRender = LeadingIcon ? (
      <div
        className={cs(
          "flex items-center transition-all duration-300 ease-in-out w-[18px]"
        )}
      >
        {LeadingIcon}
      </div>
    ) : null;

    const IconRender = (
      <div
        onClick={onClick}
        className={cs(
          "flex items-center justify-center transition-all duration-300 ease-in-out select-none",
          "w-6 px-2 h-6 ml-[4px] -mr-[4px] rounded-full",
          "hover:bg-surface-container-highest"
        )}
      >
        <Icon
          icon="close"
          size={21}
          className={cs(
            "opacity-0 transition-all duration-400 ease-in-out",
            "opacity-100 text-on-secondary-container"
          )}
        />
      </div>
    );

    return (
      <button
        ref={ref}
        className={cs(
          "items-center box-content cursor-pointer flex justify-center px-4 pr-2 min-h-8 hover:shadow-mm-1",
          "rounded-small border-[1px] transition-all duration-300 ease-in-out",
          "text-on-surface-container bg-surface hover:bg-surface-container-low border-outline overflow-hidden",
          className
        )}
        disabled={disabled}
      >
        {LeadingIconRender}
        <span
          className={cs(
            "text-ellipsis select-none text-label-large overflow-hidden ",
            "flex-1 flex items-center justify-start text-left"
          )}
        >
          {children && children}
        </span>
        {IconRender}
      </button>
    );
  }
);

export default InputChip;
