import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { PlaceType } from "../../../../utils/types";
import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import {
  UpdateIndustryCommandType, useUpdateIndustryMutation
} from "../../../../utils/data/industries";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  active: z.boolean(),
});

const EditDialog = ({ obj }: { obj: PlaceType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useUpdateIndustryMutation(Number(obj.id));

  const { control, handleSubmit, reset } =
    useForm<UpdateIndustryCommandType>({
      resolver: zodResolver(schema),
      defaultValues: {
        name: obj.name,
        active: obj.active,
      },
    });

  const onSubmit: SubmitHandler<UpdateIndustryCommandType> = async (
    data
  ) => {
    return mutation.mutate(
      {
        ...data,
        id: Number(obj.id),
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleEdit} icon={<Icon icon="edit" size={22} />} />
      <Dialog
        icon={<Icon icon="edit" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Uppdatera kategori?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDialog;
