import React from "react";

import { createFileRoute, useNavigate, Link } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { zodSearchValidator, fallback } from "@tanstack/router-zod-adapter";
import { useMediaQuery } from "usehooks-ts";
import { z } from "zod";
import debounce from "lodash/debounce";

import {
  Button,
  FilterChip,
  Icon,
  IconButton,
  LinkBox,
  NavigationDrawer,
  Search,
  Table,
  MultipleComboBox,
} from "@pnpm-monorepo/core/src/ui/components";
import { MdClose, MdTune, MdRefresh } from "@pnpm-monorepo/core/src/ui/icons";

import { cs } from "@pnpm-monorepo/core/src/utils";
import { receiversQueryOptions } from "./-api/queryOptions";
import { ReceviersSortBy } from "./-api/receivers";
import { PlaceType, SortOrder } from "../../utils/types";
import RouterProgress from "../../components/RouterProgress";
import ErrorEmoji from "../../components/ErrorEmoji";
import { NoWrap, SmallDateString, YesNo } from "../../utils/renderers";
import AddNewReceiver from "./-components/AddNewReceiver";
import Pagination from "../../components/Pagination";
import { placesQueryOptions } from "../../utils/data/common";

const receiverSearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  sortBy: fallback(
    z.enum(["code", "placeName", "week_ISO", "createdAt", "name"]),
    "createdAt"
  ).default("createdAt"),
  sortOrder: fallback(z.enum(["asc", "desc"]), "desc").default("desc"),
  q: fallback(z.string(), "").default(""),
  consumer: fallback(z.string().array(), []).default([]),
  place: fallback(z.number().array(), []).default([]),
});

export type ReceiversSearchParams = z.infer<typeof receiverSearchSchema>;

export const Route = createFileRoute("/receivers")({
  validateSearch: zodSearchValidator(receiverSearchSchema),
  loaderDeps: ({ search }: { search: ReceiversSearchParams }) => ({
    q: search.q,
    page: search.page,
    sortBy: search.sortBy,
    sortOrder: search.sortOrder,
    place: search.place,
    consumer: search.consumer,
  }),
  loader: async (opts) => {
    const queryClient = opts.context.queryClient;
    await queryClient.cancelQueries("receivers");
    return queryClient.ensureQueryData(receiversQueryOptions(opts.deps));
  },
  component: ReceiversComponent,
});

function ReceiversComponent() {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const { page, sortBy, sortOrder, q, consumer, place } = Route.useSearch();

  const receiversQuery = useSuspenseQuery(
    receiversQueryOptions(Route.useLoaderDeps())
  );

  const placeQuery = useQuery(placesQueryOptions());
  const places = placeQuery.data;
  const receivers = receiversQuery.data;

  const [consumerDraft, setConsumerDraft] = React.useState<string[]>(
    consumer ?? []
  );
  const [searchDraft, setSearchDraft] = React.useState(q ?? "");
  const [selectedPlacesDraft, setSelectedPlacesDraft] = React.useState<
    PlaceType[]
  >([]);

  React.useEffect(() => {
    if (places && place && place.length > 0) {
      const p = places.filter((x: PlaceType) => place?.includes(x.id));
      setSelectedPlacesDraft(p);
    }
  }, [place, places]);

  const handleSearch = (v: string) => {
    setSearchDraft(v);
  };

  React.useEffect(() => {
    const debouncedNavigate = debounce(() => {
      navigate({
        search: (old) => ({
          ...old,
          q: searchDraft,
          page: 1,
        }),
        replace: true,
      });
    }, 300);

    debouncedNavigate();

    return () => debouncedNavigate.cancel();
  }, [searchDraft, navigate]);

  const setSortBy = (sortBy: ReceviersSortBy) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortBy,
        };
      },
      replace: true,
    });

  const setSortOrder = (sortOrder: SortOrder) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortOrder,
        };
      },
      replace: true,
    });

  const setPage = (page: number) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          page,
        };
      },
      replace: true,
    });

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          consumer: consumerDraft,
          page: 1,
        };
      },
      replace: true,
    });
  }, [navigate, consumerDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          place: selectedPlacesDraft.map((x: PlaceType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedPlacesDraft]);

  function handleOpenFilter(): void {
    setOpen(!open);
  }

  function handleClearFilter(): void {
    setPage(1);
    setConsumerDraft([]);
    setSelectedPlacesDraft([]);
  }

  function handleClearAllFilter(): void {
    setPage(1);
    setSearchDraft("");
    setConsumerDraft([]);
    setSelectedPlacesDraft([]);
  }

  const toggleConsumer = (key: string) => {
    setConsumerDraft((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  return (
    <div>
      <NavigationDrawer
        open={open}
        variant={matches ? "standard" : "modal"}
        onClose={handleOpenFilter}
      >
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-6 py-5 block mb-16">
            <div className="flex items-center justify-end gap-1">
              <Button
                disabled={
                  consumerDraft.length === 0 &&
                  selectedPlacesDraft.length === 0 &&
                  searchDraft.length === 0
                }
                iconLeft={<MdRefresh size={24} />}
                onClick={handleClearAllFilter}
              >
                Återställ alla
              </Button>
              <IconButton icon={<MdClose />} onClick={handleOpenFilter} />
            </div>
            <div className="text-title-medium mt-2 py-1">Filter</div>

            <div className="my-8">
              <div className="my-4">
                {places && (
                  <MultipleComboBox
                    items={places}
                    selectedItems={selectedPlacesDraft}
                    setSelectedItems={setSelectedPlacesDraft}
                    placeholder={"Område"}
                  />
                )}
              </div>

              <div className="flex flex-wrap gap-2 items-center my-4">
                <FilterChip
                  key="isConsumer"
                  selected={consumerDraft.includes("yes")}
                  onClick={() => toggleConsumer("yes")}
                >
                  Konsument
                </FilterChip>
                <FilterChip
                  key="isNotConsumer"
                  selected={consumerDraft.includes("no")}
                  onClick={() => toggleConsumer("no")}
                >
                  Ej konsument
                </FilterChip>
              </div>

              {/* <Disclosure
                header="Sortera"
                className="last:rounded-none"
                defaultOpen
              >
                <div className="text-title-small mt-2 py-1">Efter</div>
                <div className="flex gap-2 flex-wrap py-2">
                  <FilterChip
                    selected={sortBy === "code"}
                    onClick={() => setSortBy("code")}
                  >
                    Kod
                  </FilterChip>
                  <FilterChip
                    selected={sortBy === "placeName"}
                    onClick={() => setSortBy("placeName")}
                  >
                    Område
                  </FilterChip>
                  <FilterChip
                    selected={sortBy === "name"}
                    onClick={() => setSortBy("name")}
                  >
                    Namn
                  </FilterChip>
                  <FilterChip
                    selected={sortBy === "week_ISO"}
                    onClick={() => setSortBy("week_ISO")}
                  >
                    Inflyttad
                  </FilterChip>
                  <FilterChip
                    selected={sortBy === "createdAt"}
                    onClick={() => setSortBy("createdAt")}
                  >
                    Skapad
                  </FilterChip>
                </div>
                <div className="text-title-small mt-2 py-1">Riktning</div>
                <div className="flex gap-2 py-2">
                  <FilterChip
                    selected={sortOrder === "asc"}
                    onClick={() => setSortOrder("asc")}
                  >
                    Stigande
                  </FilterChip>
                  <FilterChip
                    selected={sortOrder === "desc"}
                    onClick={() => setSortOrder("desc")}
                  >
                    Fallande
                  </FilterChip>
                </div>
              </Disclosure> */}
            </div>
          </div>
        </div>
      </NavigationDrawer>
      <div
        id="receivers-content"
        className={cs(
          "relative transition-all",
          open === true && matches ? "ml-80" : "ml-0"
        )}
      >
        <div className="z-10 flex min-w-[300px] flex-col text-on-surface sticky top-0 bg-surface">
          <div className=" block z-50 pb-3 pt-4 px-4">
            <Search
              id="receivers-search"
              name="search"
              placeholder="Sök inflyttad"
              clearOnEscape={true}
              onChange={handleSearch}
              defaultValue={searchDraft}
            />
            <div className="mt-4 mb-2 flex gap-2 items-center">
              <div className="flex grow gap-2 items-center">
                <Button
                  variant={open ? "filled" : "outlined"}
                  iconLeft={open ? <MdClose size={20} /> : <MdTune size={20} />}
                  onClick={handleOpenFilter}
                >
                  Filter
                </Button>

                {consumerDraft.length !== 0 ||
                selectedPlacesDraft.length !== 0 ? (
                  <Button onClick={handleClearFilter}>Rensa allt</Button>
                ) : (
                  <></>
                )}

                <RouterProgress />
              </div>
              <AddNewReceiver />
            </div>
          </div>
        </div>

        {receivers?.count === 0 ? (
          <ErrorEmoji message="Hittade inga inflyttade.">
            <Button
              variant="outlined"
              className="mt-4"
              onClick={handleClearFilter}
            >
              Rena filter och försök igen
            </Button>
          </ErrorEmoji>
        ) : (
          <>
            <Pagination
              maxVisiblePages={10}
              totalItems={receivers.count}
              itemsPerPage={25}
              currentPage={page}
              onPageChange={setPage}
            />

            <div className="flex flex-col w-full my-6 px-4 text-body-medium">
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("code");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Kod{" "}
                          {sortBy === "code" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>Konsument</Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("placeName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Område{" "}
                          {sortBy === "placeName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("name");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Namn{" "}
                          {sortBy === "name" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>Adress</Table.HeadCell>
                      <Table.HeadCell>Postnummer</Table.HeadCell>
                      <Table.HeadCell>Ort</Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("week_ISO");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Inflyttad{" "}
                          {sortBy === "week_ISO" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("createdAt");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Skapad{" "}
                          {sortBy === "createdAt" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {receivers?.results?.map((receiver) => {
                      return (
                        <Table.Row key={receiver.id}>
                          <Table.RowCell>
                            <Link
                              to={`/receivers/$receiverId`}
                              params={(prev) => ({
                                ...prev,
                                receiverId: receiver.id,
                              })}
                              search={true}
                            >
                              <LinkBox>{receiver.code}</LinkBox>
                            </Link>
                          </Table.RowCell>
                          <Table.RowCell>
                            <YesNo value={receiver.isConsumer} />
                          </Table.RowCell>
                          <Table.RowCell>{receiver.placeName}</Table.RowCell>
                          <Table.RowCell>
                            <NoWrap>{receiver.fullName}</NoWrap>
                          </Table.RowCell>
                          <Table.RowCell>{receiver.addressLine2}</Table.RowCell>
                          <Table.RowCell>{receiver.addressLine3}</Table.RowCell>
                          <Table.RowCell>{receiver.addressLine4}</Table.RowCell>
                          <Table.RowCell>{receiver.week_ISO}</Table.RowCell>
                          <Table.RowCell>
                            <SmallDateString value={receiver.createdAt} />
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
              <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
