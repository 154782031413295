import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";

import {
  campaignBasisStatusQueryOptions,
  studioUsersQueryOptions,
} from "../../../../../utils/data/common";
import SelectWrapper from "../../../../../components/FormWrappers/SelectWrapper";
import TextFieldWrapper from "../../../../../components/FormWrappers/TextFieldWrapper";
import { Route } from "../route";
import { CampaignBasisType } from "../../../../../utils/types";
import { companyContractsQueryOptions } from "../../../../../utils/data/company";
import { useCreateCampaignbasisMutation } from "../../../../../utils/data/campaignbasis";

const contractSchema = z.object({
  id: z.number(),
  number: z.string(),
});

const schema = z.object({
  campaignMonth: z.string().min(1, "Månad är obligatoriskt"),
  campaignNote: z.string(),
  workOrderNote: z.string(),
  basisText: z.string(),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  assigned: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  contract: contractSchema
    .nullable()
    .refine((val) => val !== null, { message: "Kontrakt är obligatoriskt" }),
});

const AddNewModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const params = Route.useParams();
  const navigate = useNavigate({ from: Route.fullPath });
  const mutation = useCreateCampaignbasisMutation();

  const studioUsersQuery = useQuery(studioUsersQueryOptions());
  const campaignBasisStatusQuery = useQuery(campaignBasisStatusQueryOptions());
  const companyContractsQuery = useQuery(
    companyContractsQueryOptions(params.companyId)
  );

  const { control, handleSubmit } = useForm<CampaignBasisType>({
    resolver: zodResolver(schema),
    defaultValues: {
      campaignMonth: dayjs().format("YYYY-MM"),
      campaignNote: "",
      workOrderNote: "",
      assigned: undefined,
      status: undefined,
      basisText: "",
      contract: undefined,
    },
  });

  function handleAddOffer(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  const onSubmit: SubmitHandler<CampaignBasisType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        campaignMonth: dayjs(data.campaignMonth).isValid()
          ? dayjs(data.campaignMonth).format("YYYY-MM-DD")
          : undefined,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/companies/$companyId/campaigns/$campaignId",
            search: (old) => ({
              ...old,
            }),
            params: { companyId: params.companyId, campaignId: data.id },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <>
      <Button
        iconLeft={<MdAdd size={20} />}
        variant="filled"
        onClick={handleAddOffer}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<MdAdd />}
        open={open}
        onClose={handleClose}
        headline="Lägg till kampanjunderlag?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Månad
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="campaignMonth"
                control={control}
                variant="month"
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Avseende
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="basisText" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Kontrakt
            </div>
            <div className="pt-2">
              {!companyContractsQuery.isPending ? (
                <SelectWrapper
                  name="contract"
                  control={control}
                  className="min-w-32"
                  options={companyContractsQuery.data}
                  getOptionLabel={(option) => option?.number}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Anteckningar
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="campaignNote" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Arbetsorderinfo
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="workOrderNote" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Tilldelad
            </div>
            <div className="pt-2">
              {!studioUsersQuery.isPending ? (
                <SelectWrapper
                  name="assigned"
                  control={control}
                  className="min-w-32"
                  options={studioUsersQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Status
            </div>
            <div className="pt-2">
              {!campaignBasisStatusQuery.isPending ? (
                <SelectWrapper
                  name="status"
                  control={control}
                  className="min-w-32"
                  options={campaignBasisStatusQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewModal;
