import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdateOfferServiceCommandType = {
  id: number;
  name: string;
  active: boolean;
};

export type AddOfferServiceCommandType = {
  name: string;
  active: boolean;
};

export const fetchOfferServices = async () => {
  const response = await api
    .get(`offerservices`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateOfferService({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateOfferServiceCommandType>, "id">) {
  const result = await api
    .put(`offerservices/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addOfferService({
  ...added
}: AddOfferServiceCommandType) {
  const result = await api
    .post(`offerservices`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteOfferService({ id }: { id: number }) {
  const result = await api
    .delete(`offerservices/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
