import { queryOptions, useMutation } from "@tanstack/react-query";
import { createStore, fetchCompanyStores } from "./stores";
import { queryClient } from "../../../../../App";

export const companyStoresQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", companyId, "stores"],
    queryFn: () => fetchCompanyStores(companyId),
  });

  export const useCreateStoreMutation = (companyId: number | string) => {
    return useMutation({
      mutationKey: ["companies", "create", companyId],
      mutationFn: createStore,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };