import React from "react";
import { Listbox } from "@headlessui/react";
import { cs } from "../../../utils";
import { SelectProps } from "./Select.types";
import { MdArrowDropdown } from "../../icons";

const Select: React.FC<SelectProps> = ({
  options,
  selectedOption,
  setSelectedOption,
  disabled = false,
  invalid = false,
  error,
  className,
  getOptionLabel,
}) => {

  return (
    <Listbox
      value={selectedOption}
      onChange={setSelectedOption}
      disabled={disabled}
    >
      <div className="relative">
        <Listbox.Button
          className={cs(
            "cursor-pointer relative",
            "peer flex h-[48px] w-full grow items-center rounded-t-[8px]",
            "border-x-0 border-b-2 border-t-0 border-on-surface-variant bg-surface-container px-[12px] pr-10",
            "text-body-medium text-on-surface focus:border-primary focus:outline-none",
            invalid && "focus:border-error border-error bg-error-container",
            className && className
          )}
        >
          <span className="block truncate">
            {getOptionLabel(selectedOption)}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <MdArrowDropdown size={18} />
          </span>
        </Listbox.Button>

        <Listbox.Options className="absolute z-50 mt-1 w-full bg-surface-container shadow-mm-2 max-h-60 rounded-medium py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {options.map((option, index) => (
            <Listbox.Option
              key={index}
              value={option}
              className={({ active }) =>
                `${active ? "bg-surface-container-highest" : "text-on-surface"}
                          cursor-default select-none relative`
              }
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={cs(
                      "py-2 pl-3 pr-2 block truncate text-on-surface",                
                      active && "bg-surface-container-high",
                      selected && "bg-surface-container-highest",
                    )}
                  >
                    {getOptionLabel(option)}
                  </span>
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
      {invalid && error && (
        <div className="text-label-medium my-2 text-error">{error.message}</div>
      )}
    </Listbox>
  );
};

export default Select;
