import * as React from "react";
import { cs } from "../../../utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cs(
          "peer flex h-[48px] w-full items-center rounded-t-[8px]",
          "border-x-0 border-b-2 border-t-0 border-on-surface-variant bg-surface-container px-[12px]",
          "text-body-medium text-on-surface placeholder-outline-variant focus:border-primary focus:outline-none",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
