import { TextField } from "@pnpm-monorepo/core/src/ui/components";
import { useController, FieldValues, Control } from "react-hook-form";

interface TextareaWrapperProps {
  name: string;
  placeholder?: string;
  control: Control<FieldValues>;
  defaultValue?: number;
  disabled?: boolean;
  rows?: number;
}

const TextareaWrapper: React.FC<TextareaWrapperProps> = ({
  name,
  control,
  defaultValue,
  disabled,
  placeholder,
  rows
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || "",
  });

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <TextField
      name={name}
      rows={rows}
      multiline={true}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      invalid={invalid}
      error={error}
      placeholder={placeholder}
    />
  );
};

export default TextareaWrapper;
