import { PickAsRequired } from "@tanstack/react-router";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import api from "../../../../utils/api";
import { NotFoundError } from "../../../../utils/errors";
import { OfferType } from "../../../../utils/types";

export const fetchCompanyStores = async (id: number | string) => {
  const stores = await api
    .get(`companies/${id}/stores`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return stores;
};

export const fetchCompanyIndustries = async (id: number | string) => {
  const stores = await api
    .get(`companies/${id}/industries`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return stores;
};

export const fetchOfferById = async (id: number | string) => {
  const offer = await api
    .get(`offers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Erbjudanden med id "${id}" hittades inte!`);
      }
      throw err;
    });

  return offer;
};

export const fetchOfferProducts = async (id: number | string) => {
  const products = await api
    .get(`offers/${id}/products`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return products;
};

export const fetchOfferPlaces = async (id: number | string) => {
  const places = await api
    .get(`offers/${id}/places`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return places;
};

export const fetchOfferActions = async () => {
  const actiontexts = await api
    .get(`offers/actiontexts`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return actiontexts;
};

export const fetchOfferWorths = async () => {
  const worths = await api
    .get(`offers/worths`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return worths;
};

export const fetchOfferPrepositions = async () => {
  const prepositions = await api
    .get(`offers/prepositions`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return prepositions;
};

export const fetchOfferServiceTexts = async (
  companyId: number | string | undefined
) => {
  const servicetexts = await api
    .get(`offers/servicetexts?companyId=${companyId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return servicetexts;
};

export async function updateOffer({
  id,
  ...updated
}: PickAsRequired<Partial<OfferType>, "id">) {
  const result = await api
    .put(`offers/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateOfferStatus({
  id,
  statusId,
}: {
  id: number;
  statusId: number;
}) {
  const result = await api
    .put(`offers/${id}/status`, {
      id,
      statusId,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateOfferProductPlaces({
  id,
  ...updated
}: PickAsRequired<Partial<OfferType>, "id">) {
  const result = await api
    .put(`offers/${id}/products`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateOfferTerms({
  id,
  validFrom,
  validTo,
  pauseFrom,
  pauseTo,
  ...updated
}: PickAsRequired<Partial<OfferType>, "id">) {
  const parseDate = (date: Date | string | undefined) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
  };

  const result = await api
    .put(`offers/${id}/terms`, {
      id,
      validFrom: parseDate(validFrom),
      validTo: parseDate(validTo),
      pauseFrom: parseDate(pauseFrom),
      pauseTo: parseDate(pauseTo),
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addOfferImage({
  offerId,
  formData,
}: {
  offerId: number;
  formData: FormData;
}) {
  const response = await api
    .post(`offers/${offerId}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function deleteOfferImage({
  offerId,
  imageId,
}: {
  offerId: number;
  imageId: number;
}) {
  const response = await api
    .delete(`offers/${offerId}/images/${imageId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function deleteOffer({ offerId }: { offerId: number }) {
  const response = await api
    .delete(`offers/${offerId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function copyOffer({ offerId }: { offerId: number }) {
  const response = await api
    .post(`offers/${offerId}/copy`, {})
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}