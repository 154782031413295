"use client";
import * as React from "react";

import { cs } from "../../../utils";
import { IconProps } from "./Icon.types";

const Icon: React.FC<IconProps> = (props) => {
  const {
    size,
    style: propStyle,
    color,
    fill,
    weight = "400",
    grade,
    className,
  } = props;

  const style = { ...propStyle };

  if (fill)
    style.fontVariationSettings = [style.fontVariationSettings, '"FILL" 1']
      .filter(Boolean)
      .join(", ");
  if (weight)
    style.fontVariationSettings = [
      style.fontVariationSettings,
      `"wght" ${weight}`,
    ]
      .filter(Boolean)
      .join(", ");
  if (grade)
    style.fontVariationSettings = [
      style.fontVariationSettings,
      `"GRAD" ${grade}`,
    ]
      .filter(Boolean)
      .join(", ");
  if (size) {
    style.fontVariationSettings = [
      style.fontVariationSettings,
      `"opsz" ${size}`,
    ]
      .filter(Boolean)
      .join(", ");
    style.fontSize = size;
  }

  return (
    <span
      onClick={() => {
        props.onClick && props.onClick();
      }}
      className={cs("material-symbols-outlined", color && color, className)}
      style={style}
    >
      {props.icon}
    </span>
  );
};

export default Icon;
