"use client";
import * as React from "react";

import { cs } from "../../../utils";
import { NavigationDrawerProps } from "./NavigationDrawer.types";

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  className,
  children,
  width = "w-80",
  variant = "standard",
  open = true,
  onClose,
}) => {
  const variantClasses = {
    standard: cs(" shadow-none"),
    modal: "shadow-mm-1",
  };

  const baseClass = variantClasses[variant];
  let variantClass = baseClass || "";

  const Comp = "nav";

  return (
    <>
      <div
        onClick={onClose}
        className={cs(
          "fixed top-0 bottom-0 left-0 right-0 bg-primary/10 z-[2]",
          "",
          variant === "modal" && open === true ? "visible" : "invisible"
        )}
      />
      <Comp
        className={cs(
          "fixed bg-surface-container-low rounded-r-[16px] z-[11] overflow-y-auto inset-y-0 transition-all",
          width && open === true ? width : "w-0",
          variantClass,
          className
        )}
      >
        {children}
      </Comp>
    </>
  );
};

export default NavigationDrawer;
