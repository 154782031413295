import React from "react";
import isEqual from "lodash/isEqual";
import { useController, FieldValues, Control } from "react-hook-form";

import FilterChip from "@pnpm-monorepo/core/src/ui/components/FilterChip";

export interface CheckboxGroupWrapperProps<T = any> {
  name: string;
  options: T[];
  control: Control<FieldValues>;
  defaultValue?: T[];
  className?: string;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T[]) => boolean;
}

const CheckboxGroupWrapper: React.FC<CheckboxGroupWrapperProps> = <T,>({
  name,
  options,
  control,
  defaultValue,
  className,
  getOptionLabel,
  isOptionEqualToValue,
}: CheckboxGroupWrapperProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (option: T) => {
    
    if (option !== null) {
      // Ensure value is an array
      const currentValue = Array.isArray(value) ? value : [];
  
      // Check if the option is already selected
      const isOptionSelected = currentValue.some((val) => isEqual(val, option));
  
      // Update the value based on whether the option is selected or not
      let updatedValue;
      if (isOptionSelected) {
        updatedValue = currentValue.filter((val) => !isEqual(val, option));
      } else {
        // Check if the option was already selected
        const wasOptionSelected = currentValue.some((val) => val === option);
        updatedValue = wasOptionSelected ? currentValue.map(val => val === option ? null : val) : [...currentValue, option];
      }
  
      // Call the onChange handler with the updated value
      onChange(updatedValue);
    }
  };
  

  const handleGetOptionLabel = (option: T) => {
    return getOptionLabel(option);
  };

  return (
    <>
      {options.map((option, index) => (
        <FilterChip
          key={index}
          selected={isOptionEqualToValue(option, value)}
          onClick={() => handleChange(option)}
          disabled={invalid}
          className={className}
        >
          {handleGetOptionLabel(option)}
        </FilterChip>
      ))}
    </>
  );
};

export default CheckboxGroupWrapper;
