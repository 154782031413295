import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchOfferServices,
  updateOfferService,
  addOfferService,
  deleteOfferService,
} from "./offerservices";
import { queryClient } from "../../../App";

export const offerServicesQueryOptions = (
  q: string = "",
  propertyName: string
) =>
  queryOptions({
    queryKey: ["offerservices"],
    queryFn: () => fetchOfferServices(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const useUpdateOfferServiceMutation = (id: number) => {
  return useMutation({
    mutationKey: ["offerservice", "update", id],
    mutationFn: updateOfferService,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddOfferServiceMutation = () => {
  return useMutation({
    mutationKey: ["offerservice", "add"],
    mutationFn: addOfferService,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteOfferServiceMutation = () => {
  return useMutation({
    mutationKey: ["offerservice", "delete"],
    mutationFn: deleteOfferService,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
