import React from "react";
import { useController, FieldValues, Control } from "react-hook-form";

import FilterChip from "@pnpm-monorepo/core/src/ui/components/FilterChip";
import { Value } from "@pnpm-monorepo/core/src/ui/components";

export interface RadioGroupWrapperProps<T = any> {
  name: string;
  options: Value<T>[];
  control: Control<FieldValues>;
  defaultValue?: Value<T> | null;
  className?: string;
  getOptionLabel: (option: Value<T>) => string;
  isOptionEqualToValue: (option: Value<T>, value: Value<T>) => boolean;
}

const RadioGroupWrapper: React.FC<RadioGroupWrapperProps> = <T,>({
  name,
  options,
  control,
  defaultValue,
  className,
  getOptionLabel,
  isOptionEqualToValue,
}: RadioGroupWrapperProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (option: Value<T>) => {

    if (handleIsOptionEqualToValue(option, value)) {
      return onChange(null);
    }
    return onChange(option);
  };

  const handleIsOptionEqualToValue = (option: Value<T>, value: Value<T>) => {
    if (!value) return false;
    const selected = isOptionEqualToValue(option, value);
    return selected;
  };

  const handleGetOptionLabel = (option: Value<T>) => {
    return getOptionLabel(option);
  };

  return (
    <>
      {options.map((option, index) => (
        <FilterChip
          key={index}
          selected={handleIsOptionEqualToValue(option, value)}
          onClick={() => handleChange(option)}
          disabled={invalid}
          className={className}
        >
          {handleGetOptionLabel(option)}
        </FilterChip>
      ))}
    </>
  );
};

export default RadioGroupWrapper;
