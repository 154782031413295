import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";

import { YesNo } from "../../../../../../utils/renderers";
import SwitchWrapper from "../../../../../../components/FormWrappers/SwitchWrapper";
import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";
import { useUpdateStoreOpeningHoursMutation } from "../-api/queryOptions";
import { Route } from "../route";

type OpeningHourPeriodType = {
  open: {
    day: string;
    dayName: string;
    time: string;
  };
  close: {
    day: string;
    dayName: string;
    time: string;
  };
};

export type OpeningHourType = {
  openNow: boolean;
  periods: OpeningHourPeriodType[];
};

type OpeningHourProps = {
  openingHour: OpeningHourType;
  hideOpenTimes: boolean;
  openTimeComment: string;
};

type OpeningHourCommandProps = {
  openingHours: {
    dayOfWeek: number;
    dayName: string;
    openTime: string;
    closeTime: string;
  }[];
  hideOpenTimes: boolean;
  openTimeComment: string;
};

function formatTimeForJSON(timeString) {
  if (timeString === "24:00") {
    return "00:00:00";
  } else {
    const [hours, minutes, seconds = "00"] = timeString.split(":");
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
  }
}

const OpeningHour: React.FC<OpeningHourProps> = ({
  openingHour,
  hideOpenTimes,
  openTimeComment,
}) => {
  const [edit, setEdit] = React.useState(false);

  const params = Route.useParams();

  const openingHours = [
    {
      dayOfWeek: 1,
      dayName: "Måndag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 2,
      dayName: "Tisdag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 3,
      dayName: "Onsdag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 4,
      dayName: "Torsdag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 5,
      dayName: "Fredag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 6,
      dayName: "Lördag",
      openTime: "",
      closeTime: "",
    },
    {
      dayOfWeek: 0,
      dayName: "Söndag",
      openTime: "",
      closeTime: "",
    },
  ];

  if (openingHour && openingHour.periods) {
    let index;

    for (let i = 0; i < openingHour.periods.length; i++) {
      index = openingHours.findIndex(
        (day) => day.dayName === openingHour.periods[i].open.dayName
      );

      if (openingHour.periods[i].open) {
        openingHours[index].openTime = openingHour.periods[
          i
        ].open.time.substring(0, 5);
      }

      if (openingHour.periods[i].close) {
        if (openingHour.periods[i].close.time.substring(0, 5) == "00:00")
          openingHours[index].closeTime = "24:00";
        else
          openingHours[index].closeTime = openingHour.periods[
            i
          ].close.time.substring(0, 5);
      }
    }
  }

  const mutation = useUpdateStoreOpeningHoursMutation(params.storeId);

  const { control, handleSubmit, reset } = useForm<OpeningHourCommandProps>({
    defaultValues: {
      hideOpenTimes: hideOpenTimes,
      openTimeComment: openTimeComment ?? "",
      openingHours: openingHours,
    },
  });

  const onSubmit: SubmitHandler<OpeningHourCommandProps> = (data) => {
    let f = 0;
    let json = "{" + '"periods": [';

    for (let i = 0; i < data.openingHours.length; i++) {
      if (
        data.openingHours[i].openTime != null &&
        data.openingHours[i].closeTime
      ) {
        if (f == 1) json = json + ",";

        json =
          json +
          "{ " +
          '"open": { ' +
          '"day": ' +
          data.openingHours[i].dayOfWeek +
          ", " +
          '"time": "' +
          formatTimeForJSON(data.openingHours[i].openTime) +
          '"' +
          "}, " +
          '"close": { ' +
          '"day": ' +
          data.openingHours[i].dayOfWeek +
          ", " +
          '"time": "' +
          formatTimeForJSON(data.openingHours[i].closeTime) +
          '"' +
          "}" +
          "}";

        f = 1;
      }
    }

    json = json + "]}";

    const obj = JSON.parse(json);

    return mutation.mutate(
      {
        ...obj,
        storeId: params.storeId,
        hideOpenTimes: data.hideOpenTimes,
        openTimeComment: data.openTimeComment,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title={"Öppettider"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Dölj Öppettider i Appen
              </div>
              <div className="text-body-medium">
                <YesNo value={hideOpenTimes} />
              </div>
            </div>
            <div className="mb-4">
              <div className="w-full bg-inverse-on-surface rounded-large">
                <Table.Wrapper>
                  <Table.Root>
                    <Table.Head>
                      <Table.Row>
                        <Table.HeadCell>Dag</Table.HeadCell>
                        <Table.HeadCell>Tid</Table.HeadCell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {openingHours?.map((openingHour, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.RowCell>{openingHour.dayName}</Table.RowCell>
                            <Table.RowCell>
                              {openingHour.openTime !== "" &&
                              openingHour.closeTime != ""
                                ? openingHour.openTime +
                                  " - " +
                                  openingHour.closeTime
                                : "Stängt"}
                            </Table.RowCell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table.Root>
                </Table.Wrapper>
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kommentar
              </div>
              <div className="text-body-medium">
                {openTimeComment ? (
                  openTimeComment
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Öppettider"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />

          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Dölj Öppettider i Appen
              </div>
              <div className="pt-2">
                <SwitchWrapper name="hideOpenTimes" control={control} />
              </div>
            </div>
            <div className="mb-4">
              <div className="w-full bg-inverse-on-surface rounded-large">
                <Table.Wrapper>
                  <Table.Root>
                    <Table.Head>
                      <Table.Row>
                        <Table.HeadCell>Dag</Table.HeadCell>
                        <Table.HeadCell>Tid</Table.HeadCell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {openingHours?.map((openingHour, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.RowCell>{openingHour.dayName}</Table.RowCell>
                            <Table.RowCell>
                              <div className="flex gap-2 items-center w-full">
                                <TextFieldWrapper
                                  control={control}
                                  name={`openingHours.${index}.openTime`}
                                  variant="time"
                                  className="w-full"
                                />
                                <span>-</span>
                                <TextFieldWrapper
                                  control={control}
                                  name={`openingHours.${index}.closeTime`}
                                  variant="time"
                                  className="w-full"
                                />
                              </div>
                            </Table.RowCell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table.Root>
                </Table.Wrapper>
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kommentar
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="openTimeComment" control={control} />
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3 ">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default OpeningHour;
