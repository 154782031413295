import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type SystemSettingCommandType = {
  key: string;
  value: string;
};

export const fetchSettings = async () => {
  const result = await api
    .get(`settings`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function updateSetting({
  key,
  ...updated
}: PickAsRequired<Partial<SystemSettingCommandType>, "key">) {
  const result = await api
    .put(`settings`, {
      key,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
