"use client";
import * as React from "react";

import { cs } from "../../../utils";
import { NavigationRailProps } from "./NavigationRail.types";

const NavigationRail: React.FC<NavigationRailProps> = ({
  className,
  top,
  center,
  bottom,
  open = true,
  onClose,
}) => {
  return (
    <nav
      className={cs(
        "items-center flex flex-col gap-2 h-full overflow-y-auto w-24 z-[12] px-0 py-6 bg-surface-container-low",
        className
      )}
    >
      {top && (
        <div className="flex w-full flex-col items-center justify-center">
          {top}
        </div>
      )}
      <div className={cs("grow", top && "py-6")}>{center}</div>
      {bottom && (
        <div className="flex w-full flex-col items-center justify-center gap-2">
          {bottom}
        </div>
      )}
    </nav>
  );
};

export default NavigationRail;
