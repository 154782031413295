import { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useIsMounted } from "usehooks-ts";

import {
  Card,
  CardContent,
  CardHeader,
  FilterChip,
  Button,
} from "@pnpm-monorepo/core/src/ui/components";

type PlaceListType = {
  handleSubmit: () => void;
};

const PlaceList: React.FC<PlaceListType> = ({ handleSubmit }) => {
  
  const isMounted = useIsMounted();
  const [all, setAll] = useState(true);
  const { control, register } = useFormContext();
  const { fields, update } = useFieldArray({
    control,
    name: "places",
    keyName: "customId",
  });

  useEffect(() => {
    if (isMounted()) {
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        if (!field.active) {
          return setAll(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, fields]);

  const toggleAll = () => {
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      const copy = JSON.parse(JSON.stringify(field));
      copy.active = !all;
      delete copy.customId;
      update(index, copy);
    }
    setAll(!all);
    handleSubmit();
  };

  return (
    <Card className="min-h-10">
      <CardHeader
        title="Välj områden"
        actions={
          <>
            {fields.length > 1 && (
              <Button variant="outlined" onClick={toggleAll}>
                {all ? "Avmarkera" : "Markera alla"}
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        {fields.length ? (
          <div className="flex gap-2 flex-wrap">
            {fields.map((field, index) => {
              return (
                <FilterChip
                  key={field.customId}
                  {...register(`places.${index}`)}
                  selected={field.active}
                  onClick={() => {
                    let copy = JSON.parse(JSON.stringify(field));
                    copy.active = !field.active;
                    delete copy.customId;
                    update(index, copy);
                    handleSubmit();
                  }}
                >
                  {field.name}
                </FilterChip>
              );
            })}
          </div>
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large text-body-medium f">
            Det finns inga områden.
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PlaceList;
