import { Suspense } from "react";
import Icon from "../Icon";
import { cs, TextWithLineBreaks } from "../../../utils";

interface DefaultImageProps {
  src: string;
}

export const FallbackImage: React.FC<DefaultImageProps> = ({ src = "" }) => {
  return <img src={src} alt="" className="object-cover" />;
};

export const DeviceMockup = (props) => {
  const {
    number,
    originalPrice,
    images,
    stores,
    company,
    product,
    offerActionText,
    offerWorthText,
    offerPrepositionText,
    offerServiceText,
    restrictions,
    information,
    className,
    defaultImageThumbnail,
  } = props;

  const { image: Logo } = company;

  let logotype = Logo;

  const activeStores = stores?.filter((x) => x.active);

  if(activeStores && activeStores.length === 1) {
    const storeImages = activeStores[0].images;
    const storeLogotypes = storeImages?.filter((x) => x.imageCategoryId === 1);

    logotype = storeLogotypes?.length ? storeLogotypes[0] : Logo;
  } 

  return (
    <div
      id="device-frame"
      className={cs(
        "relative bg-white text-black border-outline overflow-hidden rounded-extra-large border-8 border-solid select-none",
        "h-[760px] w-[351px]",
        className
      )}
    >
      <div id="device-frame-container" className="flex flex-col h-full">
        <div id="device-frame-top">
          <div className="h-16 w-full flex gap-3 justify-center items-center pt-4 text-body-medium border-b-[1px] border-outlined">
            <span className="flex-1 flex justify-start items-center pl-3">
              <Icon icon="chevron_left" size={26} className="cursor-pointer" />
            </span>
            <span className="flex-2 text-nowrap overflow-hidden text-ellipsis font-mobilkupongen">
              {stores != null && stores.length == 1
                ? stores[0].name
                : company.friendlyName == null || company.friendlyName == ""
                  ? company.name
                  : company.friendlyName}
            </span>
            <span className="flex-1 flex justify-end items-center pr-3"></span>
          </div>
        </div>
        <div
          id="device-frame-main"
          className="flex-1 overflow-y-auto scrollbar-hide"
        >
          <div id="device-frame-image" className="overflow-hidden">
            <Suspense fallback={<FallbackImage src={defaultImageThumbnail} />}>
              <img
                src={images.length ? images[0].url : null}
                alt=""
                className="object-cover"
              />
            </Suspense>
          </div>

          <div className="relative">
            <div
              id="device-offer"
              className="flex flex-col justify-center items-center p-3 pb-3 bg-mk-pink-container text-black after:content-[''] after:absolute after:-bottom-[3px] after:border-b-[4px] after:border-mk-pink-container after:w-full after:border-dashed"
            >
              <div className="text-[26px] text-mk-pink mt-2 text-center -mb-[5px]">
                <span className="font-black font-mobilkupongen">
                  {offerWorthText}
                </span>
              </div>
              <div className="text-[17px] leading-none px-8 text-center ">
                <span className="font-bold font-mobilkupongen">
                  <TextWithLineBreaks
                    text={
                      (offerPrepositionText ? offerPrepositionText : "") +
                      " " +
                      (offerServiceText ? offerServiceText : "")
                    }
                  />
                </span>
              </div>

              <div className="text-label-large leading-none text-center mt-2">
                <span className="font-mobilkupongen">
                  <TextWithLineBreaks
                    text={
                      (information ? information : "") +
                      " " +
                      (restrictions ? restrictions : "")
                    }
                  />
                </span>
              </div>

              <div className="text-label-large text-center mt-2 mb-2">
                <span className="font-mobilkupongen">
                  Gäller t.o.m YYYY-MM-DD
                </span>
              </div>

              {originalPrice && (
                <div className="flex text-label-small font-mobilkupongen">
                  <span className="font-mobilkupongen">
                    Ordinarie pris {originalPrice}
                  </span>
                </div>
              )}

              {offerActionText != null &&
              offerActionText.id !== 0 &&
              product != null &&
              product.productCategory?.offerActionId === 1 ? (
                <div className="text-center my-[4px]">
                  <div className="border-[1px] border-mk-purple text-mk-purple rounded-full p-4 py-[6px] text-label-large  mx-2.5 text-center cursor-pointer">
                    <span className="font-mobilkupongen uppercase">
                      {offerActionText.name}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div id="device-stores" className="my-3 p-3">
            <div className="mb-5">
              <div className="flex justify-center items-center">
                <Suspense
                  fallback={<FallbackImage src={defaultImageThumbnail} />}
                >
                  <img
                    src={logotype ? logotype.url : null}
                    alt=""
                    className="object-cover max-w-60"
                  />
                </Suspense>
              </div>
            </div>

            <div className="flex gap-2 flex-col">
              {activeStores?.map((store) => {
                return (
                  <div key={store.id}>
                    <div className="flex items-center text-label-large p-2 px-3 rounded-medium bg-mk-pink-container cursor-pointer">
                      <div className="flex flex-col justify-start grow">
                        <div className="font-medium text-nowrap overflow-hidden text-ellipsis max-w-60">
                          <span className="font-mobilkupongen leading-none">
                            {store.name}
                          </span>
                        </div>
                        <div className="text-label-small">
                          <span className="font-mobilkupongen leading-none">
                            {/^[ ,]*$/.test(store.address.formattedAddress)
                              ? ""
                              : store.address.formattedAddress}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Icon
                          icon="arrow_forward"
                          size={20}
                          className="text-mk-pink"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-3 mb-6 px-3 text-center">
            <div className="text-label-small">
              <span className="font-mobilkupongen">
                Erbjudande nr: {number}
              </span>
            </div>
          </div>
        </div>
        <div id="device-frame-bottom">
          <div className="flex h-20 bg-white">
            <div className="flex justify-center items-center h-full w-full overflow-hidden">
              <div className="bg-mk-purple text-white rounded-full p-3 text-label-large w-full mx-2.5 text-center cursor-pointer">
                <span className="uppercase font-mobilkupongen tracking-wider font-light">
                  {offerActionText != null &&
                  product != null &&
                  product.productCategory?.offerActionId === 2
                    ? offerActionText.name
                    : stores.length > 1
                      ? "Välj plats och lös in kupong"
                      : "Lös in kupongen"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
