import React from "react";
import { useController, FieldValues, Control } from "react-hook-form";
import Switch from "@pnpm-monorepo/core/src/ui/components/Switch"; 

interface SwitchWrapperProps {
  name: string;
  control: Control<FieldValues>;
  defaultValue?: boolean;
}

const SwitchWrapper: React.FC<SwitchWrapperProps> = ({
  name,
  control,
  defaultValue = false,
}) => {
  const {
    field: { onChange: onChange, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue
  });

  const handleChange = (newValue: boolean) => {
    onChange(newValue);
  };

  return (
    <Switch
      name={name}
      checked={value}
      onChange={handleChange}
      invalid={invalid}
      error={error}
    />
  );
};

export default SwitchWrapper;
