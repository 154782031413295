import { Link, Outlet, createFileRoute } from "@tanstack/react-router";
import {
  NavigationDrawer,
  NavigationDrawerItem,
} from "@pnpm-monorepo/core/src/ui/components";
import { cs } from "@pnpm-monorepo/core/src/utils";

export const Route = createFileRoute("/adminland")({
  component: AdminlandCompnent,
});

function AdminlandCompnent() {
  return (
    <div>
      {/* <NavigationDrawer width="w-60">
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-3 py-4 block mb-16">
            <Link to="/adminland" activeOptions={{ exact: true }}>
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="adminland"
                      label="Adminland översikt"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/systemsettings">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="system"
                      label="Systeminställningar"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/offerworths">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="offerworths"
                      label="Erbjudandevärde"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/offerservices">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="offerservices"
                      label="Erbjudandetjänster"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/industries">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="industries"
                      label="Kategori"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/chains">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="chains"
                      label="Kedja"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/places">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="places"
                      label="Område"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/products">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="products"
                      label="Produkt"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/productcategories">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="productcategories"
                      label="Produktkategori"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
            <Link to="/adminland/sellers">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationDrawerItem
                      id="sellers"
                      label="Säljare"
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
          </div>
        </div>
      </NavigationDrawer> */}
      <div className={cs("relative transition-all", "")}>
        <div className="flex justify-center gap-4 mx-auto w-full lg:max-w-screen-2xl">
          <div className="flex flex-col w-full px-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
