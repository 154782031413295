import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  deleteCampaign,
  fetchCampaignActivity,
  fetchCampaignById,
  fetchCampaignEstimate,
  fetchCampaignStatuses,
  fetchCampaignTestLogs,
  fetchEmailTemplates,
  sendCampaignMail,
  updateCampaign,
} from "./campaign";
import { queryClient } from "../../../../App";

export const campignQueryOptions = (campaignId: number | string) =>
  queryOptions({
    queryKey: ["campaigns", campaignId],
    queryFn: () => fetchCampaignById(campaignId),
  });

export const campignQueryEstimateOptions = (campaignId: number | string) =>
  queryOptions({
    queryKey: ["campaigns", "estimate", campaignId],
    queryFn: () => fetchCampaignEstimate(campaignId),
  });

export const campignQueryActivityOptions = (campaignId: number | string) =>
  queryOptions({
    queryKey: ["campaigns", "activity", campaignId],
    queryFn: () => fetchCampaignActivity(campaignId),
  });

  export const campignQueryTestMailLogsQueryOptions = (campaignId: number | string) =>
    queryOptions({
      queryKey: ["campaigns", "testmaillogs", campaignId],
      queryFn: () => fetchCampaignTestLogs(campaignId),
    });

export const useDeleteCampaign = (campaignId: number) => {
  return useMutation({
    mutationKey: ["campaigns", campaignId],
    mutationFn: deleteCampaign,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["campaigns"] }),
  });
};

export const campignStatusesQueryOptions = () =>
  queryOptions({
    queryKey: ["campaigns", "status"],
    queryFn: () => fetchCampaignStatuses(),
  });

export const campignTemplatesQueryOptions = () =>
  queryOptions({
    queryKey: ["campaigns", "templates"],
    queryFn: () => fetchEmailTemplates(),
  });

export const useUpdateCampaignMutation = (campaignId: number | string) => {
  return useMutation({
    mutationKey: ["campaigns", "update", campaignId],
    mutationFn: updateCampaign,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useSendTestCampaign = (campaignId: number | string) => {
  return useMutation({
    mutationKey: ["campaign", "testmail", campaignId],
    mutationFn: sendCampaignMail,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

