import React from "react";
import { NumberInputProps } from "./NumberInput.types";
import IconButton from "../IconButton";
import { MdRemove, MdAdd } from "../../icons";
import { cs } from "../../../utils";

const NumberInput: React.FC<NumberInputProps> = (props) => {
  const {
    name,
    value,
    onChange,
    onBlur,
    min = 0,
    max = 10,
    disabled = false,
    invalid = false,
    error = null,
    className
  } = props;

  const increment = () => {
    const newValue = value + 1;
    onChange(newValue > max ? max : newValue);
  };

  const decrement = () => {
    const newValue = value - 1;
    onChange(newValue < min ? min : newValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target.value.trim() === "" ? min : parseInt(e.target.value);

    // Check if newValue is a valid number and within the range
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onChange(newValue);
    } else {
      // If not a valid number or out of range, update the value to min or max
      if (newValue < min) {
        onChange(min);
      } else if (newValue > max) {
        onChange(max);
      }
    }
  };

  return (
    <>
      <div
        className={cs(
          "flex w-fit flex-row gap-[4px] rounded-[8px] bg-surface-container-high",
          className,
          invalid && "border border-error",
        )}
      >
        <IconButton
          icon={<MdRemove />}
          className={cs("m-[2px] rounded-[6px] p-[6px]", disabled || value <= min && "disabled:cursor-not-allowed")}
          onClick={decrement}
          disabled={disabled || value <= min}
        />
        <div className="flex min-w-[24px] items-center justify-center px-[2px] text-body-medium text-on-surface bg-transparent focus:outline-none">
          <input
            type="text"
            name={name}
            onBlur={onBlur}
            value={value}
            onChange={handleChange}
            className="text-center bg-transparent focus:outline-none"
            autoComplete="off"
          />
        </div>
        <IconButton
          icon={<MdAdd />}
          className={cs("m-[2px] rounded-[6px] p-[6px]", disabled || value >= max && "disabled:cursor-not-allowed")}
          onClick={increment}
          disabled={disabled || value >= max}
        />
      </div>
      {invalid && error && (
        <div className="text-label-medium my-2 text-error">{error.message}</div>
      )}
    </>
  );
};

export default NumberInput;
