import { useEffect, useState, useCallback, FormEvent, useRef } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { cs } from "../../../utils";

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

export const PlaceAutocompleteCustom = ({ onPlaceSelect }: Props) => {
  const map = useMap();
  const places = useMapsLibrary("places");

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [highlightedIndex, setHighlightedIndex] = useState(-1); // state for highlighted suggestion
  const suggestionListRef = useRef(null); // Ref for suggestion list

  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        onPlaceSelect(placeDetails);
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? "");
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  const handleKeyDown = (event) => {
    if (predictionResults.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        setHighlightedIndex((prevIndex) =>
          prevIndex < predictionResults.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : predictionResults.length - 1
        );
        break;
      case "Enter":
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < predictionResults.length
        ) {
          handleSuggestionClick(predictionResults[highlightedIndex].place_id);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const listElement = suggestionListRef.current;
    if (listElement && highlightedIndex >= 0) {
      const selectedItem = listElement.children[highlightedIndex];
      if (selectedItem) {
        selectedItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [highlightedIndex]);

  return (
    <>
      <div className="relative w-96 m-2">
        <input
          value={inputValue}
          onInput={(event: FormEvent<HTMLInputElement>) => onInputChange(event)}
          onKeyDown={handleKeyDown} // Add keydown event handler
          placeholder="Sök efter ett ställe"
          className="w-full p-3 border-2 border-outline-variant text-label-large focus:outline-none"
        />

        {predictionResults.length > 0 && (
          <ul
            className="bg-surface absolute w-full -mt-[2px] border-2 border-outline-variant"
            ref={suggestionListRef}
          >
            {predictionResults.map(({ place_id, description }, index) => {
              const isHighlighted = index === highlightedIndex;

              return (
                <li
                  key={place_id}
                  className={cs(
                    "px-2 py-[4px] cursor-pointer hover:bg-surface-container-lowest",
                    isHighlighted ? "bg-surface-container-lowest" : ""
                  )}
                  onClick={() => handleSuggestionClick(place_id)}
                >
                  {description}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};
