import { Button, Icon } from "@pnpm-monorepo/core/src/ui/components";
import React from "react";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  maxVisiblePages: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  maxVisiblePages = 5,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const pageNumbers: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const renderPageNumbers = () => {
    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to max visible pages, display all page numbers
      return pageNumbers;
    } else {
      const leftEllipsis = currentPage > Math.ceil(maxVisiblePages / 2) + 1;
      const rightEllipsis =
        currentPage < totalPages - Math.floor(maxVisiblePages / 2);
      let visiblePages: number[] = [];

      if (leftEllipsis && rightEllipsis) {
        // Display ellipsis on both sides
        const start = currentPage - Math.floor(maxVisiblePages / 2);
        visiblePages = pageNumbers.slice(
          start - 1,
          start + maxVisiblePages - 1
        );
      } else if (leftEllipsis) {
        // Display ellipsis on the left side
        visiblePages = pageNumbers.slice(
          totalPages - maxVisiblePages,
          totalPages
        );
      } else if (rightEllipsis) {
        // Display ellipsis on the right side
        visiblePages = pageNumbers.slice(0, maxVisiblePages);
      }

      return visiblePages;
    }
  };

  return (
    <div>
      <div className="mx-3 flex justify-center items-center space-x-1 space-y-2 flex-wrap">
        <div className="flex flex-wrap items-center space-x-1 space-y-1 z-0">
      
            <Button
              size="small"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <Icon icon="arrow_back" />
            </Button>
        
          {renderPageNumbers().map((number, index) => (
            <div key={number}>
              <Button
                size="small"
                onClick={() => onPageChange(number)}
                variant={number === currentPage ? "tonal" : "text"}
              >
                {number}
              </Button>
            </div>
          ))}
       
            <Button
              size="small"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <Icon icon="arrow_forward" />
            </Button>
        
        </div>
        <div className="text-label-medium pl-2">
          Visar {startIndex} till {endIndex} av {totalItems.toLocaleString("sv")}.
        </div>
      </div>
    </div>
  );
};

export default Pagination;
