import { createRootRoute, Outlet } from "@tanstack/react-router";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import Navigation from "../components/Navigation";
import { cs } from "@pnpm-monorepo/core/src/utils";
// import RouterNavigation from "../components/RouterNavigation";

export const Route = createRootRoute({
  component: () => (
    <div
      className={cs("flex sm:w-full sm:flex-row h-full bg-surface", "flex-col")}
    >
      <Navigation />
      <div
        className={cs(
          "flex-1  h-full relative",
          "sm:flex-col sm:overflow-x-auto sm:flex sm:w-full "
        )}
      >
        {/* <RouterNavigation /> */}
        <Outlet />
      </div>
      {/* <TanStackRouterDevtools position="bottom-right" /> */}
    </div>
  ),
});
