import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdateIndustryCommandType = {
  id: number;
  name: string;
  active: boolean;
};

export type AddIndustryCommandType = {
  name: string;
  active: boolean;
};

export const fetchIndustries = async () => {
  const response = await api
    .get(`industries`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateIndustry({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateIndustryCommandType>, "id">) {
  const result = await api
    .put(`industries/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addIndustry({ ...added }: AddIndustryCommandType) {
  const result = await api
    .post(`industries`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteIndustry({ id }: { id: number }) {
  const result = await api
    .delete(`industries/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
