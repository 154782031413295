import { useState, useEffect } from "react";
import {
  companyOffersProofQueryOptions,
  useUpdateCompanyOfferProof,
} from "../-api/queryOptions";
import {
  Button,
  Card,
  DeviceMockup,
  Dialog,
  Icon,
  IconButton,
  Tooltip,
} from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";
import { useQuery } from "@tanstack/react-query";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { useSnackbar } from "notistack";

const ProofDialog = ({ companyId }: { companyId: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const companyOffersProofQuery = useQuery(
    companyOffersProofQueryOptions(companyId)
  );
  const companyOffersProof = companyOffersProofQuery.data;

  useEffect(() => {
    if (companyOffersProof) setSelected(companyOffersProof.proofs);
  }, [companyOffersProof]);

  const updateCompanyOfferProof = useUpdateCompanyOfferProof(companyId);

  function handleProofEdit(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setSelected[[]]
    setOpen(false);
  }

  function handleSave(): void {
    updateCompanyOfferProof.mutate(
      {
        companyId: companyId,
        offers: selected,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            "Vilka erbjudanden som ska visas på korrektursidan är uppdaterad"
          );
        },
      }
    );
  }

  const toggleSelection = (item: string) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  return (
    <>
      <Tooltip
        content={"Välj vilka erbjudanden som ska visas på korrektursidan"}
      >
        <IconButton icon={<Icon icon="preview" />} onClick={handleProofEdit} />
      </Tooltip>
      <Dialog
        icon={<MdAdd />}
        open={open}
        fullScreen
        onClose={handleClose}
        headline="Välj vilka erbjudanden som ska visas på korrektursidan"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Stäng
            </Button>
            <Button variant="filled" onClick={handleSave}>
              Spara
            </Button>
          </>
        }
      >
        <div className="flex flex-wrap gap-4 m-2 mt-10 justify-center items-center">
          {companyOffersProof?.offers?.length ?
            companyOffersProof.offers.map((offer) => {
              return (
                <Card
                  key={offer.id}
                  className={cs(
                    "w-fit flex flex-col p-4",
                    selected.includes(offer.id)
                      ? "bg-secondary-container text-on-secondary-container"
                      : ""
                  )}
                  headline={
                    <div className="text-title-medium">
                      {offer.number} / {offer.status?.name}
                    </div>
                  }
                  onClick={() => toggleSelection(offer.id)}
                >
                  <DeviceMockup {...offer} />
                </Card>
              );
            }): <></>}
        </div>
      </Dialog>
    </>
  );
};

export default ProofDialog;
