"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { TopAppBarProps } from "./TopAppBar.types";

const TopAppBar: React.FC<TopAppBarProps> = (props) => {
  const { variant = "center", left, right, headline, className } = props;

  const variantContent = {
    center: (
      <div className={cs("flex items-start", className)}>
        <div className="flex h-16 w-full items-center justify-center px-2 py-1.5 text-on-surface">
          <div className="pr-3 flex gap-2">{left}</div>
          <div className="flex h-full w-full flex-row items-center justify-center pb-[2px] text-center text-title-large overflow-hidden">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">
              {headline}
            </span>
          </div>
          <div className="flex min-w-max flex-row text-body-small">
            <div className="pl-3">{right}</div>
          </div>
        </div>
      </div>
    ),
    small: (
      <div className={cs("flex items-start", className)}>
        <div className="flex h-16 w-full items-center justify-center px-4 py-1.5 text-on-surface">
          <div className="pr-3 flex gap-2">{left}</div>
          <div className="flex h-full w-full flex-row items-center pb-[2px] text-center text-title-large overflow-hidden">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">
              {headline}
            </span>
          </div>
          <div className="flex min-w-max flex-row text-body-small">
            <div className="pl-3">{right}</div>
          </div>
        </div>
      </div>
    ),
    medium: (
      <div className={cs("flex items-start", className)}>
        <div className="flex h-16 w-full items-center justify-center px-2 py-1.5 text-on-surface">
          <div className="pr-3">{left}</div>
          <div className="flex h-full w-full flex-row items-center justify-center pb-[2px] text-center text-title-large overflow-hidden">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">
              {headline}
            </span>
          </div>
          <div className="flex min-w-max flex-row text-body-small">
            <div className="pl-3">{right}</div>
          </div>
        </div>
      </div>
    ),
    large: (
      <div className={cs("flex items-start", className)}>
        <div className="flex h-16 w-full items-center justify-center px-2 py-1.5 text-on-surface">
          <div className="pr-3">{left}</div>
          <div className="flex h-full w-full flex-row items-center justify-center pb-[2px] text-center text-title-large overflow-hidden">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">
              {headline}
            </span>
          </div>
          <div className="flex min-w-max flex-row text-body-small">
            <div className="pl-3">{right}</div>
          </div>
        </div>
      </div>
    ),
  };

  const render = variantContent[variant];

  return (
    <nav
      className={cs(
        "flex w-full flex-col text-on-surface bg-surface-container-lowest",
        className
      )}
    >
      {render}
    </nav>
  );
};

export default TopAppBar;
