import { ErrorComponent, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useForm, FormProvider } from "react-hook-form";

import { campignQuerySelectionOptions, useUpdateCampaignSelectionMutation } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import Estimate from "../-components/Estimate";
import { Card, CardContent } from "@pnpm-monorepo/core/src/ui/components";
import OfferList from "./-components/OfferList";
import PlaceList from "./-components/PlaceList";
import CampaignCheckActivityMonthsBack from "./-components/CampaignCheckActivityMonthsBack";

export const Route = createFileRoute("/campaigns/$campaignId/selections")({
  component: CampaignSelectionComponent,
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    queryClient.ensureQueryData(campignQuerySelectionOptions(campaignId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function CampaignSelectionComponent() {
  const params = Route.useParams();

  const mutation = useUpdateCampaignSelectionMutation(params.campaignId);
  const campignQuerySelection = useSuspenseQuery(
    campignQuerySelectionOptions(params.campaignId)
  );

  const campaignSelection = campignQuerySelection.data;

  const methods = useForm({ values: campaignSelection, mode: "onTouched" });
  const onSubmit = (data) => {
    const command = {
      id: params.campaignId,
      ...data,
    };
    mutation.mutate(command);
  };

  const submitForm = async () => {
    const isValid = await methods.trigger();

    if (isValid) {
      methods.handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex w-full flex-col-reverse lg:flex-row gap-4">
        <div className="flex-1 flex gap-4 flex-col">
          <FormProvider {...methods}>
            <OfferList {...campaignSelection} />
            <PlaceList handleSubmit={submitForm} />
            <CampaignCheckActivityMonthsBack handleSubmit={submitForm} />
          </FormProvider>
        </div>
        <Card className="lg:w-[460px]">
          <CardContent>
            <Estimate showNavButton={false} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
