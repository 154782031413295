import React, { ChangeEvent } from "react";
import { TextFieldProps } from "./TextField.types";
import { cs } from "../../../utils";

const TextField: React.FC<TextFieldProps> = ({
  name,
  value,
  onChange,
  onBlur,
  invalid,
  error,
  disabled = false,
  multiline = false,
  rows = 4,
  placeholder,
  variant,
  className,
  supportingText,
  rightElement,
  leftElement,
  style
}) => {
  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange(e.target.value);
  };

  return (
    <div className={cs(className && className)}>
      <div className="relative flex w-full rounded-t-[8px]">
        {multiline ? (
          <>
            <textarea
              id={name}
              name={name}
              rows={rows}
              autoComplete="off"
              onChange={handleInputChange}
              onBlur={onBlur}
              className={cs(
                "peer flex w-full rounded-t-[8px] border-x-0 border-b-2 border-t-0 border-outline",
                "bg-surface-container-low px-[12px] pr-[48px] pt-[12px] text-body-medium text-on-surface",
                "focus:border-primary focus:outline-none",
                invalid && "focus:border-error border-error bg-error-container"
              )}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              style={style}
            ></textarea>
          </>
        ) : (
          <>
            {leftElement && (
              <div className="absolute left-[8px] flex h-[48px] w-[48px] items-center justify-end text-on-surface">
                {leftElement}
              </div>
            )}
            <input
              id={name}
              name={name}
              type={variant || "text"}
              autoComplete="off"
              onChange={handleInputChange}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              className={cs(
                "peer flex h-[48px] w-full  items-center rounded-t-[8px]",
                "border-x-0 border-b-2 border-t-0 border-on-surface-variant bg-surface-container px-[12px]",
                "text-body-medium text-on-surface focus:border-primary focus:outline-none",
                invalid && "focus:border-error border-error bg-error-container",
                leftElement && "pl-[56px]",
                rightElement && "pr-[56px]"
              )}
              style={style}
            />
            {rightElement && (
              <div className="absolute right-[8px] flex h-[48px] w-[48px] items-center justify-end text-on-surface">
                {rightElement}
              </div>
            )}
          </>
        )}
      </div>
      {invalid && error && (
        <div className="text-label-small my-2 text-error">{error.message}</div>
      )}
      {supportingText && (
        <div className="text-label-small my-2">{supportingText}</div>
      )}
    </div>
  );
};

export default TextField;
