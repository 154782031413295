import api from "../../../utils/api";
import { PlaceType, SortOrder, WeekType } from "../../../utils/types";

export type ReceviersSortBy =
  | "code"
  | "placeName"
  | "week_ISO"
  | "createdAt"
  | "name";

export type AddNewReceiverCommandType = {
  firstName: string;
  lastName: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  place?: PlaceType;
  week_ISO?: WeekType;
};

export const fetchReceivers = async ({
  q,
  page,
  sortBy,
  sortOrder,
  consumer,
  place,
}: {
  q?: string;
  page?: number;
  sortBy?: ReceviersSortBy;
  sortOrder?: SortOrder;
  consumer?: string[];
  place?: PlaceType[];
} = {}) => {

  const receivers = await api
    .get(`receivers`, {
      params: {
        search: q,
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        consumer: consumer?.join(","),
        place: place?.join(","),
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return receivers;
};

export async function createReceiver({
  ...data
}: Partial<AddNewReceiverCommandType>) {
  const result = await api
    .post(`receivers`, {
      ...data,
      placeId: data.place?.id,
      week_ISO: data.week_ISO?.id,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
