import React, { Suspense, useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
  Dialog,
} from "@pnpm-monorepo/core/src/ui/components";
import { CompanyType } from "../../../../utils/types";
import { FallbackImage, Image } from "../../../../utils/renderers";
import { MdAdd, MdDelete } from "@pnpm-monorepo/core/src/ui/icons";
import ImageUploader from "../../../../components/ImageUploader";
import { useAddCompanyLogo, useDeleteCompanyLogo } from "../-api/queryOptions";

export default function Logotype({ company }: { company: CompanyType }) {
  const [edit, setEdit] = React.useState(false);
  const { image } = company;
  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title="Logotyp"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-6">
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={image ? image.url : ""}
                      className="object-cover max-h-[200px]"
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Logotyp"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <div className="flex justify-start gap-2 p-3 border-t-[1px] border-outline-variant">
            <AddLogotype companyId={company.id} />
            {image ? (
              <RemoveLogotype companyId={company.id} imageId={image.id} />
            ) : null}
          </div>
          <CardContent>
            <div className="mb-6">
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={image ? image.url : ""}
                      className="object-cover "
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

const AddLogotype = ({ companyId }: { companyId: number }) => {
  const [open, setOpen] = useState<boolean>(false);
  const addLogo = useAddCompanyLogo(companyId);

  function handleAddImage(): void {
    setOpen(true);
  }

  function handleOk(blob: Blob, image: File): void {
    const file = new File([blob], image.name, { type: image.type });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", companyId.toString());

    addLogo.mutate(
      {
        companyId,
        formData,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdAdd size={18} />}
        onClick={handleAddImage}
      >
        Välj en bild
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        headline="Lägg till bild"
        icon={<MdAdd />}
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
          </>
        }
      >
        <div className="flex flex-col justify-center items-center">
          <ImageUploader
            onImageUpload={handleOk}
            isPending={addLogo.isPending}
            useCrop={false}
          />
        </div>
      </Dialog>
    </>
  );
};

const RemoveLogotype = ({
  companyId,
  imageId,
}: {
  companyId: number;
  imageId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const deleteLogo = useDeleteCompanyLogo(companyId, imageId);

  function handleDeleteImage(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    setOpen(false);
    deleteLogo.mutate(
      {
        companyId: companyId,
        imageId: imageId,
      },
      {
        onSuccess() {
        },
      }
    );
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdDelete size={18} />}
        onClick={handleDeleteImage}
      >
        Ta bort befintlig bild
      </Button>
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort befintlig bild?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={deleteLogo.isPending}
            >
              Ok
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};
