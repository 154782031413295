import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import React from "react";
import RouterProgress from "../../../components/RouterProgress";
import DefaultPendingComponent from "../../../components/PendingComponent";
import { LinkBox, Table } from "@pnpm-monorepo/core/src/ui/components";
import { YesNo } from "../../../utils/renderers";
import { sellersQueryOptions } from "../../../utils/data/seller";
import EditDialog from "./-components/EditDialog";
import AddDialog from "./-components/AddDialog";
import DeleteDialog from "./-components/DeleteDialog";
import { SellerType } from "../../../utils/types";

export const Route = createFileRoute("/adminland/sellers")({
  component: SellersComponent,
});

function SellersComponent() {
  const [searchDraft, setSearchDraft] = React.useState("");
  const { data, isPending } = useQuery(
    sellersQueryOptions(searchDraft, "name")
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setSearchDraft(v);
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="py-4 flex flex-col gap-4 grow">
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center">
              <div className="flex-1 flex items-center gap-2 lg:flex-row flex-col">
                <div className="flex-1 text-headline-large flex items-center gap-2">
                  <Link to="/adminland">
                    <LinkBox>Adminland</LinkBox>
                  </Link>{" "}
                  / Säljare <RouterProgress />
                </div>{" "}
                <input
                  type="text"
                  className="p-[4px] bg-surface-container-low rounded-extra-large pl-6 pr-16 text-headline-small placeholder:text-on-surface-variant"
                  placeholder="Sök..."
                  onChange={handleSearch}
                  value={searchDraft}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <AddDialog />
        </div>
      </div>

      {isPending ? (
        <DefaultPendingComponent />
      ) : (
        <>
          <div className="flex flex-col w-full my-6 text-body-medium">
            {data.length ? (
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>Namn</Table.HeadCell>
                      <Table.HeadCell>E-post</Table.HeadCell>
                      <Table.HeadCell>Telefon</Table.HeadCell>
                      <Table.HeadCell>Säljföretag</Table.HeadCell>
                      <Table.HeadCell className="border-r-0">
                        Aktiv
                      </Table.HeadCell>
                      <Table.HeadCell className="sticky right-0 bg-surface-container-highest shadow-sticky-left"></Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {data?.map((obj : SellerType) => {
                      return (
                        <Table.Row key={obj.id}>
                          <Table.RowCell>{obj.name}</Table.RowCell>
                          <Table.RowCell>{obj.email}</Table.RowCell>
                          <Table.RowCell>{obj.phone}</Table.RowCell>
                          <Table.RowCell>{obj.sellerCompany}</Table.RowCell>
                          <Table.RowCell className="border-r-0">
                            <YesNo value={obj.active} />
                          </Table.RowCell>
                          <Table.RowCell
                            justify="justify-start"
                            className=" sticky right-0 bg-surface overflow-hidden shadow-sticky-left w-16"
                          >
                            <EditDialog obj={obj} />
                            {obj.canBeDeleted && <DeleteDialog obj={obj} />}
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
            ) : (
              <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
                Hittade inte någon säljare.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
