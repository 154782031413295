
import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../../utils/api";

export type SelectionPlaceType = {
  id: number;
  name: string;
  active: boolean;
};

export type SelectionOfferType = {
  id: number;
  number: string;
  companyId: number;
  companyName: string;
  validToText: string;
  offerWorthText: string;
  offerPrepositionText: string;
  offerServiceText: string;
  information: string;
  statusId: number;
  statusName: string;
  imageUrl: string;
  places: SelectionPlaceType[];
};

export type SelectionType = {
  id: number | string,
  checkActivityMonthsBack: number;
  offers: SelectionOfferType[];
  places: SelectionPlaceType[];
};

export const fetchCampaignSelection = async (id: number | string) => {
  const response = await api
    .get(`campaigns/${id}/selection`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function removeCampaignOffer({
  campaignId,
  offerId,
}: {
  campaignId: number | string;
  offerId: number;
}) {
  const result = await api
    .put(`campaigns/${campaignId}/selection/removeoffer`, {
      campaignId,
      offerId,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addCampaignOffer({
  campaignId,
  offerId,
}: {
  campaignId: number | string;
  offerId: number;
}) {
  const result = await api
    .put(`campaigns/${campaignId}/selection/addoffer`, {
      campaignId,
      offerId,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateCampaignSelection({
  id,
  ...updated
}: PickAsRequired<Partial<SelectionType>, "id">) {
  const result = await api
    .put(`campaigns/${id}/selection`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}