import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ErrorComponent, Link, createFileRoute } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
  LinkBox,
  Value,
} from "@pnpm-monorepo/core/src/ui/components";

import {
  campignQueryOptions,
  campignStatusesQueryOptions,
  campignTemplatesQueryOptions,
  useUpdateCampaignMutation,
} from "./-api/queryOptions";
import DefaultPendingComponent from "../../../components/PendingComponent";
import { SmallDateString, YearMonthString } from "../../../utils/renderers";
import Estimate from "./-components/Estimate";
import Activity from "./-components/Activity";
import TextFieldWrapper from "../../../components/FormWrappers/TextFieldWrapper";

import SelectWrapper from "../../../components/FormWrappers/SelectWrapper";
import TextareaWrapper from "../../../components/FormWrappers/TextareaWrapper";
import { CampaignType } from "../../../utils/types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  subject: z.string().min(1, "E-post ämne är obligatoriskt"),
  header: z.string().min(1, "E-post rubrik är obligatoriskt"),
  description: z.string().nullable(),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  template: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  campaignDate: z.string().min(1, { message: "Giltigt från är obligatoriskt" }),
  campaignDateUntil: z.string().nullable(),
});

export const Route = createFileRoute("/campaigns/$campaignId/")({
  component: CampaignIndexComponent,
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    queryClient.ensureQueryData(campignQueryOptions(campaignId));
    queryClient.ensureQueryData(campignStatusesQueryOptions());
    queryClient.ensureQueryData(campignTemplatesQueryOptions());
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function CampaignIndexComponent() {
  const [edit, setEdit] = React.useState(false);

  const params = Route.useParams();

  const updateCampaignMutation = useUpdateCampaignMutation(params.campaignId);
  const campaignQuery = useSuspenseQuery(
    campignQueryOptions(params.campaignId)
  );

  const campaignStatusQuery = useSuspenseQuery(campignStatusesQueryOptions());
  const campignTemplatesQuery = useSuspenseQuery(
    campignTemplatesQueryOptions()
  );

  const campaign = campaignQuery.data;
  const campaignStatus = campaignStatusQuery.data;
  const campignTemplates = campignTemplatesQuery.data;

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      id: campaign.id,
      name: campaign.name,
      campaignDate: campaign.campaignDate
        ? dayjs(campaign.campaignDate).format("YYYY-MM-DD")
        : undefined,
      campaignDateUntil: campaign.campaignDateUntil
        ? dayjs(campaign.campaignDateUntil).format("YYYY-MM-DD")
        : undefined,
      status: campaign.status,
      subject: campaign.subject ?? "",
      header: campaign.header ?? "",
      description: campaign.description ?? "",
      template: campaign.template,
    },
  });

  const onSubmit: SubmitHandler<CampaignType> = (data) => {
    return updateCampaignMutation.mutate(
      {
        ...data,
        id: Number(params.campaignId),
      },
      {
        onSuccess: () => {
          setEdit(false);
          reset(data);
        },
      }
    );
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-4">
      <div className="flex w-full gap-4 flex-col lg:flex-row ">
        {!edit ? (
          <Card className="lg:w-[560px]">
            <CardHeader
              title={campaign.name ? campaign.name : <span className=""></span>}
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(true)}
                    iconLeft={<Icon icon="edit" size={20} />}
                  >
                    Redigera
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(true)}
                    icon={<Icon icon="edit" size={20} />}
                  />
                </>
              }
            />

            <CardContent>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Kampanjdatum
                </div>
                <div className="text-body-medium">
                  Fr.o.m{" "}
                  {campaign.campaignDate ? (
                    <span>
                      <SmallDateString value={campaign.campaignDate} />
                    </span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}{" "}
                  → T.o.m.{" "}
                  {campaign.campaignDateUntil ? (
                    <span>
                      <SmallDateString value={campaign.campaignDateUntil} />
                    </span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  E-post ämne
                </div>
                <div className="text-body-medium">
                  {campaign.subject ? (
                    campaign.subject
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  E-post rubrik
                </div>
                <div className="text-body-medium">
                  {campaign.header ? (
                    campaign.header
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  E-post kampanjtext
                </div>
                <div className="text-body-medium">
                  {campaign.description ? (
                    campaign.description
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              {campaign.campaignBasis && (
                <div className="mb-4">
                  <div className="text-label-medium text-on-surface-variant">
                    Kampanjunderlag
                  </div>
                  <div className="text-body-medium">
                    <LinkBox className="whitespace-normal">
                      <Link
                        to={`/companies/$companyId/campaigns/$campaignId`}
                        params={{
                          campaignId: campaign.campaignBasis.id,
                          companyId: campaign.campaignBasis.companyId,
                        }}
                      >
                        {campaign.campaignBasis.basisText} (
                        <YearMonthString
                          value={campaign.campaignBasis.campaignMonth}
                        />
                        )
                      </Link>
                    </LinkBox>
                  </div>
                </div>
              )}
              {campaign.relatedCampaignId !== 0 ? (
                <div className="mb-4">
                  <div className="text-label-medium text-on-surface-variant">
                    {campaign.repeatId === 0
                      ? "Repeatutskick"
                      : "Originalutskick"}
                  </div>
                  <div className="text-body-medium">
                    <LinkBox>
                      <Link
                        to={`/campaigns/$campaignId`}
                        params={{ campaignId: campaign.relatedCampaignId }}
                      >
                        {campaign.relatedCampaignDate}
                      </Link>
                    </LinkBox>
                  </div>
                </div>
              ) : null}
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Status
                </div>
                <div className="text-body-medium">
                  {campaign.status ? (
                    campaign.status.name
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card className="lg:w-[560px]" variant="outlined">
            <CardHeader
              title={campaign.name ? campaign.name : <span className=""></span>}
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(false)}
                    iconLeft={<Icon icon="close" size={20} />}
                  >
                    Avsluta redigering
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(false)}
                    icon={<Icon icon="close" />}
                  />
                </>
              }
            />
            <CardContent className=" border-t-[1px] border-outline-varian">
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Namn
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="name" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Kampanjdatum
                </div>
                <div className="pt-2 flex gap-2 items-center w-full">
                  <TextFieldWrapper
                    name="campaignDate"
                    control={control}
                    variant="date"
                    className="w-full"
                  />
                  <span> → </span>
                  <TextFieldWrapper
                    name="campaignDateUntil"
                    control={control}
                    variant="date"
                    className="w-full"
                  />
                </div>
              </div>

              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Status
                </div>

                <div className="pt-2">
                  <SelectWrapper
                    name="status"
                    control={control}
                    className="min-w-32"
                    options={campaignStatus}
                    getOptionLabel={(option: Value) => option?.name}
                  />
                </div>
              </div>

              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  E-post ämne
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="subject" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  E-post rubrik
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="header" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  E-post kampanjtext
                </div>
                <div className="pt-2">
                  <TextareaWrapper
                    name="description"
                    control={control}
                    rows={10}
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  E-postmall
                </div>
                <div className="pt-2">
                  <SelectWrapper
                    name="template"
                    control={control}
                    className="min-w-32"
                    options={campignTemplates}
                    getOptionLabel={(option: Value) => option?.name}
                  />
                </div>
              </div>
            </CardContent>
            <div className="flex justify-end gap-2 p-3">
              <Button variant="filled" onClick={handleSubmit(onSubmit)} disabled={updateCampaignMutation.isPending}>
                Spara & stäng
              </Button>
            </div>
          </Card>
        )}

        <Card className="flex-1" headline="Aktivitet">
          <CardContent>
            <div className="flex flex-col gap-4">
              <Estimate showNavButton={true} />
              <Activity />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
