import {
  ErrorComponent,
  Link,
  Outlet,
  createFileRoute,
} from "@tanstack/react-router";

import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { IconButton, TopAppBar } from "@pnpm-monorepo/core/src/ui/components";

import { MdArrowBack } from "@pnpm-monorepo/core/src/ui/icons";
import { consumerQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../components/PendingComponent";
import { MoreMenu } from "./-components/MoreMenu";
import Tabs from "../../../components/Tabs";
import { consumerOffersQueryOptions } from "./offers/-api/queryOptions";
import { consumerEventsQueryOptions } from "./events/-api/queryOptions";

export const Route = createFileRoute("/consumers/$consumerId")({
  loader: ({ context: { queryClient }, params: { consumerId } }) => {
    queryClient.ensureQueryData(consumerQueryOptions(consumerId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: ConsumerComponent,
});

function ConsumerComponent() {
  const params = Route.useParams();
  const consumerQuery = useSuspenseQuery(
    consumerQueryOptions(params.consumerId)
  );
  const consumer = consumerQuery.data;

  // prevload
  useQuery(consumerOffersQueryOptions(params.consumerId));
  useQuery(consumerEventsQueryOptions(params.consumerId, [1,2,3]));

  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        left={
          <Link to={"/consumers"} search={true}>
            <IconButton icon={<MdArrowBack />} />
          </Link>
        }
        right={
          <div className="flex gap-2 items-center">
            <MoreMenu consumer={consumer} />
          </div>
        }
        headline={<>Konsument {consumer.email}</>}
        className="sticky top-0 z-50 bg-surface"
      />

      <div className="flex-1 flex flex-col lg:flex-row">
        <div className="p-6 flex flex-col gap-4 grow">
          <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "account",
                  header: "Konto",
                  to: "/consumers/$consumerId",
                  params: { consumerId: params.consumerId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "other",
                  header: "Övriga uppgifter",
                  to: "/consumers/$consumerId/properties",
                  params: { consumerId: params.consumerId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "events",
                  header: "Erbjudandehändelser",
                  to: "/consumers/$consumerId/events",
                  params: { consumerId: params.consumerId },
                  search: true,
                  activeOptions: { exact: false },
                },
                {
                  id: "offers",
                  header: "Aktuella erbjudanden",
                  to: "/consumers/$consumerId/offers",
                  params: { consumerId: params.consumerId },
                  search: true,
                  activeOptions: { exact: false },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>
          <div className="flex justify-center gap-4 mx-auto w-full lg:max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
