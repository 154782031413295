"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { ButtonProps } from "./Button.types";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      children,
      icon,
      iconLeft,
      iconRight,
      disabled = false,
      variant = "text",
      onClick,
      fullwidth = false,
      size = "standard",
      type,
      ...other
    } = props;

    let variantClass = "";

    if (variant === "text") {
      variantClass =
        "text-primary disabled:hover:bg-transparent hover:bg-surface-container";
    }

    if (variant === "elevated") {
      variantClass =
        "text-primary bg-surface-container-low shadow-mm-1 hover:shadow-mm-2";
    }

    if (variant === "outlined") {
      variantClass =
        "text-primary border-[1px] border-outline hover:shadow-mm-1";
    }

    if (variant === "tonal") {
      variantClass =
        "text-on-secondary-container bg-secondary-container hover:shadow-mm-1";
    }

    if (variant === "filled") {
      variantClass = "text-on-primary bg-primary hover:hover:shadow-mm-2";
    }
    let sizeClass =
      "relative flex h-10 w-max cursor-pointer items-center justify-center gap-2 overflow-hidden text-sm leading-5 tracking-[0.1px] font-normal px-4 py-2.5 rounded-full disabled:opacity-[.38] disabled:cursor-default";
    if (size === "small") {
      sizeClass += " h-8 px-4 py-1.5";
    }

    return (
      <button
        ref={ref}
        className={cs(
          sizeClass,
          "whitespace-nowrap transition-all duration-300 ease-in-out select-none",
          variantClass,
          className,
          fullwidth && "w-full"
        )}
        disabled={disabled}
        onClick={onClick}
        {...other}
      >
        {iconLeft && iconLeft}
        {icon && icon}
        {children && children}
        {iconRight && iconRight}
      </button>
    );
  }
);

export default Button;
