import api from "../../../../../utils/api";

export const fetchCompanyCampaigns = async (id: number | string) => {
  const companyCampaigns = await api
    .get(`companies/${id}/campaigns`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyCampaigns;
};
