import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";

import { Route } from "../route";
import TextFieldWrapper from "../../../components/FormWrappers/TextFieldWrapper";
import { AddNewCampaignCommandType } from "../-api/campaigns";
import { useCreateCampaignMutation } from "../-api/queryOptions";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  campaignDate: z.string().min(1, "Kampanjdatum är obligatoriskt"),
});

const AddNewCampaign = () => {
  const navigate = useNavigate({ from: Route.fullPath});
  const [open, setOpen] = useState<boolean>(false);

  const mutation =  useCreateCampaignMutation();

  const { control, handleSubmit, reset } = useForm<AddNewCampaignCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      campaignDate: dayjs().format("YYYY-MM-DD")
    },
  });

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<AddNewCampaignCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/campaigns/$campaignId",
            search: (old) => ({
              ...old,
            }),
            params: { campaignId: data },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button
          variant="filled"
          iconLeft={<Icon icon="add" size={20} />}
          onClick={handleAdd}
        >
          Lägg till
        </Button>
      </>

      <Dialog
        icon={<Icon icon={"campaign"} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till kampanj"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Kampanjdatum
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="campaignDate" control={control} variant="date" />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewCampaign;
