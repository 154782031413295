import { useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { ContractType } from "../../../../../../utils/types";
import { SmallDateString } from "../../../../../../utils/renderers";

import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";
import { useQuery } from "@tanstack/react-query";

import SelectWrapper from "../../../../../../components/FormWrappers/SelectWrapper";
import {
  contractStatusesQueryOptions,
  industriesQueryOptions,
  sellersQueryOptions,
} from "../../../../../../utils/data/common";
import { useUpdateContractMutation } from "../../../../../../utils/data/contract";

const schema = z.object({
  number: z.string().min(1, { message: "Nummer är obligatoriskt" }),
  companySigner: z.string(),
  digitallyCampaignOther: z.string(),
  seller: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  industry: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  contractDate: z
    .string()
    .min(1, { message: "Kontraktsdatum är obligatoriskt" }),
  validFrom: z.string().min(1, { message: "Giltigt från är obligatoriskt" }),
  validTo: z.string().nullable(),
});

const Common = ({ contract }: { contract: ContractType }) => {
  const [edit, setEdit] = useState<boolean>(false);

  const mutation = useUpdateContractMutation(contract.id);

  const sellersQuery = useQuery(sellersQueryOptions());
  const industriesQuery = useQuery(industriesQueryOptions());
  const contractStatusesQuery = useQuery(contractStatusesQueryOptions());

  const { control, handleSubmit, reset } = useForm<ContractType>({
    resolver: zodResolver(schema),
    defaultValues: {
      number: contract.number ?? "",
      companySigner: contract.companySigner ?? "",
      digitallyCampaignOther: contract.digitallyCampaignOther ?? "",
      seller: contract.seller,
      industry: contract.industry,
      status: contract.status,
      contractDate: contract.contractDate
        ? dayjs(contract.contractDate).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
      validFrom: contract.validFrom
        ? dayjs(contract.validFrom).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
      validTo: contract.validTo
        ? dayjs(contract.validTo).format("YYYY-MM-DD")
        : null,
    },
  });

  const onSubmit: SubmitHandler<ContractType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        id: contract.id,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit ? (
        <Card>
          <CardHeader
            title="Kontrakt"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Nummer
              </div>
              <div className="text-body-medium">{contract.number}</div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kategori
              </div>
              <div className="text-body-medium">{contract.industry?.name}</div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Säljare
              </div>
              <div className="text-body-medium">{contract.seller?.name}</div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kontraktsdatum
              </div>
              <div className="text-body-medium">
                <SmallDateString value={contract.contractDate} />
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Giltigt från → till
              </div>
              <div className="text-body-medium">
                Fr.o.m{" "}
                {contract.validFrom ? (
                  <SmallDateString value={contract.validFrom} />
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}{" "}
                → T.o.m.{" "}
                {contract.validTo ? (
                  <SmallDateString value={contract.validTo} />
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Signerad av
              </div>
              <div className="text-body-medium">{contract.companySigner}</div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kampanjerbjudande avseende
              </div>
              <div className="text-body-medium">
                {contract.digitallyCampaignOther}

                {contract.digitallyCampaignOther ? (
                  contract.digitallyCampaignOther
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="text-body-medium">{contract.status?.name}</div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card variant="outlined">
          <CardHeader
            title={"Kontrakt"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Nummer
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="number" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kategori
              </div>
              <div className="pt-2">
                {!industriesQuery.isPending && (
                  <SelectWrapper
                    name="industry"
                    control={control}
                    className="min-w-32"
                    options={industriesQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                )}
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Säljare
              </div>
              <div className="pt-2">
                {!sellersQuery.isPending && (
                  <SelectWrapper
                    name="seller"
                    control={control}
                    className="min-w-32"
                    options={sellersQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                )}
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kontraktsdatum
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="contractDate"
                  control={control}
                  variant="date"
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col">
              <div className="text-label-medium text-on-surface-variant">
                Giltigt från → till
              </div>
              <div className="pt-2 flex gap-2 items-baseline w-full">
                <TextFieldWrapper
                  name="validFrom"
                  control={control}
                  variant="date"
                  className="w-full"
                />
                <span className="">
                  <span> → </span>
                </span>
                <TextFieldWrapper
                  name="validTo"
                  control={control}
                  variant="date"
                  className="w-full"
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Signerad av
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="companySigner" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kampanjerbjudande avseende
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="digitallyCampaignOther"
                  control={control}
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="pt-2">
                {!contractStatusesQuery.isPending && (
                  <SelectWrapper
                    name="status"
                    control={control}
                    className="min-w-32"
                    options={contractStatusesQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                )}
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3 ">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default Common;
