import { motion } from "framer-motion";
import { CircularProgressProps } from "./CircularProgress.types";

const CircularProgress: React.FC<CircularProgressProps> = ({
  progress = 0,
  size = 32,
  strokeWidth = 4
}) => {
  // Calculate radius and circumference
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Calculate progress offset
  const progressOffset = circumference * (1 - progress);

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <motion.circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        strokeWidth={strokeWidth}
        className="stroke-current text-secondary-container"
      />

        <motion.circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          className="stroke-current text-primary"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset} // Use progress offset for non-indeterminate mode
          initial={{ strokeDashoffset: circumference }}
          animate={{ strokeDashoffset: progressOffset }}
        />

    </svg>
  );
};

export default CircularProgress;