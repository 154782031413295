import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdatePlaceCommandType = {
  id: number;
  name: string;
  active: boolean;
};

export type AddPlaceCommandType = {
  name: string;
  active: boolean;
};

export const fetchPlaces = async () => {
  const result = await api
    .get(`places`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function updatePlace({
  id,
  ...updated
}: PickAsRequired<Partial<UpdatePlaceCommandType>, "id">) {
  const result = await api
    .put(`places/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addPlace({ ...added }: AddPlaceCommandType) {
  const result = await api
    .post(`places`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deletePlace({ id }: { id: number }) {
  const result = await api
    .delete(`places/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
