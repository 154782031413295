"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { IconButtonProps } from "./IconButton.types";

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const {
    className,
    icon,
    disabled = false,
    variant = "standard",
    size = "standard",
    onClick,
  } = props;

  let variantClass =
    "text-on-surface bg-inherit hover:bg-surface-container-highest";

  if (variant === "filled") {
    variantClass = "text-on-primary bg-primary hover:shadow-mm-2";
  }

  if (variant === "tonal") {
    variantClass =
      "text-on-secondary-container bg-secondary-container hover:bg-secondary-container";
  }

  if (variant === "outlined") {
    variantClass =
      "text-primary border-[1px] border-outline hover:bg-secondary-container-hover";
  }

  let sizeClass = "";
  if (size === "small") {
    sizeClass = "iconSmall";
  }

  return (
    <button
      className={cs(
        "flex max-h-10 max-w-[40px] items-center justify-center gap-3 m-0 p-2 rounded-full disabled:opacity-30 disabled:cursor-default transition-all duration-300 ease-in-out",
        variantClass,
        sizeClass,
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {/* {icon && React.cloneElement(icon as React.ReactElement)} */}
      {icon && icon}
    </button>
  );
};

export default IconButton;
