import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";

import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Button,
  Dialog,
  IconButton,
  Icon,
  Menu2,
  LinkBox,
} from "@pnpm-monorepo/core/src/ui/components";

import {
  useCreateConsumerMutation,
  useDeleteReceiverMutation,
} from "../-api/queryOptions";

import { ReceiverType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { CreateConsumerCommandType } from "../-api/receiver";

export function MoreMenu({ receiver }: { receiver: ReceiverType }) {
  const [open, setOpen] = useState<boolean>(false);
  const [openCreateConsumer, setOpenCreateConsumer] = useState<boolean>(false);
  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  function handleCreateAccount(): void {
    setOpenCreateConsumer(true);
  }

  const handleCloseCreateConsumer = (): void => {
    setOpenCreateConsumer(false);
  };

  return (
    <>
      <Menu2.DropdownMenu>
        <Menu2.DropdownMenuTrigger asChild>
          <div>
            <IconButton icon={<Icon icon="more_vert" />} />
          </div>
        </Menu2.DropdownMenuTrigger>
        <Menu2.DropdownMenuContent align="end" alignOffset={8} forceMount>
          {!receiver.consumerId && (
            <>
              <Menu2.DropdownMenuItem onClick={handleCreateAccount}>
                <Icon
                  icon="person_add"
                  size={24}
                  className="pr-[12px] text-on-surface-variant"
                />
                <span>Skapa konsument</span>
              </Menu2.DropdownMenuItem>
              <Menu2.DropdownMenuSeparator />
            </>
          )}
          <Menu2.DropdownMenuItem onClick={handleDelete}>
            <Icon
              icon="delete"
              size={24}
              className="pr-[12px] text-on-surface-variant"
            />
            <span>Ta bort</span>
          </Menu2.DropdownMenuItem>
        </Menu2.DropdownMenuContent>
      </Menu2.DropdownMenu>
      <DeleteReceiver
        open={open}
        onClose={handleClose}
        receiverId={receiver.id}
      />
      <CreateConsumer
        open={openCreateConsumer}
        onClose={handleCloseCreateConsumer}
        receiverId={receiver.id}
      />
    </>
  );
}

const schema = z.object({
  email: z
    .string()
    .min(1, "E-postadress är obligatoriskt")
    .email("E-postadress är ogiltig"),
});

export const CreateConsumer = ({
  open,
  onClose,
  receiverId,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
  receiverId: number;
}) => {
  const mutation = useCreateConsumerMutation(receiverId);

  const { control, handleSubmit, reset } = useForm<CreateConsumerCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: receiverId,
      email: "",
    },
  });

  const onSubmit: SubmitHandler<CreateConsumerCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        id: receiverId,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = (): void => {
    onClose(false);
    reset();
  };

  return (
    <>
      <Dialog
        icon={<Icon icon="person_add" />}
        open={open}
        onClose={handleClose}
        headline="Skapa konsument?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Skapa konsument
            </Button>
          </>
        }
      >
        <div className="my-2">
          {/* <p className="flex items-center justify-center px-6 text-body-medium text-on-surface-variant mb-4">
            Ange den inflyttades e-postadress. E-postadress blir även login för
            kontot. Följande kommer att ske när kontot skapas;
          </p> */}
          {/* <ul
            role="list"
            className="px-6 text-body-medium text-on-surface-variant space-y-3 marker:text-on-surface-variant list-inside list-decimal"
          >
            <li>
              Ett välkomnande e-postmeddelande skickas till den inflyttade med
              ett autogenererat lösenord.
            </li>
            <li>
              Vi skickar med länken{" "}
              <LinkBox>
                <a
                  href="https://www.mobilkupongen.se/konsument/logga-in-i-appen/"
                  target="_blank"
                >
                  https://www.mobilkupongen.se/konsument/logga-in-i-appen/
                </a>
              </LinkBox>{" "}
              där den inflyttade uppmanas att ladda ner appen och/eller logga in
              på appen med e-postadress och valt lösenord.
            </li>
            <li>
              Observera att den inflyttade nu bara skall logga in i appen, inte
              skriva någon välkomstkod och skapa konto. Kontot skapar du ju här.
            </li>
          </ul> */}
          <div className="my-2 mt-6">
            {mutation.error && (
              <div className="flex flex-col text-body-medium break-words my-2 mb-6 p-3 bg-error-container text-on-error-container">
                {Object.entries(mutation.error.response.data.errors).map(
                  ([key, obj]) => (
                    <div key={key}>{obj[0]}</div>
                  )
                )}
              </div>
            )}

            {mutation.isSuccess && (
              <div className="flex text-body-medium mb-6 my-2 p-3 bg-tertiary-container text-on-tertiary-container">
                Lösenord uppdaterad
              </div>
            )}

            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Ange e-postaddress
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="email" control={control} />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteReceiver = ({
  open,
  onClose,
  receiverId,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
  receiverId: number;
}) => {
  const navigate = useNavigate({ from: `/receivers/${receiverId}` });

  const deleteReceiver = useDeleteReceiverMutation(receiverId);

  const handleClose = (): void => {
    onClose(false);
  };

  const handleOk = (): void => {
    deleteReceiver.mutate(
      {
        id: receiverId,
      },
      {
        onSuccess: () => {
          navigate({
            to: "/receivers",
            search: (old) => ({
              ...old,
            }),
          });
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        icon={<Icon icon="delete" />}
        open={open}
        onClose={handleClose}
        headline="Ta bort inflyttad?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={deleteReceiver.isPending}
            >
              Ok
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};
