import { keepPreviousData, queryOptions, useMutation } from "@tanstack/react-query";
import { PlaceType, SellerType, SortOrder, StatusType } from "../../../utils/types";
import { CompaniesSortBy, createCompany, fetchCompanies } from "./companies";
import { queryClient } from "../../../App";

export const companiesQueryOptions = (opts: {
    q?: string;
    page?: number;
    sortBy?: CompaniesSortBy;
    sortOrder?: SortOrder;
    place?: PlaceType[];
    status?: StatusType[];
    seller?: SellerType[];
  }) => {
    return queryOptions({
      queryKey: ["companies", opts.q, opts.page, opts.sortBy, opts.sortOrder, opts.place, opts.status, opts.seller],
      queryFn: () => fetchCompanies(opts),
      placeholderData: keepPreviousData,
    });
  };

  export const useCreateCompanyMutation = () => {
    return useMutation({
      mutationKey: ["company", "create"],
      mutationFn: createCompany,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };