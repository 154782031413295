import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";


import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { UpdateSellerCommandType, useUpdateSellerMutation } from "../../../../utils/data/seller";
import { SellerType } from "../../../../utils/types";
import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";



const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  phone: z.string().nullable(),
  email: z
    .string()
    .min(1, "E-postadress är obligatoriskt")
    .email("E-postadress är ogiltig"),
    active: z.boolean(),
});

const EditDialog = ({ obj }: { obj: SellerType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useUpdateSellerMutation(obj.id);

  const { control, handleSubmit, reset } = useForm<UpdateSellerCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: obj.name,
      email: obj.email,
      phone: obj.phone,
      active: obj.active,
    },
  });

  const onSubmit: SubmitHandler<UpdateSellerCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        id: obj.id,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleEdit} icon={<Icon icon="edit" size={22} />} />
      <Dialog
        icon={<Icon icon="edit" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Uppdatera säljare?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="name"
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              E-postadress 
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="email"
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Telefon
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="phone"
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDialog;
