import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  Icon,
  Dialog,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useCopyOffer } from "../-api/queryOptions";
import { Route } from "..";
import { OfferType } from "../../../../utils/types";

const CopyDialog = ({ offer }: { offer: OfferType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const mutation = useCopyOffer(offer.id);

  const handleCopy = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation(); // Stop the event from bubbling up
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    mutation.mutate(
      {
        offerId: Number(offer.id),
      },
      {
        onSuccess: (data: OfferType) => {
          navigate({
            to: "/companies/$companyId/offers/$offerId",
            search: (old) => ({
              ...old,
            }),
            params: {
              companyId: data.company.id.toString(),
              offerId: data.id.toString(),
            },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <Button
        variant="filled"
        iconLeft={<Icon icon="content_copy" size={20} />}
        onClick={handleCopy}
        className="lg:flex hidden"
      >
        Kopiera
      </Button>
      <IconButton
        icon={<Icon icon="content_copy" />}
        className="lg:hidden flex"
        variant="filled"
        onClick={handleCopy}
      />

      <Dialog
        icon={<Icon icon={"content_copy"} />}
        open={open}
        onClose={handleClose}
        headline="Vill du kopiera erbjudandet?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={mutation.isPending}
            >
              Kopiera
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};

export default CopyDialog;
