
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";

import { Dialog, Button } from "@pnpm-monorepo/core/src/ui/components";
import { useAddImage } from "../../utils/useAddImage";

import ImageUploader from "../ImageUploader";

export const AddImageModal = () => {
  const addImage = useAddImage();

  function handleImageUpload(blob: Blob, image: File): void {
    if (addImage.onConfirm) {
      const file = new File([blob], image.name, { type: image.type });
      const formData = new FormData();
      formData.append("file", file);
      addImage.onConfirm(formData);
    }
  }

  return (
    <Dialog
      open={addImage.isOpen}
      onClose={addImage.onClose}
      headline="Lägg till bild"
      icon={<MdAdd />}
      actions={
        <>
          <Button variant="text" onClick={addImage.onClose}>
            Avbryt
          </Button>
        </>
      }
    >
      <div className="flex flex-col justify-center items-center">
        <ImageUploader onImageUpload={handleImageUpload} isPending={false} thumbnail={false} />
      </div>
    </Dialog>
  );
};
