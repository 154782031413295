"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { FilterChipProps } from "./FilterChip.types";
import Icon from "../Icon";

const FilterChip = React.forwardRef<HTMLButtonElement, FilterChipProps>(
  (props, ref) => {
    const {
      className,
      children,
      trailingIcon,
      onClick,
      disabled = false,
      selected = false,
    } = props;

    const TrailingIcon = trailingIcon
      ? React.cloneElement(trailingIcon as React.ReactElement, {
          className: cs("text-on-surface-container"),
        })
      : null;

    const TrailingIconRender = TrailingIcon ? (
      <div
        className={cs(
          "flex items-center transition-all duration-300 ease-in-out w-[0px]",
          selected && "w-[18px]"
        )}
      >
        {TrailingIcon}
      </div>
    ) : null;

    const IconRender = (
      <span
        className={cs(
          "flex items-center transition-all duration-300 ease-in-out w-[0px] select-none",
          selected && "w-8 px-2"
        )}
      >
        <Icon icon="done" 
          size={21}
          className={cs(
            "opacity-0 transition-all duration-400 ease-in-out",
            selected && "opacity-100 text-on-secondary-container"
          )}
        />
      </span>
    );

    return (
      <button
        ref={ref}
        className={cs(
          "items-center box-content cursor-pointer flex justify-center px-4 h-8 hover:shadow-mm-1",
          "rounded-small border-[1px] transition-all duration-300 ease-in-out",
          "text-on-surface-container bg-surface hover:bg-surface-container-low border-outline",
          selected &&
            "pl-0 pr-4 bg-secondary-container hover:bg-secondary-container text-on-secondary-container",
          className
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {IconRender}
        <span
          className={cs(
            "text-ellipsis whitespace-nowrap select-none text-label-large"
          )}
        >
          {children && children}
        </span>
        {TrailingIconRender}
      </button>
    );
  }
);

export default FilterChip;
