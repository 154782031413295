import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdateSellerCommandType = {
  id: number;
  name: string;
  email: string;
  phone: string;
  sellerCompany: string;
  active: boolean;
};

export type AddSellerCommandType = {
  name: string;
  email: string;
  phone: string;
  sellerCompany: string;
  active: boolean;
};

export const fetchSellers = async () => {
  const result = await api
    .get(`sellers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function updateSeller({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateSellerCommandType>, "id">) {
  const result = await api
    .put(`sellers/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addSeller({ ...added }: AddSellerCommandType) {
  const result = await api
    .post(`sellers`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteSeller({ id }: { id: number }) {
  const result = await api
    .delete(`sellers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
