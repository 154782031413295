import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchPlaces, updatePlace, addPlace, deletePlace } from "./places";
import { queryClient } from "../../../App";

export const placesQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["places"],
    queryFn: () => fetchPlaces(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const useUpdatePlaceMutation = (id: number) => {
  return useMutation({
    mutationKey: ["place", "update", id],
    mutationFn: updatePlace,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddPlaceMutation = () => {
  return useMutation({
    mutationKey: ["place", "add"],
    mutationFn: addPlace,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeletePlaceMutation = () => {
  return useMutation({
    mutationKey: ["place", "delete"],
    mutationFn: deletePlace,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
