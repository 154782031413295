import React from "react";

import { createFileRoute, useNavigate, Link } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { zodSearchValidator, fallback } from "@tanstack/router-zod-adapter";
import { useMediaQuery } from "usehooks-ts";
import { z } from "zod";
import debounce from "lodash/debounce";

import { campaignsQueryOptions } from "./-api/queryOptions";
import {
  Button,
  Icon,
  IconButton,
  LinkBox,
  MultipleComboBox,
  NavigationDrawer,
  Search,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import { MdClose, MdTune, MdRefresh } from "@pnpm-monorepo/core/src/ui/icons";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { SmallDateString, TextContainer } from "../../utils/renderers";
import RouterProgress from "../../components/RouterProgress";
import ErrorEmoji from "../../components/ErrorEmoji";
import { SortOrder, StatusType } from "../../utils/types";
import { CampaignsSortBy } from "./-api/campaigns";
import AddNewCampaign from "./-components/AddNewCampaign";
import DefaultPendingComponent from "../../components/PendingComponent";
import Pagination from "../../components/Pagination";
import { campignStatusesQueryOptions } from "../campaigns_/$campaignId/-api/queryOptions";

const campaignSearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  sortBy: fallback(
    z.enum(["name", "campaignDate", "statusName"]),
    "campaignDate"
  ).default("campaignDate"),
  sortOrder: fallback(z.enum(["asc", "desc"]), "desc").default("desc"),
  q: fallback(z.string(), "").default(""),
  status: fallback(z.number().array(), []).default([]),
});

export type CampaignsSearchParams = z.infer<typeof campaignSearchSchema>;

export const Route = createFileRoute("/campaigns")({
  validateSearch: zodSearchValidator(campaignSearchSchema),
  loaderDeps: ({ search }: { search: CampaignsSearchParams }) => ({
    q: search.q,
    page: search.page,
    sortBy: search.sortBy,
    sortOrder: search.sortOrder,
    status: search.status,
  }),
  loader: async (opts) => {
    const queryClient = opts.context.queryClient;
    await queryClient.cancelQueries("campaigns");
    return queryClient.ensureQueryData(campaignsQueryOptions(opts.deps));
  },
  component: CampaignsComponent,
  pendingComponent: DefaultPendingComponent,
});

function CampaignsComponent() {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const { page, sortBy, sortOrder, q, status } = Route.useSearch();

  const campaignsQuery = useSuspenseQuery(
    campaignsQueryOptions(Route.useLoaderDeps())
  );

  const campaigns = campaignsQuery.data;
  const campignStatusesQuery = useQuery(campignStatusesQueryOptions());
  const campignStatuses = campignStatusesQuery.data;

  const [searchDraft, setSearchDraft] = React.useState(q ?? "");
  const [selectedStatusDraft, setSelectedStatusDraft] = React.useState<
    StatusType[]
  >([]);

  const handleSearch = (v: string) => {
    setSearchDraft(v);
  };

  React.useEffect(() => {
    const debouncedNavigate = debounce(() => {
      navigate({
        search: (old) => ({
          ...old,
          q: searchDraft,
          page: 1,
        }),
        replace: true,
      });
    }, 300);

    debouncedNavigate();

    return () => debouncedNavigate.cancel();
  }, [searchDraft, navigate]);

  React.useEffect(() => {
    if (campignStatuses && status && status.length > 0) {
      const s = campignStatuses.filter((x: StatusType) =>
        status?.includes(x.id)
      );
      setSelectedStatusDraft(s);
    }
  }, [status, campignStatuses]);

  const setSortBy = (sortBy: CampaignsSortBy) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortBy,
        };
      },
      replace: true,
    });

  const setSortOrder = (sortOrder: SortOrder) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortOrder,
        };
      },
      replace: true,
    });

  const setPage = (page: number) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          page,
        };
      },
      replace: true,
    });

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          status: selectedStatusDraft.map((x: StatusType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedStatusDraft]);

  function handleOpenFilter(): void {
    setOpen(!open);
  }

  function handleClearFilter(): void {
    setPage(1);
    setSelectedStatusDraft([]);
  }

  function handleClearAllFilter(): void {
    setPage(1);
    setSearchDraft("");
    setSelectedStatusDraft([]);
  }

  return (
    <div>
      <NavigationDrawer
        open={open}
        variant={matches ? "standard" : "modal"}
        onClose={handleOpenFilter}
      >
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-6 py-5 block mb-16">
            <div className="flex items-center justify-end gap-1">
              <Button
                disabled={
                  selectedStatusDraft.length === 0 && searchDraft.length === 0
                }
                iconLeft={<MdRefresh size={24} />}
                onClick={handleClearAllFilter}
              >
                Återställ alla
              </Button>
              <IconButton icon={<MdClose />} onClick={handleOpenFilter} />
            </div>
            <div className="text-title-medium mt-2 py-1">Filter</div>

            <div className="my-8">
              <div className="my-4">
                {campignStatuses && (
                  <MultipleComboBox
                    items={campignStatuses}
                    selectedItems={selectedStatusDraft}
                    setSelectedItems={setSelectedStatusDraft}
                    placeholder={"Status"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </NavigationDrawer>
      <div
        id="campaigns-content"
        className={cs(
          "relative transition-all",
          open === true && matches ? "ml-80" : "ml-0"
        )}
      >
        <div className="z-10 flex min-w-[300px] flex-col text-on-surface sticky top-0 bg-surface">
          <div className=" block z-50 pb-3 pt-4 px-4">
            <Search
              id="campaigns-search"
              name="search"
              placeholder="Sök kampanj"
              clearOnEscape={true}
              onChange={handleSearch}
              defaultValue={searchDraft}
            />

            <div className="mt-4 mb-2 flex gap-2 items-center">
              <div className="flex grow gap-2 items-center">
                <Button
                  variant={open ? "filled" : "outlined"}
                  iconLeft={open ? <MdClose size={20} /> : <MdTune size={20} />}
                  onClick={handleOpenFilter}
                >
                  Filter
                </Button>

                {selectedStatusDraft.length !== 0 ? (
                  <Button onClick={handleClearFilter}>Rensa allt</Button>
                ) : (
                  <></>
                )}

                <RouterProgress />
              </div>
              <AddNewCampaign />
            </div>
          </div>
        </div>

        {campaigns?.count === 0 ? (
          <ErrorEmoji message="Hittade inga kampanjer.">
            <Button
              variant="outlined"
              className="mt-4"
              onClick={handleClearFilter}
            >
              Rena filter och försök igen
            </Button>
          </ErrorEmoji>
        ) : (
          <>
            <Pagination
              maxVisiblePages={10}
              totalItems={campaigns.count}
              itemsPerPage={25}
              currentPage={page}
              onPageChange={setPage}
            />

            <div className="flex flex-col w-full my-6 px-4 text-body-medium">
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>
                        {" "}
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("name");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Namn{" "}
                          {sortBy === "name" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>Kampanjtext</Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("campaignDate");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Kampanjdatum{" "}
                          {sortBy === "campaignDate" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>Kampanjdatum t.o.m</Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("statusName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Status{" "}
                          {sortBy === "statusName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {campaigns?.results?.map((campaign) => {
                      return (
                        <Table.Row key={campaign.id}>
                          <Table.RowCell>
                            <Link
                              to={`/campaigns/$campaignId`}
                              params={(prev) => ({
                                ...prev,
                                campaignId: campaign.id,
                              })}
                              search={true}
                            >
                              <LinkBox>{campaign.name}</LinkBox>
                            </Link>
                          </Table.RowCell>
                          <Table.RowCell>
                            <TextContainer>
                              {campaign.description}
                            </TextContainer>
                          </Table.RowCell>
                          <Table.RowCell>
                            <SmallDateString value={campaign.campaignDate} />
                          </Table.RowCell>
                          <Table.RowCell>
                            <SmallDateString
                              value={campaign.campaignDateUntil}
                            />
                          </Table.RowCell>
                          <Table.RowCell>{campaign.statusName}</Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
              <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
