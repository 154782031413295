import { Icon, IconButton, Menu2 } from "@pnpm-monorepo/core/src/ui/components";

export default function AppMenu() {
  return (
    <>
      <Menu2.DropdownMenu>
        <Menu2.DropdownMenuTrigger asChild>
          <div>
            <IconButton icon={<Icon icon="apps" />} size="standard" />
          </div>
        </Menu2.DropdownMenuTrigger>
        <Menu2.DropdownMenuContent
          align="center"
          forceMount
          className="m-2"
        >
          <Menu2.DropdownMenuItem asChild>
            <a href="https://grus-int.azurewebsites.net">GRUS</a>
          </Menu2.DropdownMenuItem>
          <Menu2.DropdownMenuItem asChild>
            <a href="https://admin2.mobilkupongen.se">Mobilkupongen</a>
          </Menu2.DropdownMenuItem>
        </Menu2.DropdownMenuContent>
      </Menu2.DropdownMenu>
    </>
  );
}
