import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../utils/api";
import { NotFoundError } from "../../../../utils/errors";
import { CampaignType } from "../../../../utils/types";


export interface SendTestMailCommandType {
  email: string;
  id: number
}

export const fetchCampaignById = async (id: number | string) => {
  const campaign = await api
    .get(`campaigns/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Kampanj med id "${id}" hittades inte!`);
      }
      throw err;
    });

  return campaign;
};

export const fetchCampaignEstimate = async (id: number | string) => {
  const response = await api
    .get(`campaigns/${id}/estimate`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchCampaignTestLogs = async (id: number | string) => {
  const response = await api
    .get(`campaigns/${id}/sendtestlogs`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchCampaignActivity = async (id: number | string) => {
  const response = await api
    .get(`campaigns/${id}/activity`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function deleteCampaign({ id }: { id: number }) {
  const response = await api
    .delete(`campaigns/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export const fetchCampaignStatuses = async () => {
  const response = await api
    .get(`/options?type=CampaignStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchEmailTemplates = async () => {
  const response = await api
    .get(`/options?type=EmailTemplate`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateCampaign({
  id,
  ...updated
}: PickAsRequired<Partial<CampaignType>, "id">) {
  const result = await api
    .put(`campaigns/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function sendCampaignMail({
  id,
  ...updated
}: PickAsRequired<Partial<SendTestMailCommandType>, "id">) {
  const result = await api
    .post(`campaigns/${id}/sendtest`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}