import { PickAsRequired } from "@tanstack/react-router";

import api from "../../../../../utils/api";
import { OfferEventType } from "../../../../../utils/types";

export const fetchOfferEvents = async ({
  id,
  page = 1,
  events,
  search
}: {
  id: number | string;
  page: number;
  events: string;
  search?: string;
}) => {
  const result = await api
    .get(`offers/${id}/events`, {
      params: { id: id, page: page, events: events, search: search },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function removeOfferEvent({
  offerId,
  ...event
}: PickAsRequired<Partial<OfferEventType>, "offerId">) {
  const result = await api
    .put(`offers/${offerId}/events`, {
      offerId,
      ...event,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
