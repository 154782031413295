import { LinkBox } from "@pnpm-monorepo/core/src/ui/components";
import React, { useState } from "react";

interface Place {
  id: string;
  name: string;
}

interface ProductPlace {
  id: string;
  place: Place;
}

interface PlacesProps {
  uniquePlaces: ProductPlace[];
}

const Places: React.FC<PlacesProps> = ({ uniquePlaces }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="text-body-medium">
      {uniquePlaces.length ? (
        <>
          {uniquePlaces
            .slice(0, showAll ? uniquePlaces.length : 10)
            .map((cpp) => (
              <div key={cpp.id}>{cpp.place?.name}</div>
            ))}
          {uniquePlaces.length > 10 && (
            <button onClick={toggleShowAll} className="mt-2">
              <LinkBox>{showAll ? "Visa mindre" : "Visa alla"}</LinkBox>
            </button>
          )}
        </>
      ) : (
        <span className="">&lt;Ej angivet&gt;</span>
      )}
    </div>
  );
};

export default Places;
