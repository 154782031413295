import React from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { CompanyType } from "../../../../utils/types";
import { Route } from "..";
import { SubmitHandler, useForm } from "react-hook-form";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import {
  useUpdateCompanyMutation,
} from "../-api/queryOptions";
import { useQuery } from "@tanstack/react-query";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import TextareaWrapper from "../../../../components/FormWrappers/TextareaWrapper";
import { chainsQueryOptions, companyStatusesQueryOptions, sellersQueryOptions } from "../../../../utils/data/common";

const Common = ({ company }: { company: CompanyType }) => {
  const [edit, setEdit] = React.useState(false);

  const params = Route.useParams();

  const { seller, chain, status } = company;

  const companyStatusesQuery = useQuery(companyStatusesQueryOptions());
  const chainsQuery = useQuery(chainsQueryOptions());
  const sellersQuery = useQuery(sellersQueryOptions());

  const mutation = useUpdateCompanyMutation(params.companyId);

  const { control, handleSubmit, reset } = useForm<CompanyType>({
    defaultValues: {
      id: company.id,
      name: company.name,
      friendlyName: company.friendlyName,
      contractSigner: company.contractSigner,
      emailStatistics: company.emailStatistics,
      description: company.description,
      organizationNumber: company.organizationNumber,
      status: status,
      seller: seller,
      chain: chain,
    },
  });

  const onSubmit: SubmitHandler<CompanyType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title="Allmänt"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Nummer
              </div>
              <div className="text-body-medium">{company.number}</div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Namn
              </div>
              <div className="text-body-medium">
                {company.name ? (
                  company.name
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Alternativt namn
              </div>
              <div className="text-body-medium">
                {company.friendlyName ? (
                  company.friendlyName
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Organisationsnummer
              </div>
              <div className="text-body-medium">
                {company.organizationNumber ? (
                  company.organizationNumber
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Säljare
              </div>
              <div className="text-body-medium">
                {seller ? (
                  seller.name
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kedja
              </div>
              <div className="text-body-medium">
                {chain ? (
                  chain.name
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Skicka erbjudandestatistik till
              </div>
              <div className="text-body-medium">
                {company.emailStatistics ? (
                  company.emailStatistics
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kontraktstecknare
              </div>
              <div className="text-body-medium">
                {company.contractSigner ? (
                  company.contractSigner
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Anteckning
              </div>
              <div className="text-body-medium">
                {company.description ? (
                  company.description
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="text-body-medium">{status?.name}</div>
            </div>
          </CardContent>
        </Card>
      )}

      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Allmänt"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
 
          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Namn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="name" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Alternativt namn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="friendlyName" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Organisationsnummer
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="organizationNumber" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Säljare
              </div>
              <div className="pt-2">
                {!sellersQuery.isPending ? (
                  <SelectWrapper
                    name="seller"
                    control={control}
                    className="min-w-32"
                    options={sellersQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                ) : null}
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kedja
              </div>
              <div className="pt-2">
                {!chainsQuery.isPending ? (
                  <SelectWrapper
                    name="chain"
                    control={control}
                    className="min-w-32"
                    options={chainsQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                ) : null}
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Skicka erbjudandestatistik till
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="emailStatistics" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kontraktstecknare
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="contractSigner" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Anteckningar
              </div>
              <div className="pt-2">
                <TextareaWrapper name="description" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="pt-2">
                {!companyStatusesQuery.isPending ? (
                  <SelectWrapper
                    name="status"
                    control={control}
                    className="min-w-32"
                    options={companyStatusesQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                ) : null}
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3 ">
          <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default Common;
