import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config";
import MyIcon from "../assets/coupon.svg?react";

const SignIn = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div
      id="authentication-signin-page"
      className="flex flex-col h-full w-fill justify-center items-center"
    >
      <div
        className="bg-surface-container-low text-on-surface flex flex-col p-6 rounded-large hover:cursor-pointer hover:bg-secondary-container transition-all group"
        onClick={handleLoginRedirect}
      >
        <div className="flex justify-center">
          <MyIcon className="dark:fill-on-surface" />
        </div>
        <h1 className="text-display-medium">Admin Mobilkupongen</h1>
        <h2 className="text-body-large">Klicka för att logga in</h2>
      </div>
    </div>
  );
};

export default SignIn;
