import { forwardRef } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import Icon from "../Icon";
import { cs } from "../../../utils";
import { Select2ItemProps, Select2Props } from "./Select2.types";

export const Select2 = forwardRef<HTMLButtonElement, Select2Props>(
  (
    { children, placeholder, onValueChange, selectedItem, value },
    forwardedRef
  ) => {
    return (
      <SelectPrimitive.Root onValueChange={onValueChange} value={value}>
        <SelectPrimitive.Trigger ref={forwardedRef} asChild>
          <div
            className={cs(
              "peer flex h-[48px] w-full items-center rounded-t-[8px]",
              "border-x-0 border-b-2 border-t-0 border-on-surface-variant bg-surface-container px-[12px]",
              "text-body-medium text-on-surface placeholder-outline-variant focus:border-primary focus:outline-none"
            )}
          >
            <div className="grow">
              <SelectPrimitive.Value placeholder={placeholder}>
                {selectedItem}
              </SelectPrimitive.Value>
            </div>

            <Icon icon="expand_more" />
          </div>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cs(
              "w-full bg-surface-container shadow-mm-2 rounded-medium",
              "z-50",
              "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1"
            )}
            position="popper"
          >
            <SelectPrimitive.ScrollUpButton className="flex items-center px-3 py-2 border-b-[1px] border-outline-variant">
              <Icon icon="expand_less" />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className="bg-surface-container h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]">
              {children}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className="items-center px-3 py-2 border-t-[1px] border-outline-variant">
              <Icon icon="expand_more" />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  }
);

export const Select2Item = forwardRef<HTMLDivElement, Select2ItemProps>(
  ({ children, className, value, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        {...props}
        value={value}
        ref={forwardedRef}
        className={cs(
          "relative flex items-center px-3 py-2 text-body-large text-on-surface focus:bg-surface-container-high data-[state=checked]:bg-surface-container-highest",
          "radix-disabled:opacity-50",
          "focus:outline-none select-none",
          className
        )}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  }
);
