import api from "../../../utils/api";
import { PlaceType, SortOrder, StatusType } from "../../../utils/types";

export type ConsumersSortBy =
  | "email"
  | "code"
  | "placeName"
  | "createdAt"
  | "statusName";

export const fetchConsumers = async ({
  q,
  page,
  sortBy,
  sortOrder,
  place,
  status,
}: {
  q?: string;
  page?: number;
  sortBy?: ConsumersSortBy;
  sortOrder?: SortOrder;
  place?: PlaceType[];
  status?: StatusType[];
} = {}) => {
  const consumers = await api
    .get(`consumers`, {
      params: {
        search: q,
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        place: place?.join(","),
        status: status?.join(","),
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return consumers;
};
