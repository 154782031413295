import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Search3,
} from "@pnpm-monorepo/core/src/ui/components";
import { useState } from "react";
import { Route } from "..";
import { useConsumerReceiverRelationMutation } from "../-api/queryOptions";
import api from "../../../../utils/api";

interface ReceiverSelection {
  id: number;
  code: string;
  name: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
}

const getItems = async (request, callback) => {
  try {
    const { data } = await api.get(`/receivers/codes?search=${request.q}`);
    return callback(data);
  } catch (e) {
    throw new Error(`API error: ${e?.message}`);
  }
};

const AddReceiver = () => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);

  const renderCustomOption = (option: ReceiverSelection) => {
    return (
      <div className="flex flex-col grow">
        <span className="text-label-large">{option.code}</span>
        <span className="text-label-small">{option.name}</span>
        <span className="text-label-small">
          {option.addressLine2}, {option.addressLine3} {option.addressLine4}
        </span>
      </div>
    );
  };

  const addMutation = useConsumerReceiverRelationMutation(params.consumerId);

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (selected: ReceiverSelection):  void => {
    if (selected) {
      addMutation.mutate(
        {
          receiverId: selected.id,
          consumerId: params.consumerId,
        },
        {
          onSuccess: () => {
            setOpen(false);
          },
          onSettled: () => {},
        }
      );
    }
  };

  return (
    <div>
      <>
        <Button
          className="md:flex hidden"
          iconLeft={<Icon icon="add" size={20} />}
          onClick={handleAdd}
        >
          Lägg till
        </Button>
        <IconButton
          variant="tonal"
          className="md:hidden flex"
          icon={<Icon icon="add" size={20} />}
          onClick={handleAdd}
        />
      </>

      <Dialog
        icon={<Icon icon={"add"} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till inflyttad?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={addMutation.isPending}
            >
              Avbryt
            </Button>
          </>
        }
      >
        <div className="my-2">
          <Search3<ReceiverSelection>
            noResultsMessage="Vi hittade inga inflyttade"
            placeholder="Ange kod"
            onSelect={handleOk}
            renderOption={renderCustomOption}
            getItems={getItems}
            getOptionLabel={(option) => option.code}
            loading={false}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AddReceiver;
