import { ReactNode, useEffect } from "react";

// eslint-disable-next-line react-refresh/only-export-components
export const ErrorEmojiCollection: string[] = [
  "(='X'=)",
  "(˚Δ˚)b",
  "\\(o_o)/",
  "(;-;)",
  "(≥o≤)",
  "(o^^)o",
  "(='X'=)",
  "(·_·)",
  "(>_<)",
  "(^_^)b",
  "\\(^Д^)/",
];

const getRandomErrorEmoji = () => {
  const randomIndex = Math.floor(Math.random() * ErrorEmojiCollection.length);
  return ErrorEmojiCollection[randomIndex];
};

let lastErrorEmoji: string | null = null;

const resetLastErrorEmoji = () => {
  lastErrorEmoji = null;
};

export default function ErrorEmoji({ message, children }: { message: string, children?: ReactNode }) {
  let randomErrorEmoji: string;
  if (lastErrorEmoji === null) {
    randomErrorEmoji = getRandomErrorEmoji();
    lastErrorEmoji = randomErrorEmoji;
  } else {
    randomErrorEmoji = lastErrorEmoji;
  }

  useEffect(() => {
    resetLastErrorEmoji();
  }, []); // This effect runs only once, like componentDidMount, to reset lastErrorEmoji when component mounts


  return (
    <div className="w-full flex justify-center items-center my-10">
      <div className="flex justify-center items-center flex-col">
        <div className="text-surface-container-highest text-[180px]">
          {randomErrorEmoji}
        </div>
        <div className="text-headline-small mt-4">{message}</div>
        {children}
      </div>
    </div>
  );
}
