import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchIndustries, addIndustry, updateIndustry, deleteIndustry } from "./industries";
import { queryClient } from "../../../App";

export const industriesQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["industries"],
    queryFn: () => fetchIndustries(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const useUpdateIndustryMutation = (id: number) => {
  return useMutation({
    mutationKey: ["industries", "update", id],
    mutationFn: updateIndustry,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddIndustryMutation = () => {
  return useMutation({
    mutationKey: ["industries", "add"],
    mutationFn: addIndustry,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteIndustryMutation = () => {
  return useMutation({
    mutationKey: ["industries", "delete"],
    mutationFn: deleteIndustry,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
