import api from "../../api";

export async function fetchOfferLogs(offerId: number | string) {
    const result = await api
      .get(`offers/${offerId}/logs`)
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  }