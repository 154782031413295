import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";


export type SchedulerCommandType = {
  id: number;
  repeatMinutes: number;
  active: boolean;
};

export const fetchNotificationScheduler = async () => {
  const response = await api
    .get(`notifications`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateSchedule({
  id,
  ...updated
}: PickAsRequired<Partial<SchedulerCommandType>, "id">) {
  const result = await api
    .put(`notifications/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
