"use client";
import * as React from "react";
import { cs } from "../../../utils";
import { ListProps } from "./List.types";

const List: React.FC<ListProps> = ({
  className,
  disabled = false,
  divider = true,
  width = "w-full",
  leadingItem,
  trailingItem,
  onClick,
  headline,
  supportingText,
  trailingSupportingText,
}) => {
  const baseClass = "";
  let variantClass = baseClass || "";

  if (disabled) {
    variantClass += " opacity-[.38]";
  }

  if (!disabled) {
    if (onClick) {
      variantClass += " cursor-pointer text-left hover:bg-surface-container";
    }
  }

const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  if (onClick) {
    onClick(event);
  }
}

  return (
    <div
      className={cs(
        "bg-surface text-on-surface flex",
        width && width,
        variantClass,
        className,
        divider && "border-b-[1px] border-outline-variant last:border-none "
      )}
      onClick={handleOnClick}
      tabIndex={0}
    >
      {leadingItem && (
        <div className="flex item-center">
          <div className="pl-4 py-2 flex items-center justify-center text-on-surface-variant">
            {leadingItem}
          </div>
        </div>
      )}
      <div className="flex item-center grow">
        <div className="px-4 py-2 flex grow flex-col justify-center item-center overflow-hidden">
          {headline && (
            <div className="text-body-large overflow-hidden text-ellipsis">
              {headline}
            </div>
          )}
          {supportingText && (
            <div className="text-body-medium text-on-surface-variant">
              {supportingText}
            </div>
          )}
        </div>
      </div>
      {trailingSupportingText && (
        <div className="pr-4 py-2 flex items-center justify-center">
          <div className="text-label-small text-on-surface-variant">
            {trailingSupportingText}
          </div>
        </div>
      )}
      {trailingItem && (
        <div className="pr-4 py-2 flex items-center justify-center text-on-surface-variant">
          {trailingItem}
        </div>
      )}
    </div>
  );
};

export default List;
