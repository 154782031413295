import { Link, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { Button } from "@pnpm-monorepo/core/src/ui/components";

import { ErrorComponent } from "../../../../../components/ErrorComponent";
import DefaultPendingComponent from "../../../../../components/PendingComponent";
import { storeQueryOptions } from "./-api/queryOptions";
import OpeningHour from "./-components/OpeningHour";
import Common from "./-components/Common";
import Address from "./-components/Address";
import { MdClose } from "@pnpm-monorepo/core/src/ui/icons";
import StoreImage from "./-components/StoreImage";
import LogDialog from "./-components/LogDialog";

export const Route = createFileRoute("/companies/$companyId/stores/$storeId")({
  loader: ({ context: { queryClient }, params: { storeId } }) => {
    queryClient.ensureQueryData(storeQueryOptions(storeId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyContractsComponent,
});


function CompanyContractsComponent() {
  const params = Route.useParams();
  const storeQuery = useSuspenseQuery(storeQueryOptions(params.storeId));

  const store = storeQuery.data;

  const { address } = store;

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex mb-6 justify-start gap-2">
        <Link
          to={"/companies/$companyId/stores"}
          params={{ companyId: params.companyId }}
          search={true}
          className="flex-1 flex items-center"
        >
          <Button variant="tonal" iconLeft={<MdClose />}>
            Stäng
          </Button>
        </Link>
        <div className="flex items-center justify-end">
          <LogDialog />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="lg:w-[560px] flex flex-col gap-4">
          <Common store={store} />
          <OpeningHour {...store} />
          <StoreImage store={store} />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <Address address={address} />
        </div>
      </div>
    </div>
  );
}
