import { useSuspenseQuery } from "@tanstack/react-query";
import { ErrorComponent, createFileRoute } from "@tanstack/react-router";

import {
  consumerQueryOptions,
  consumerStatusesQueryOptions,
} from "./-api/queryOptions";
import DefaultPendingComponent from "../../../components/PendingComponent";

import Receivers from "./-components/Receivers";
import Account from "./-components/Account";

export const Route = createFileRoute("/consumers/$consumerId/")({
  component: ConsumerIndexComponent,
  loader: ({ context: { queryClient }, params: { consumerId } }) => {
    queryClient.ensureQueryData(consumerQueryOptions(consumerId));
    queryClient.ensureQueryData(consumerStatusesQueryOptions());
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function ConsumerIndexComponent() {
  const params = Route.useParams();
  const consumerQuery = useSuspenseQuery(
    consumerQueryOptions(params.consumerId)
  );

  const consumer = consumerQuery.data;

  return (
    <div className="flex w-full flex-col lg:flex-row gap-4">
      <div className="flex w-full flex-col lg:flex-row ">
        <div className="flex flex-col gap-4 w-full">
          <Account consumer={consumer} />
          <Receivers /> 
        </div>
      </div>
    </div>
  );
}
