import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";
import { useNavigate } from "@tanstack/react-router";
import { Route } from "../route";
import { useState } from "react";
import { MdDelete } from "@pnpm-monorepo/core/src/ui/icons";
import { useDeleteCampaignBasisMutation } from "../../../../../../utils/data/campaignbasis";

const DeleteCampaign = () => {
  const params = Route.useParams();
  const navigate = useNavigate({ from: Route.fullPath });

  const [open, setOpen] = useState<boolean>(false);

  function handleRemove(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  const mutation = useDeleteCampaignBasisMutation(parseInt(params.campaignId));

  const handleOk = (): void => {
    mutation.mutate(
      {
        id: Number(params.campaignId),
      },
      {
        onSuccess: () => {
          navigate({
            to: `/companies/${params.companyId}/campaigns`,
            search: (old) => ({
              ...old,
            }),
          });
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Button
        iconLeft={<MdDelete size={20} />}
        variant="filled"
        className="bg-error text-on-error"
        onClick={handleRemove}
      >
        Ta bort
      </Button>
      <Dialog
        icon={<Icon icon="delete" />}
        open={open}
        onClose={handleClose}
        headline="Ta bort kampanjunderlag?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={mutation.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default DeleteCampaign;
