import { queryOptions } from "@tanstack/react-query";
import { fetchConsumerOffers } from "./offers";

export const consumerOffersQueryOptions = (
  consumerId: number | string,
) =>
  queryOptions({
    queryKey: ["consumers", "offers", consumerId],
    queryFn: () => fetchConsumerOffers(consumerId),
  });
