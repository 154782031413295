import { Icon } from "@pnpm-monorepo/core/src/ui/components";
import React from "react";
import { SmallDateTimeString } from "../utils/renderers";
import { LogEntry, DataEntry } from "../utils/types";

const Logs = ({
  data,
}: {

    data: LogEntry[];
}) => {

  return (
    <>
      {data
        .filter((log: LogEntry) =>
          log.action === "redigerade" || log.action === "skapade"
            ? log.data?.some(
                (data: DataEntry) => data.current !== data.previous
              )
            : true
        )
        .map((log: LogEntry, idx: number) => (
          <div
            key={idx}
            className="bg-surface-container text-on-surface rounded-small mb-4 even:bg-surface-container-lowest even:text-on-surface"
          >
            <div className="flex gap-2 p-3 text-label-medium">
              <div className="">
                <SmallDateTimeString value={log.when} />
              </div>
              <div> - </div>
              <div className="text-label-small">
                {log.who ?? "?"} {log.action} {log.section}
              </div>
            </div>
            {log.action === "redigerade" || log.action === "skapade" ? (
              <div className="p-3 overflow-hidden">
                {log.data?.map((data: DataEntry, dataIdx) =>
                  data.current !== data.previous ? (
                    <div key={dataIdx}>
                      <small className="text-label-small">{data.field}</small>
                      <div className="flex items-center min-h-[24px] overflow-x-auto">
                        <div className="inline-flex items-center">
                          {data.isImage ? (
                            <img src={data.previous} width="150" />
                          ) : (
                            <div className="inline-flex text-body-medium">
                              {data.previous}
                            </div>
                          )}

                          <div className="px-2">
                            <Icon
                              icon="arrow_forward"
                              className="text-on-secondary-container"
                            />
                          </div>
                          {data.isImage ? (
                            <img src={data.current} width="150" />
                          ) : (
                            <div className="inline-flex text-body-medium">
                              {data.current}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment key={dataIdx} />
                  )
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
    </>
  );
};

export default Logs;
