import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import React from "react";
import {
  LinkBox,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import RouterProgress from "../../../../components/RouterProgress";
import {
  NoWrap,
  SmallDateTimeString,
  TextContainer,
} from "../../../../utils/renderers";
import { requestsQueryOptions } from "../../../../utils/data/request";
import { requestStatusQueryOptions } from "../../../../utils/data/common";
import RequestStatusDropdown from "./-components/RequestStatusDropdown";

export const Route = createFileRoute("/adminland/support/mobilkupongen")({
  component: SupportComponent,
});

function SupportComponent() {
  const [searchDraft, setSearchDraft] = React.useState("");
  const { data, isPending } = useQuery(
    requestsQueryOptions(searchDraft, ["ticketNumber", "details.name", "details.welcomeCode", "details.email"])
  );

  const {data: options } = useQuery(requestStatusQueryOptions())

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setSearchDraft(v);
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="py-4 flex flex-col gap-4 grow">
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center">
              <div className="flex-1 flex items-center gap-2 lg:flex-row flex-col">
                <div className="flex-1 text-headline-large flex items-center gap-2">
                  <Link to="/adminland">
                    <LinkBox>Adminland</LinkBox>
                  </Link>{" "}
                  / Support <RouterProgress />
                </div>{" "}
                <input
                  type="text"
                  className="p-[4px] bg-surface-container-low rounded-extra-large pl-6 pr-16 text-headline-small placeholder:text-on-surface-variant"
                  placeholder="Sök..."
                  onChange={handleSearch}
                  value={searchDraft}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPending ? (
        <DefaultPendingComponent />
      ) : (
        <>
          <div className="flex flex-col w-full my-6 text-body-medium">
            {data.length ? (
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>Ärende</Table.HeadCell>
                      <Table.HeadCell>Namn</Table.HeadCell>
                      <Table.HeadCell>E-post</Table.HeadCell>
                      <Table.HeadCell>Välkomstkod</Table.HeadCell>
                      <Table.HeadCell>Inflyttningsdatum</Table.HeadCell>
                      <Table.HeadCell>Meddelande</Table.HeadCell>
                      <Table.HeadCell>Status</Table.HeadCell>
                      <Table.HeadCell>Skapad</Table.HeadCell>
                      <Table.HeadCell>Uppdaterad</Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {data?.map((obj) => {
                      return (
                        <Table.Row key={obj.id}>
                          <Table.RowCell>{obj.ticketNumber}</Table.RowCell>
                          <Table.RowCell>{obj.details?.name}</Table.RowCell>
                          <Table.RowCell>{obj.details?.email}</Table.RowCell>
                          <Table.RowCell>
                            {obj.details.welcomeCode}
                          </Table.RowCell>
                          <Table.RowCell>{obj.details.date}</Table.RowCell>
                          <Table.RowCell>
                            <TextContainer>
                              {obj.details?.message}
                            </TextContainer>
                          </Table.RowCell>
                          <Table.RowCell
                            style={{ background: obj.status.color }}
                          >
                            <RequestStatusDropdown row={obj} options={options} />
                          </Table.RowCell>
                          <Table.RowCell>
                            <NoWrap>
                              <SmallDateTimeString value={obj.createdAt} />
                            </NoWrap>
                          </Table.RowCell>
                          <Table.RowCell>
                            <NoWrap>
                              <SmallDateTimeString value={obj.updatedAt} />
                            </NoWrap>
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
            ) : (
              <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
                Hittade inte några ärenden.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
