import { useState } from "react";
import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";
import { Route } from "../route";
import { useQuery } from "@tanstack/react-query";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import Logs from "../../../../components/Logs";
import { companyLogsQueryOptions } from "../../../../utils/data/company";


const LogDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const params = Route.useParams();

  const companyLogsQuery = useQuery(companyLogsQueryOptions(params.companyId));

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        iconLeft={<Icon icon="history" />}
        variant="text"
        onClick={handleOpen}
      >
        Företagslogg
      </Button>
      <Dialog
        icon={<Icon icon="history" />}
        open={open}
        onClose={handleClose}
        headline="Företagslogg"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Stäng
            </Button>
          </>
        }
      >
        <div>
          {companyLogsQuery.isPending ? (
            <DefaultPendingComponent />
          ) : (
            <Logs data={companyLogsQuery.data} />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LogDialog;
