import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { consumerOffersQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { LinkBox, Table } from "@pnpm-monorepo/core/src/ui/components";
import { Image, FallbackImage, NoWrap } from "../../../../utils/renderers";
import { Suspense } from "react";

export const Route = createFileRoute("/consumers/$consumerId/offers")({
  component: ConsumerOffersComponent,
});

function Result({ offers }: { offers: unknown[] }) {
  return offers.length ? (
    <>
      <div className="m-3 mt-0">
        <div className="text-label-medium pl-2">
          {offers.length.toLocaleString("sv")} aktuella erbjudanden.
        </div>
      </div>

      <Table.Wrapper>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Nummer</Table.HeadCell>
              <Table.HeadCell>Produkttyp</Table.HeadCell>
              <Table.HeadCell>Bild</Table.HeadCell>
              <Table.HeadCell>Företag</Table.HeadCell>
              <Table.HeadCell>Erbjudandetext</Table.HeadCell>
              <Table.HeadCell>Dagar</Table.HeadCell>
              <Table.HeadCell>Gäller t.o.m.</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {offers.map((offer, index: number) => {
              return (
                <Table.Row key={index}>
                  <Table.RowCell>
                    <Link
                      to={`/offers/$offerId`}
                      params={(prev) => ({
                        ...prev,
                        offerId: offer.id,
                      })}
                      search={true}
                    >
                      <LinkBox>{offer.number}</LinkBox>
                    </Link>
                  </Table.RowCell>
                  <Table.RowCell>
                    <NoWrap>{offer.groupTitle}</NoWrap>
                  </Table.RowCell>
                  <Table.RowCell>
                    <Suspense
                      fallback={
                        <FallbackImage className="w-56 min-w-36 rounded-large" />
                      }
                    >
                      <Image
                        url={offer.imageUrl}
                        className="w-56 min-w-36 rounded-large"
                      />
                    </Suspense>
                  </Table.RowCell>
                  <Table.RowCell>{offer.companyName}</Table.RowCell>
                  <Table.RowCell>
                    {offer.worthPrepositionServiceText}
                  </Table.RowCell>
                  <Table.RowCell>
                    <NoWrap>{offer.timeLeftText}</NoWrap>
                  </Table.RowCell>
                  <Table.RowCell>
                    <NoWrap>{offer.validToText}</NoWrap>
                  </Table.RowCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </Table.Wrapper>
    </>
  ) : (
    <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
      Det finns inga erbjudanden.
    </div>
  );
}

function ConsumerOffersComponent() {
  const params = Route.useParams();

  const { data, isPending } = useQuery(
    consumerOffersQueryOptions(params.consumerId)
  );

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <div className="flex flex-col grow">
        <div className="flex flex-col w-full text-body-medium">
          {isPending ? (
            <DefaultPendingComponent />
          ) : (
            <>
              <Result offers={data} />
            </>
          )}
          <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
        </div>
      </div>
    </div>
  );
}
