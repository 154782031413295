"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { CardContentProps } from "./CardContent.types";

const CardContent: React.FC<CardContentProps> = (props) => {
  const { className, children } = props;

  return <div className={cs("p-3", className)}>{children}</div>;
};

export default CardContent;
