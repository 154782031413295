import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../../../utils/api";
import { AddressType, OpeningHourType, StoreType } from "../../../../../../utils/types";

export const fetchStoreById = async (storeId: number | string) => {
  const response = await api
    .get(`stores/${storeId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateAddress({
  id,
  ...updated
}: PickAsRequired<Partial<AddressType>, "id">) {
  const result = await api
    .put(`addresses/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateStore({
  id,
  ...updated
}: PickAsRequired<Partial<StoreType>, "id">) {
  const result = await api
    .put(`stores/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateStoreOpeningHours({
  storeId,
  ...updated
}: PickAsRequired<Partial<OpeningHourType>, "storeId">) {
  const result = await api
    .post(`stores/${storeId}/openinghour`, {
      storeId,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteStoreImage({
  storeId,
  imageId,
}: {
  storeId: number;
  imageId: number;
}) {
  const response = await api
    .delete(`stores/${storeId}/images/${imageId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function addStoreImage({
  storeId,
  formData,
}: {
  storeId: number;
  formData: FormData;
}) {
  const response = await api
    .post(`stores/${storeId}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}