import { queryOptions, useMutation } from "@tanstack/react-query";
import { createCampaign, fetchCampaignBasis, updateCampaignBasis, deleteCampaignBasis, createCampaignBasis } from "./campaignbasis";
import { queryClient } from "../../../App";

export const campaignBasisQueryOptions = (campaignId: number | string) =>
  queryOptions({
    queryKey: ["campaignbasis", campaignId],
    queryFn: () => fetchCampaignBasis(campaignId),
  });

export const useUpdateCampaignbasisMutation = (campaignId: number | string) => {
  return useMutation({
    mutationKey: ["campaignbasis", campaignId],
    mutationFn: updateCampaignBasis,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useCreateCampaignbasisMutation = () => {
  return useMutation({
    mutationKey: ["campaignbasis"],
    mutationFn: createCampaignBasis,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useCreateCampaignMutation = (campaignId: number | string) => {
  return useMutation({
    mutationKey: ["campaignbasis", campaignId],
    mutationFn: createCampaign,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteCampaignBasisMutation = (campaignId: number | string) => {
  return useMutation({
    mutationKey: ["campaignbasis", campaignId],
    mutationFn: deleteCampaignBasis,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};