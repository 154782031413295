import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardHeader,
} from "@pnpm-monorepo/core/src/ui/components";
import { SmallDateTimeString } from "../../../../../../utils/renderers";

// api

const getWorkOrder = async (id: number | string) => {
  const { data } = await axios.get(import.meta.env.VITE_GRUS_API_URL + id, {
    headers: { ApiKey: import.meta.env.VITE_GRUS_API_KEY },
  });

  return data;
};

// queries

export const useQueryGetWorkOrder = (id: number | string) => {
  return useQuery({
    queryKey: ["workorder", id],
    queryFn: () => getWorkOrder(id),
  });
};

// mutations

export const useMutationCreateWorkorder = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (command) =>
      axios.post(import.meta.env.VITE_GRUS_API_URL, command, {
        headers: { ApiKey: import.meta.env.VITE_GRUS_API_KEY },
      }),
    onSuccess: (response) => {
      queryClient.setQueryData(["workorder", id.toString()], response.data);
    },
  });
};

export const useMutationUpdateWorkorder = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (command) =>
      axios.put(import.meta.env.VITE_GRUS_API_URL, command, {
        headers: { ApiKey: import.meta.env.VITE_GRUS_API_KEY },
      }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["workorder", id.toString()] }),
  });
};

export default function ExternalGRUS({ id }: { id: number | string }) {
  const { data, isPending } = useQueryGetWorkOrder(id);

  return (
    <>
      {!isPending && data?.contract ? (
        <Card>
          <CardHeader title="Arbetsorder" />
          <CardContent>
          <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Skapad
              </div>
              <div className="text-body-medium">
                <SmallDateTimeString
                  value={data.contract?.createdAt}
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="text-body-medium">
                {data.contract?.workOrder?.status} (
                <SmallDateTimeString
                  value={data.contract?.workOrder?.statusDate}
                />
                )
              </div>
            </div>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}
