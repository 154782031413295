import api from "../../api";

export const fetchCompanyContracts = async (companyId: number | string) => {
  const result = await api
    .get(`companies/${companyId}/contracts`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function fetchCompanyLogs(companyId: number | string) {
  const result = await api
    .get(`companies/${companyId}/logs`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
