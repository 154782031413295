import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchOfferById,
  fetchOfferActions,
  fetchOfferWorths,
  fetchOfferPrepositions,
  fetchOfferServiceTexts,
  fetchOfferProducts,
  fetchOfferPlaces,
  updateOffer,
  updateOfferTerms,
  fetchCompanyStores,
  fetchCompanyIndustries,
  updateOfferProductPlaces,
  deleteOfferImage,
  addOfferImage,
  updateOfferStatus,
  deleteOffer,
  copyOffer,
} from "./offer";
import { queryClient } from "../../../../App";

export const companyStoresQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", "stores", companyId],
    queryFn: () => fetchCompanyStores(companyId),
  });

export const companyIndustriesQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", "industries", companyId],
    queryFn: () => fetchCompanyIndustries(companyId),
  });

export const offerQueryOptions = (offerId: number | string) =>
  queryOptions({
    queryKey: ["offers", offerId],
    queryFn: () => fetchOfferById(offerId),
  });

export const offerProductsQueryOptions = (offerId: number | string) =>
  queryOptions({
    queryKey: ["offers", "products", offerId],
    queryFn: () => fetchOfferProducts(offerId),
  });

export const offerPlacesQueryOptions = (offerId: number | string) =>
  queryOptions({
    queryKey: ["offers", "places", offerId],
    queryFn: () => fetchOfferPlaces(offerId),
  });

export const offerActionTextsQueryOptions = () =>
  queryOptions({
    queryKey: ["offeractiontexts"],
    queryFn: () => fetchOfferActions(),
  });

export const offerWorthsQueryOptions = () =>
  queryOptions({
    queryKey: ["offerworths"],
    queryFn: () => fetchOfferWorths(),
  });

export const offerPrepositionsQueryOptions = () =>
  queryOptions({
    queryKey: ["offerprepositions"],
    queryFn: () => fetchOfferPrepositions(),
  });

export const offerServiceTextsQueryOptions = (
  companyId: number | string | undefined
) =>
  queryOptions({
    queryKey: ["servicetexts"],
    queryFn: () => fetchOfferServiceTexts(companyId),
  });

export const useUpdateOfferStatusMutation = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "status", "update", offerId],
    mutationFn: updateOfferStatus,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useUpdateOfferProductPlacesMutation = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "products", "update", offerId],
    mutationFn: updateOfferProductPlaces,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useUpdateOfferMutation = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "update", offerId],
    mutationFn: updateOffer,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useUpdateOfferTermsMutation = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "terms", "update", offerId],
    mutationFn: updateOfferTerms,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useAddOfferImage = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "images", offerId],
    mutationFn: addOfferImage,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useDeleteOfferImage = (offerId: number, imageId: number) => {
  return useMutation({
    mutationKey: ["offers", "images", imageId, offerId],
    mutationFn: deleteOfferImage,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useDeleteOffer = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", offerId],
    mutationFn: deleteOffer,
    // onSuccess: () => queryClient.invalidateQueries({ queryKey: ["offers"] }),
  });
};

export const useCopyOffer = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", offerId, "copy"],
    mutationFn: copyOffer,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["offers"] }),
  });
};
