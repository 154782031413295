import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useCreateStoreMutation } from "../-api/queryOptions";
import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";
import { AddNewStoreCommandType } from "../-api/stores";

import { placesQueryOptions } from "../../../../../utils/data/common";
import SelectWrapper from "../../../../../components/FormWrappers/SelectWrapper";
import TextFieldWrapper from "../../../../../components/FormWrappers/TextFieldWrapper";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  place: z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .nullable()
  .refine((val) => val !== null, { message: "Område är obligatoriskt" }),
});

const AddNewModal = ({ companyId }: { companyId: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate({ from: `/companies/${companyId}/stores` });
  const mutation = useCreateStoreMutation(companyId);

  const placesQuery = useQuery(placesQueryOptions());

  const { control, handleSubmit } = useForm<AddNewStoreCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      place: undefined
    },
  });

  function handleAddOffer(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  const onSubmit: SubmitHandler<AddNewStoreCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        companyId
      },
      {
        onSuccess: (data) => {

          console.log(data);
          navigate({
            to: "/companies/$companyId/stores/$storeId",
            search: (old) => ({
              ...old,
            }),
            params: { companyId: companyId, storeId: data },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <>
      <Button
        iconLeft={<MdAdd size={20} />}
        variant="filled"
        onClick={handleAddOffer}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<MdAdd />}
        open={open}
        onClose={handleClose}
        headline="Lägg till ny butik?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
          <div className="my-2">
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>

          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Område
            </div>
            <div className="pt-2">
              {!placesQuery.isPending ? (
                <SelectWrapper
                  name="place"
                  control={control}
                  className="min-w-32"
                  options={placesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewModal;
