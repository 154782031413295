import React, { useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useQuery } from "@tanstack/react-query";

import {
  IconButton,
  Icon,
  List,
  Dialog,
  Button,
} from "@pnpm-monorepo/core/src/ui/components";

import { SmallDateString } from "../../../../../../utils/renderers";
import { ContractProductPlaceType } from "../../../../../../utils/types";

import { contractStatusesQueryOptions, placesQueryOptions, productsQueryOptions } from "../../../../../../utils/data/common/queryOptions";
import { isSwedishDecimal } from "../../../../../../utils/validation";
import SelectWrapper from "../../../../../../components/FormWrappers/SelectWrapper";
import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";
import { EditContractProductPlaceType } from "../../../../../../utils/data/contract/types";
import { useEditContractProductPlaceMutation } from "../../../../../../utils/data/contract";
import { Route } from "../route";

type ProductPlaceProps = {
  data: ContractProductPlaceType;
};

const ProductPlaceList: React.FC<ProductPlaceProps> = ({ data }) => {


  return (
    <div className="w-full bg-inverse-on-surface rounded-large">
      {data?.map((cpp, index) => {
        return (
          <List
            key={index}
            headline={cpp.product.name}
            supportingText={
              <>
                {cpp.place.name} / {cpp.status.name} /{" "}
                <SmallDateString value={cpp.validFrom} /> -<span className="inline-block whitespace-nowrap">{" "}
                {cpp.validTo ? (
                  <SmallDateString value={cpp.validTo} />
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}</span>
              </>
            }
            trailingItem={
              <EditProduct product={cpp} />
            }
          />
        );
      })}
    </div>
  );
};

const schema = z.object({
  price: z
    .string()
    .refine((value) => isSwedishDecimal(value), {
      message:
        "Priset måste vara ett giltigt decimaltal i svenskt format (t.ex. 123,45)",
    })
    .nullable(),
  place: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Område är obligatoriskt" }),
  product: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Produkt är obligatoriskt" }),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  validFrom: z.string().min(1, { message: "Giltigt från är obligatoriskt" }),
  validTo: z.string().nullable(),
});

const EditProduct = ({ product }: { product: ContractProductPlaceType }) => {
  const [open, setOpen] = useState<boolean>(false);

  const params = Route.useParams(); 

  const placesQuery = useQuery(placesQueryOptions());
  const productsQuery = useQuery(productsQueryOptions());
  const contractStatusesQuery = useQuery(contractStatusesQueryOptions());

  const mutation = useEditContractProductPlaceMutation(params.contractId);

  const { control, handleSubmit, reset } = useForm<EditContractProductPlaceType>(
    {
      resolver: zodResolver(schema),
      defaultValues: {
        price: product.price,
        validFrom: dayjs(product.validFrom).format("YYYY-MM-DD"),
        validTo: dayjs(product.validTo).format("YYYY-MM-DD"),
        status: product.status,
        place: product.place,
        product: product.product,
      },
    }
  );

  const handleClose = (): void => {
    setOpen(false);
    reset();
    mutation.reset();
  };

  const onSubmit: SubmitHandler<EditContractProductPlaceType> = async (data) => {

    return mutation.mutate(
      {
        ...data,
        id: product.id,
        contractId: params.contractId,
        validTo: dayjs(data.validTo).isValid() ? data.validTo :  undefined
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <IconButton icon={<Icon icon="edit" size={20} />}  onClick={() => setOpen(true)}/>
      <Dialog
        icon={<Icon icon="edit" />}
        open={open}
        onClose={handleClose}
        headline="Redigera produkt?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Område
            </div>
            <div className="pt-2">
              {!placesQuery.isPending && (
                <SelectWrapper
                  name="place"
                  control={control}
                  className="min-w-32"
                  options={placesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Produkt
            </div>
            <div className="pt-2">
              {!productsQuery.isPending && (
                <SelectWrapper
                  name="product"
                  control={control}
                  className="min-w-32"
                  options={productsQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Pris
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="price" control={control} />
            </div>
          </div>
          <div className="mb-6 flex flex-col">
            <div className="text-label-medium text-on-surface-variant">
              Giltigt från → till
            </div>
            <div className="pt-2 flex gap-2 items-baseline w-full">
              <TextFieldWrapper
                name="validFrom"
                control={control}
                variant="date"
                className="w-full"
              />
              <span className="">
                <span> → </span>
              </span>
              <TextFieldWrapper
                name="validTo"
                control={control}
                variant="date"
                className="w-full"
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Status
            </div>
            <div className="pt-2">
              {!contractStatusesQuery.isPending && (
                <SelectWrapper
                  name="status"
                  control={control}
                  className="min-w-32"
                  options={contractStatusesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProductPlaceList;