import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";

import { Route } from "../route";
import TextFieldWrapper from "../../../components/FormWrappers/TextFieldWrapper";

import SelectWrapper from "../../../components/FormWrappers/SelectWrapper";

import { AddNewCompanyCommandType } from "../-api/companies";
import {
  chainsQueryOptions,
  companyStatusesQueryOptions,
  sellersQueryOptions,
} from "../../../utils/data/common";
import { useCreateCompanyMutation } from "../-api/queryOptions";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  friendlyName: z.string(),
  organizationNumber: z.string(),
  description: z.string(),
  emailStatistics: z.string(),
  contractSigner: z.string(),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  chain: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  seller: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
});

const AddNewCompany = () => {
  const navigate = useNavigate({ from: Route.fullPath });
  const [open, setOpen] = useState<boolean>(false);

  const chainsQuery = useQuery(chainsQueryOptions());
  const sellersQuery = useQuery(sellersQueryOptions());
  const companyStatusesQuery = useQuery(companyStatusesQueryOptions());

  const mutation = useCreateCompanyMutation();

  const { control, handleSubmit, reset } = useForm<AddNewCompanyCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      friendlyName: "",
      organizationNumber: "",
      description: "",
      emailStatistics: "",
      contractSigner: "",
      chain: undefined,
      seller: undefined,
      status: undefined,
    },
  });

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<AddNewCompanyCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/companies/$companyId",
            search: (old) => ({
              ...old,
            }),
            params: { companyId: data },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button
          variant="filled"
          iconLeft={<Icon icon="add" size={20} />}
          onClick={handleAdd}
        >
          Lägg till
        </Button>
      </>

      <Dialog
        icon={<Icon icon={"business_center"} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till företag"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
        {mutation.error && (
            <div className="flex flex-col text-body-medium break-words my-2 mb-6 p-3 bg-error-container text-on-error-container">
              {Object.entries(mutation.error.response.data.errors).map(
                ([key, obj]) => (
                  <div key={key}>{obj[0]}</div>
                )
              )}
            </div>
          )}
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Alternativt namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="friendlyName" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Organisationsnummer
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="organizationNumber" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Säljare
            </div>
            <div className="pt-2">
              {!sellersQuery.isPending ? (
                <SelectWrapper
                  name="seller"
                  control={control}
                  className="min-w-32"
                  options={sellersQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Kedja
            </div>
            <div className="pt-2">
              {!chainsQuery.isPending ? (
                <SelectWrapper
                  name="chain"
                  control={control}
                  className="min-w-32"
                  options={chainsQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Skicka erbjudandestatistik till
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="emailStatistics" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Kontraktstecknare
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="contractSigner" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Anteckning
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="description" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Status
            </div>
            <div className="pt-2">
              {!companyStatusesQuery.isPending ? (
                <SelectWrapper
                  name="status"
                  control={control}
                  className="min-w-32"
                  options={companyStatusesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewCompany;
