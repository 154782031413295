import { useState } from "react";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import {
  Button,
  Dialog,
  Icon,
  LinkBox,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";

import { ErrorComponent } from "../../../../components/ErrorComponent";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { SmallDateString } from "../../../../utils/renderers";

import {
  contractStatusesQueryOptions,
  industriesQueryOptions,
  sellersQueryOptions,
} from "../../../../utils/data/common";

import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { useAddContractMutation } from "../../../../utils/data/contract";
import { AddContractType } from "../../../../utils/data/contract/types";
import { companyContractsQueryOptions } from "../../../../utils/data/company/queryOptions";

export const Route = createFileRoute("/companies/$companyId/contracts")({
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyContractsQueryOptions(companyId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyContractsComponent,
});

function CompanyContractsComponent() {
  const params = Route.useParams();
  const companyContractsQuery = useSuspenseQuery(
    companyContractsQueryOptions(params.companyId)
  );
  const companyContracts = companyContractsQuery.data;

  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-end">
        <AddContract />
      </div>
      <div className="flex flex-col w-full my-6 text-body-medium">
        {companyContracts.length ? (
          <Table.Wrapper>
            <Table.Root>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Nummer</Table.HeadCell>
                  <Table.HeadCell>Säljare</Table.HeadCell>
                  <Table.HeadCell>Kontraktsdatum</Table.HeadCell>
                  <Table.HeadCell>Giltig från</Table.HeadCell>
                  <Table.HeadCell>Giltig till</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                  <Table.HeadCell>Uppdaterad</Table.HeadCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {companyContracts?.map((contract) => {
                  return (
                    <Table.Row key={contract.id}>
                      <Table.RowCell>
                        <Link
                          to={`/companies/$companyId/contracts/$contractId`}
                          params={(prev) => ({
                            ...prev,
                            companyId: params.companyId,
                            contractId: contract.id,
                          })}
                          search={true}
                        >
                          <LinkBox>{contract.number}</LinkBox>
                        </Link>
                      </Table.RowCell>
                      <Table.RowCell>{contract.sellerName}</Table.RowCell>
                      <Table.RowCell>
                        <SmallDateString value={contract.contractDate} />
                      </Table.RowCell>
                      <Table.RowCell>
                        <SmallDateString value={contract.validFrom} />
                      </Table.RowCell>
                      <Table.RowCell>
                        <SmallDateString value={contract.validTo} />
                      </Table.RowCell>
                      <Table.RowCell>{contract.statusName}</Table.RowCell>
                      <Table.RowCell>
                        <SmallDateString value={contract.updatedAt} />
                      </Table.RowCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </Table.Wrapper>
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large">
            Det finns inga kontrakt.
          </div>
        )}
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
      </div>
    </div>
  );
}

const schema = z.object({
  industry: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Kategori är obligatoriskt" }),
  seller: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Säljare är obligatoriskt" }),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  number: z.string().min(1, { message: "Nummer är obligatoriskt" }),
  validFrom: z.string().min(1, { message: "Giltigt från är obligatoriskt" }),
  validTo: z.string().nullable(),
  companySigner: z.string().nullable(),
  digitallyCampaignOther: z.string().nullable(),
  contractDate: z
    .string()
    .min(1, { message: "Kontraktsdatum är obligatoriskt" }),
});

const AddContract = () => {
  const [open, setOpen] = useState<boolean>(false);

  const params = Route.useParams();

  const sellersQuery = useQuery(sellersQueryOptions());
  const industriesQuery = useQuery(industriesQueryOptions());
  const contractStatusesQuery = useQuery(contractStatusesQueryOptions());

  const mutation = useAddContractMutation(params.companyId);

  const { control, handleSubmit, reset } = useForm<AddContractType>({
    resolver: zodResolver(schema),
    defaultValues: {
      number: "",
      companySigner: "",
      digitallyCampaignOther: "",
      seller: null,
      industry: null,
      status: null,
      contractDate: dayjs().format("YYYY-MM-DD"),
      validFrom: dayjs().format("YYYY-MM-DD"),
      validTo: "",
    },
  });

  const handleClose = (): void => {
    setOpen(false);
    reset();
    mutation.reset();
  };

  const onSubmit: SubmitHandler<AddContractType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        companyId: params.companyId,
        validTo: dayjs(data.validTo).isValid() ? data.validTo : undefined,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="filled"
        onClick={() => setOpen(true)}
        iconLeft={<Icon icon="add" size={20} />}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<Icon icon="add" />}
        open={open}
        onClose={handleClose}
        headline="Lägg till kontrakt?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Nummer
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="number" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Kategori
            </div>
            <div className="pt-2">
              {!industriesQuery.isPending && (
                <SelectWrapper
                  name="industry"
                  control={control}
                  className="min-w-32"
                  options={industriesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Säljare
            </div>
            <div className="pt-2">
              {!sellersQuery.isPending && (
                <SelectWrapper
                  name="seller"
                  control={control}
                  className="min-w-32"
                  options={sellersQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Kontraktsdatum
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="contractDate"
                control={control}
                variant="date"
              />
            </div>
          </div>
          <div className="mb-6 flex flex-col">
            <div className="text-label-medium text-on-surface-variant">
              Giltigt från → till
            </div>
            <div className="pt-2 flex gap-2 items-baseline w-full">
              <TextFieldWrapper
                name="validFrom"
                control={control}
                variant="date"
                className="w-full"
              />
              <span className="">
                <span> → </span>
              </span>
              <TextFieldWrapper
                name="validTo"
                control={control}
                variant="date"
                className="w-full"
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Signerad av
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="companySigner" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Kampanjerbjudande avseende
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="digitallyCampaignOther"
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Status
            </div>
            <div className="pt-2">
              {!contractStatusesQuery.isPending && (
                <SelectWrapper
                  name="status"
                  control={control}
                  className="min-w-32"
                  options={contractStatusesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
