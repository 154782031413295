import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchContractById,
  fetchContractProductPlaces,
  updateContract,
  addContractProductPlace,
  editContractProductPlace,
  addContract,
} from "./contract";
import { queryClient } from "../../../App";

export const contractQueryOptions = (contractId: number | string) =>
  queryOptions({
    queryKey: ["contracts", contractId],
    queryFn: () => fetchContractById(contractId),
  });

export const contractProductPlaceQueryOptions = (contractId: number | string) =>
  queryOptions({
    queryKey: ["contracts", contractId, "products"],
    queryFn: () => fetchContractProductPlaces(contractId),
  });

export const useAddContractMutation = (companyId: number | string) => {
  return useMutation({
    mutationKey: ["contracts", "add", companyId],
    mutationFn: addContract,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useUpdateContractMutation = (contractId: number | string) => {
  return useMutation({
    mutationKey: ["contracts", "update", contractId],
    mutationFn: updateContract,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useAddContractProductPlaceMutation = (
  contractId: number | string
) => {
  return useMutation({
    mutationKey: ["contracts", "products", contractId],
    mutationFn: addContractProductPlace,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useEditContractProductPlaceMutation = (
  contractId: number | string
) => {
  return useMutation({
    mutationKey: ["contracts", "products", contractId],
    mutationFn: editContractProductPlace,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};
