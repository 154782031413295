import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useState } from "react";
import { Route } from "..";
import { useDeleteConsumerReceiverMutation } from "../-api/queryOptions";

const ReleaseRecevier = ({ receiverId }: { receiverId: number }) => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);

  const deleteMutation = useDeleteConsumerReceiverMutation(params.consumerId);

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation(); // Stop the event from bubbling up
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    deleteMutation.mutate(
      {
        receiverId: receiverId,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <IconButton icon={<Icon icon="delete" />} onClick={handleDelete} />

      <Dialog
        icon={<Icon icon={"delete"} />}
        open={open}
        onClose={handleClose}
        headline="Vill du ta bort inflyttad?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={deleteMutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={deleteMutation.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};

export default ReleaseRecevier;
