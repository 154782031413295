import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdateOfferWorthCommandType = {
  id: number;
  name: string;
  active: boolean;
};

export type AddOfferWorthCommandType = {
  name: string;
  active: boolean;
};

export const fetchOfferWorths = async () => {
  const response = await api
    .get(`offerworths`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function updateOfferWorth({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateOfferWorthCommandType>, "id">) {
  const result = await api
    .put(`offerworths/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addOfferWorth({ ...added }: AddOfferWorthCommandType) {
  const result = await api
    .post(`offerworths`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteOfferWorth({ id }: { id: number }) {
  const result = await api
    .delete(`offerworths/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
