import { useSuspenseQuery } from "@tanstack/react-query";
import { ErrorComponent, createFileRoute } from "@tanstack/react-router";

import DefaultPendingComponent from "../../../components/PendingComponent";
import { receiverQueryOptions } from "./-api/queryOptions";
import Receiver from "./-components/Receiver";

export const Route = createFileRoute("/receivers/$receiverId/")({
  component: ReceiverIndexComponent,
  loader: ({ context: { queryClient }, params: { receiverId } }) => {
    queryClient.ensureQueryData(receiverQueryOptions(receiverId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function ReceiverIndexComponent() {
  const params = Route.useParams();
  const receiverQuery = useSuspenseQuery(
    receiverQueryOptions(params.receiverId)
  );

  const receiver = receiverQuery.data;

  return (
    <div className="flex w-full flex-col lg:flex-row gap-4">
      <div className="flex w-full flex-col lg:flex-row ">
        <div className="flex flex-col gap-4 w-full">
          <Receiver receiver={receiver} />
        </div>
      </div>
    </div>
  );
}
