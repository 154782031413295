"use client";
import * as React from "react";
import { cs } from "../../../utils";

import CardContent from "../CardContent";
import { CardTextProps } from "./CardText.types";

const CardText: React.FC<CardTextProps> = (props) => {
  const { variant, className, children } = props;

  const variantContainerClasses = {
    headline: "py-0 pt-4 pb-1.5",
    subhead: "py-0",
    supportingText: "text-body-medium",
  };

  const variantClasses = {
    headline: "text-title-large",
    subhead: "text-body-small text-on-surface-variant",
    supportingText: "text-body-medium ",
  };

  const baseVariantWrapperClass = variantContainerClasses[variant];
  const baseVariantClass = variantClasses[variant];
  let variantClass = baseVariantClass || "";
  let variantWrapperClasses = baseVariantWrapperClass || "";

  return (
    <CardContent className={cs("text-on-surface flex w-full text-left", variantWrapperClasses, className)}>
      <div className={cs(variantClass)}>{children}</div>
    </CardContent>
  );
};

export default CardText;
