import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchConsumerEvents, removeOfferEvent } from "./events";
import { queryClient } from "../../../../../App";

export const consumerEventsQueryOptions = (
  consumerId: number | string,
  filters: number[]
) =>
  queryOptions({
    queryKey: ["consumers", "events", consumerId],
    queryFn: () => fetchConsumerEvents(consumerId),
    select: (events) =>
      events.filter((event: { eventId: number }) =>
        filters.includes(event.eventId)
      ),
  });

export const useRemoveOfferEventMutation = (consumerId: number) => {
  return useMutation({
    mutationKey: ["consumers", "events", consumerId],
    mutationFn: removeOfferEvent,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};
