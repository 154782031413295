import { queryOptions, useMutation } from "@tanstack/react-query";
import { addSeller, deleteSeller, fetchSellers, updateSeller } from "./seller";
import { queryClient } from "../../../App";

export const sellersQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["sellers"],
    queryFn: () => fetchSellers(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const useUpdateSellerMutation = (id: number) => {
  return useMutation({
    mutationKey: ["seller", "update", id],
    mutationFn: updateSeller,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddSellerMutation = () => {
  return useMutation({
    mutationKey: ["seller", "add"],
    mutationFn: addSeller,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteSellerMutation = () => {
  return useMutation({
    mutationKey: ["seller", "delete"],
    mutationFn: deleteSeller,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
