import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchReceiverById,
  fetchReceiverAffiliations,
  updateReceiverAffiliation,
  deleteReceiver,
  updateReceiverAffiliationRow,
  createConsumer,
} from "./receiver";
import { queryClient } from "../../../../App";

export const receiverQueryOptions = (receiverId: number | string) =>
  queryOptions({
    queryKey: ["receivers", receiverId],
    queryFn: () => fetchReceiverById(receiverId),
  });

export const receiverAffiliationsQuery = (receiverId: number | string) =>
  queryOptions({
    queryKey: ["receivers", "affiliation", receiverId],
    queryFn: () => fetchReceiverAffiliations(receiverId),
  });

export const useUpdateReceiverAffiliationsMutation = (
  receiverId: number | string
) => {
  return useMutation({
    mutationKey: ["receivers", "affiliation", receiverId],
    mutationFn: updateReceiverAffiliation,
    onSuccess: (newData) =>
      queryClient.setQueryData(
        ["receiver", "affiliation", receiverId],
        newData.data
      ),
  });
};

export const useUpdateReceiverAffiliationMutation = (
  id: number | string,
  receiverId: number | string
) => {
  return useMutation({
    mutationKey: ["receivers", "affiliation", receiverId, id],
    mutationFn: updateReceiverAffiliationRow,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useCreateConsumerMutation = (receiverId: number | string) => {
  return useMutation({
    mutationKey: ["receiver", "createconsumer", receiverId],
    mutationFn: createConsumer,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteReceiverMutation = (receiverId: number | string) => {
  return useMutation({
    mutationKey: ["receiver", receiverId],
    mutationFn: deleteReceiver,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
