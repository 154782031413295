import React from "react";
import { cs } from "../../../utils";

interface MenuItemProps {
  active?: boolean;
  children: React.ReactNode;
  onDick?: () => void;
}

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  ({ children, active = false, onDick }, ref) => {

    return (
      <div
        ref={ref}
        className={cs(
          "py-3 px-4 block truncate select-none",
          onDick && "cursor-pointer hover:bg-surface-container-high", 
          active && "bg-surface-container-high"
        )}
        onClick={onDick}
      >
        {children}
      </div>
    );
  }
);

export default MenuItem;
