import React from "react";
import { useController, FieldValues, Control } from "react-hook-form";
import Select, { Value } from "@pnpm-monorepo/core/src/ui/components/Select";

export interface SelectWrapperProps<T = any> {
  name: string;
  options: T[];
  control: Control<FieldValues>;
  defaultValue?: Value<T> | null;
  className?: string;
  getOptionLabel: (option: Value<T>) => object | string;
}

const SelectWrapper: React.FC<SelectWrapperProps> = ({
  name,
  options,
  control,
  defaultValue = null,
  className,
  getOptionLabel,
}) => {
  const {
    field: { onChange, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Select
      options={options}
      selectedOption={value}
      setSelectedOption={onChange}
      getOptionLabel={getOptionLabel}
      invalid={invalid}
      error={error}
      className={className}
    />
  );
};

export default SelectWrapper;
