"use client";
import * as React from "react";
import { cs } from "../../../utils";
import { CardProps, DynamicCardProps } from "./Card.types";

import CardText from "../CardText";

const Card: React.FC<CardProps> = ({
  className,
  variant = "filled",
  disabled = false,
  width = "w-full",
  image,
  children,
  onClick,
  headline,
  subhead,
  supportingText
}) => {
  const variantClasses = {
    filled: "bg-surface-container-lowest rounded-medium",
    elevated:
      "bg-surface-container-lowest rounded-medium shadow-mm-1",
    outlined:
      "bg-surface border-[1px] border-outline-variant rounded-medium",
  };

  const baseClass = variantClasses[variant];
  let variantClass = baseClass || "";

  if (disabled) {
    variantClass += " opacity-[.38]";
  }

  if (!disabled) {
    if (onClick) {
      variantClass += " cursor-pointer text-left";
    }

    if (onClick && variant === "filled") {
      variantClass += " hover:shadow-mm-1 active:shadow-none";
    }

    if (onClick && variant === "elevated") {
      variantClass += " hover:shadow-mm-2 active:shadow-mm-1";
    }

    if (onClick && variant === "outlined") {
      variantClass += " hover:shadow-mm-1 active:shadow-none";
    }
  }

  const Comp = onClick ? "button" : "div";

  const cardProps: DynamicCardProps = Comp === "button" ? { disabled } : {};

  if (Comp === "div" && disabled) {
    cardProps["aria-disabled"] = disabled;
  }

  return (
    <Comp className={cs("flex flex-col h-fit focus:outline-none", width && width, variantClass, className)} onClick={onClick} {...cardProps} tabIndex={0}>
      {image && <div className="overflow-hidden rounded-medium rounded-b-none">{image}</div>}
      {headline && <CardText variant="headline">{headline}</CardText>}
      {subhead && <CardText variant="subhead">{subhead}</CardText>}
      {supportingText && <CardText variant="supportingText">{supportingText}</CardText>}
      {children}
    </Comp>
  );
};

export default Card;
