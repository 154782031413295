import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { SchedulerType } from "../../../../../utils/types";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NumberInputWrapper from "../../../../../components/FormWrappers/NumberInputWrapper";
import SwitchWrapper from "../../../../../components/FormWrappers/SwitchWrapper";
import {
  SchedulerCommandType,
  useUpdateScheduleMutation,
} from "../../../../../utils/data/notification";

const schema = z.object({
  repeatMinutes: z.number().refine((value) => !isNaN(value), {
    message: "Upprepa varje minut är obligatirisk",
  }),
  active: z.boolean().refine((value) => typeof value === "boolean", {
    message: "Value must be a boolean",
  }),
});

const EditDialog = ({ schedule }: { schedule: SchedulerType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useUpdateScheduleMutation(schedule.id);

  const { control, handleSubmit, reset } = useForm<SchedulerCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      repeatMinutes: schedule.repeatMinutes,
      active: schedule.active,
    },
  });

  const onSubmit: SubmitHandler<SchedulerCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
        id: schedule.id,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleEdit} icon={<Icon icon="edit" size={22} />} />
      <Dialog
        icon={<Icon icon="edit" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Uppdatera schema?"
        text={schedule.executer?.name}
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Upprepa varje minut
            </div>
            <div className="pt-2 flex w-full">
              <NumberInputWrapper
                name="repeatMinutes"
                control={control}
                max={360}
                min={0}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDialog;
