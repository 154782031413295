import { Link, Outlet, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import {
  IconButton,
  TopAppBar,
} from "@pnpm-monorepo/core/src/ui/components";

import { MdArrowBack } from "@pnpm-monorepo/core/src/ui/icons";

import { ErrorComponent } from "../../../components/ErrorComponent";
import { companyQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../components/PendingComponent";
import Tabs from "../../../components/Tabs";

export const Route = createFileRoute("/companies/$companyId")({
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyQueryOptions(companyId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyComponent,
});

function CompanyComponent() {
  const params = Route.useParams();
  const companyQuery = useSuspenseQuery(companyQueryOptions(params.companyId));
  const company = companyQuery.data;

  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        left={
          <Link to={"/companies"} search={true}>
            <IconButton icon={<MdArrowBack />} />
          </Link>
        }
        headline={<>Företag {company.name}</>}
        className="sticky top-0 z-50 bg-surface"
      />

      <div className="flex-1 flex flex-col">
        <div className="p-6 flex flex-col gap-4 grow">
          <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "1",
                  header: "Inställningar",
                  to: "/companies/$companyId",
                  params: { companyId: params.companyId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "2",
                  header: "Kontrakt",
                  to: "/companies/$companyId/contracts",
                  params: { companyId: params.companyId },
                  search: true,
                  activeOptions: { exact: false },
                },
                {
                  id: "3",
                  header: "Butiker",
                  to: "/companies/$companyId/stores",
                  params: { companyId: params.companyId },
                  search: true,
                  activeOptions: { exact: false },
                },
                {
                  id: "4",
                  header: "Erbjudanden",
                  to: "/companies/$companyId/offers",
                  params: { companyId: params.companyId },
                  search: true,
                  activeOptions: { exact: false },
                },
                {
                  id: "5",
                  header: "Kampanjunderlag",
                  to: "/companies/$companyId/campaigns",
                  params: { companyId: params.companyId },
                  search: true,
                  activeOptions: { exact: false },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>

          <div className="flex justify-center gap-4 mx-auto w-full lg:max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
