"use client";
import React, { useCallback, useEffect, useState } from "react";

import {
  APIProvider,
  ControlPosition,
  Map as GoogleMap,
  Marker,
} from "@vis.gl/react-google-maps";
import { cs } from "../../../utils";

import { Map2Props, MapProps, MarkerProps } from "./Map.types";
import { CustomMapControl } from "./MapControl";
import MapHandler from "./MapHandler";

export const MapApiKey = "AIzaSyBgO4J5clObX2YUgkp8_jorVOTWjhH46y8";

const Map: React.FC<MapProps> = (props) => {
  const { latitude, longitude, className } = props;

  const position = { lat: latitude, lng: longitude };

  return (
    <div
      className={cs(
        "relative flex bg-surface-container-lowest rounded-medium",
        className
      )}
    >
      <APIProvider apiKey={MapApiKey}>
        <GoogleMap defaultCenter={position} defaultZoom={10}>
          {/* 
          // behöver sätta upp och hämta ett MAp-id
          <AdvancedMarker position={position} /> */}
          <Marker position={position} />
        </GoogleMap>
      </APIProvider>
    </div>
  );
};

export const Map2: React.FC<Map2Props> = (props) => {
  const { latitude, longitude, className, markerPosition, setMarkerPosition } =
    props;
  const initialPosition = { lat: latitude, lng: longitude };

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (selectedPlace && selectedPlace.geometry) {
      const newPosition = {
        lat: selectedPlace.geometry.location
          ? selectedPlace.geometry.location.lat()
          : 0,
        lng: selectedPlace.geometry.location
          ? selectedPlace.geometry.location.lng()
          : 0,
      };
      setMarkerPosition(newPosition);
    }
  }, [selectedPlace]);

  const onMarkerDragEnd = useCallback((e: google.maps.MapMouseEvent) => {
    const lat = e.latLng ? e.latLng.lat() : 0;
    const lng = e.latLng ? e.latLng.lng() : 0;
    setMarkerPosition({ lat, lng });
  }, []);

  return (
    <div
      className={cs(
        "relative flex bg-surface-container-lowest rounded-medium",
        className
      )}
    >
      <APIProvider apiKey={MapApiKey}>
        <GoogleMap
          defaultCenter={initialPosition}
          defaultZoom={10}
          disableDefaultUI={true}
        >
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>
        <CustomMapControl
          controlPosition={ControlPosition.TOP}
          onPlaceSelect={setSelectedPlace}
        />
        <MapHandler place={selectedPlace} />
      </APIProvider>
    </div>
  );
};

export default Map;
