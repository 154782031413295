import api from "../../../utils/api";
import {
  ChainType,
  PlaceType,
  SellerType,
  SortOrder,
  StatusType,
} from "../../../utils/types";

export type CompaniesSortBy =
  | "number"
  | "name"
  | "organizationNumber"
  | "place"
  | "statusName"
  | "updatedAt";

export type AddNewCompanyCommandType = {
  name: string;
  friendlyName?: string;
  organizationNumber?: string;
  description?: string;
  emailStatistics?: string;
  contractSigner?: string;
  seller?: SellerType;
  chain?: ChainType;
  status: StatusType;
};

export const fetchCompanies = async ({
  q,
  page,
  sortBy,
  sortOrder,
  place,
  status,
  seller,
}: {
  q?: string;
  page?: number;
  sortBy?: CompaniesSortBy;
  sortOrder?: SortOrder;
  place?: PlaceType[];
  status?: StatusType[];
  seller?: SellerType[];
} = {}) => {
  const companies = await api
    .get(`companies`, {
      params: {
        search: q,
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        place: place?.join(","),
        status: status?.join(","),
        seller: seller?.join(","),
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companies;
};

export async function createCompany({
  ...data
}: Partial<AddNewCompanyCommandType>) {
  const result = await api
    .post(`companies`, {
      ...data,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
