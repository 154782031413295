import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  addLogo,
  deleteLogo,
  fetchCompanyById,
  fetchCompanyCanCreateOffer,
  updateCompany,
  updateCompanyAddress,
} from "./company";
import { queryClient } from "../../../../App";

export const companyQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", companyId],
    queryFn: () => fetchCompanyById(companyId),
  });

  export const companyCanCreateOfferQueryOptions = (companyId: number | string) =>
    queryOptions({
      queryKey: ["companies", "cancreate", companyId],
      queryFn: () => fetchCompanyCanCreateOffer(companyId),
    });

export const useUpdateCompanyMutation = (companyId: number | string) => {
  return useMutation({
    mutationKey: ["company", "update", companyId],
    mutationFn: updateCompany,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useUpdateCompanyAddressMutation = (companyId: number | string) => {
  return useMutation({
    mutationKey: ["company", "updateaddress", companyId],
    mutationFn: updateCompanyAddress,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useAddCompanyLogo = (companyId: number) => {
  return useMutation({
    mutationKey: ["company", "logo", companyId],
    mutationFn: addLogo,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};

export const useDeleteCompanyLogo = (companyId: number, imageId: number) => {
  return useMutation({
    mutationKey: ["company", "logo", imageId, companyId, "delete"],
    mutationFn: deleteLogo,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};
