import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchConsumerById,
  fetchConsumerReceivers,
  fetchConsumerStatuses,
  fetchConsumerPlaces,
  fetchConsumerGenders,
  deleteConsumerReceiver,
  updateConsumer,
  updateConsumerReceiverRelation,
  updateReceiverConsumerRelation,
  updateConsumerId,
  updateConsumerLogin,
  updateConsumerPassword,
  deleteConsumer,
} from "./consumers";
import { queryClient } from "../../../../App";

export const consumerQueryOptions = (consumerId: number | string) =>
  queryOptions({
    queryKey: ["consumers", consumerId],
    queryFn: () => fetchConsumerById(consumerId),
  });

export const consumerReceiversQueryOptions = (consumerId: number | string) =>
  queryOptions({
    queryKey: ["consumers", "receviers", consumerId],
    queryFn: () => fetchConsumerReceivers(consumerId),
  });

export const consumerStatusesQueryOptions = () =>
  queryOptions({
    queryKey: ["consumers", "status"],
    queryFn: () => fetchConsumerStatuses(),
  });

export const consumerPlacesQueryOptions = () =>
  queryOptions({
    queryKey: ["consumers", "places"],
    queryFn: () => fetchConsumerPlaces(),
  });

export const consumerGendersQueryOptions = () =>
  queryOptions({
    queryKey: ["consumers", "genders"],
    queryFn: () => fetchConsumerGenders(),
  });

export const useUpdateConsumerUserIdMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", "userId", consumerId],
    mutationFn: updateConsumerId,
    onSuccess: () => queryClient.invalidateQueries({queryKey :["consumers", consumerId]}),
  });
};

export const useUpdateConsumerLoginMutation = (consumerId: number | string) => {
  return useMutation({
    mutationKey: ["consumers", "login", consumerId],
    mutationFn: updateConsumerLogin,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useUpdateConsumerPasswordMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", "password", consumerId],
    mutationFn: updateConsumerPassword,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useConsumerMutation = (consumerId: number | string) => {
  return useMutation({
    mutationKey: ["consumers", consumerId],
    mutationFn: updateConsumer,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useConsumerReceiverRelationMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", "consumerrecevierrelation", consumerId],
    mutationFn: updateConsumerReceiverRelation,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useReceiverConsumerRelationMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", "recevierconsumerrelation", consumerId],
    mutationFn: updateReceiverConsumerRelation,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteConsumerMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", consumerId],
    mutationFn: deleteConsumer,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteConsumerReceiverMutation = (
  consumerId: number | string
) => {
  return useMutation({
    mutationKey: ["consumers", "receviers", consumerId],
    mutationFn: deleteConsumerReceiver,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};