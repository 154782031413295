import {
  TextField,
  TextFieldPropsVariant,
} from "@pnpm-monorepo/core/src/ui/components";
import { ReactNode } from "react";
import { useController, FieldValues, Control } from "react-hook-form";

interface TextFieldWrapperProps {
  name: string;
  supportingText?: string;
  placeholder?: string;
  control: Control<FieldValues>;
  defaultValue?: string;
  disabled?: boolean;
  variant?: TextFieldPropsVariant;
  className?: string;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  style?: React.CSSProperties;
}

const TextFieldWrapper: React.FC<TextFieldWrapperProps> = ({
  name,
  control,
  defaultValue,
  disabled,
  placeholder,
  variant = "text",
  className,
  leftElement,
  rightElement,
  supportingText,
  style
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || "",
  });

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <TextField
      variant={variant}
      name={name}
      multiline={false}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      invalid={invalid}
      error={error}
      placeholder={placeholder}
      className={className}
      leftElement={leftElement}
      rightElement={rightElement}
      supportingText={supportingText}
      style={style}
    />
  );
};

export default TextFieldWrapper;
