import { ErrorComponent, createFileRoute } from "@tanstack/react-router";
import DefaultPendingComponent from "../../../components/PendingComponent";
import { companyQueryOptions } from "./-api/queryOptions";
import { useSuspenseQuery } from "@tanstack/react-query";
import Common from "./-components/Common";
import Address from "./-components/Address";
import Logotype from "./-components/Logotype";
import {
  chainsQueryOptions,
  companyStatusesQueryOptions,
  sellersQueryOptions,
} from "../../../utils/data/common";
import LogDialog from "./-components/LogDialog";

export const Route = createFileRoute("/companies/$companyId/")({
  component: CompanyIndexComponent,
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyQueryOptions(companyId));
    queryClient.ensureQueryData(chainsQueryOptions());
    queryClient.ensureQueryData(companyStatusesQueryOptions());
    queryClient.ensureQueryData(sellersQueryOptions());
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function CompanyIndexComponent() {
  const params = Route.useParams();
  const companyQuery = useSuspenseQuery(companyQueryOptions(params.companyId));
  const company = companyQuery.data;

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col mb-4">
        <div className="flex justify-end">
          <LogDialog />
        </div>
      </div>
      <div className="flex w-full flex-col lg:flex-row gap-4">
        <div className="flex w-full gap-4 flex-col lg:flex-row ">
          <div className="lg:w-[560px] flex flex-col gap-4">
            <Common company={company} />
            <Logotype company={company} />
          </div>
          <div className="flex-1">
            <Address company={company} />
          </div>
        </div>
      </div>
    </div>
  );
}
