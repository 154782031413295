import { useState } from "react";

import {
  Button,
  Dialog,
  IconButton,
  Icon,
} from "@pnpm-monorepo/core/src/ui/components";

import { MdDelete } from "@pnpm-monorepo/core/src/ui/icons";

import { OfferEventType } from "../../../../../utils/types";
import { useRemoveOfferEventMutation } from "../-api/queryOptions";

export function EventActions({ event }: { event: OfferEventType }) {
  return (
    <>
      {event && event.event === "Inlöst" ? (
        <DeleteEvent consumerId={event.consumerId} offerId={event.offerId} />
      ) : null}
    </>
  );
}

const DeleteEvent = ({
  consumerId,
  offerId,
}: {
  consumerId: number;
  offerId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const removeOfferEvent = useRemoveOfferEventMutation(consumerId);

  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    removeOfferEvent.mutate(
      {
        offerId: offerId,
        consumerId: consumerId,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <IconButton
        variant="tonal"
        onClick={handleDelete}
        icon={<Icon icon="delete" size={22} />}
      />
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort händelse?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={removeOfferEvent.isPending}
            >
              Ok
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};
