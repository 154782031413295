import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../../utils/api";
import { PlaceType } from "../../../../../utils/types";

export const fetchCompanyStores = async (id: number | string) => {
  const companyStores = await api
    .get(`companies/${id}/stores`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyStores;
};

export const addCompanyStores = async (id: number | string) => {
  const companyStores = await api
    .get(`companies/${id}/stores`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyStores;
};

export type AddNewStoreCommandType = {
  companyId: number | string;
  name: string;
  place: PlaceType;
};

export async function createStore({
  companyId,
  ...data
}: PickAsRequired<Partial<AddNewStoreCommandType>, "companyId">) {
  const result = await api
    .post(`companies/${companyId}/stores`, {
      ...data,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
