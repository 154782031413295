import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";
import { OfferActionType, OfferTypeType, ProductCategoryType } from "../../types";

export type UpdateProductCommandType = {
  id: number;
  name: string;
  description: string;
  price: string;
  productCategory: ProductCategoryType;
  active: boolean;
};

export type AddProductCommandType = {
  name: string;
  description: string;
  price: string;
  productCategory: ProductCategoryType;
  active: boolean;
};

export type UpdateProductCategoryCommandType = {
  id: number;
  name: string;
  description: string;
  colour: string;
  limitedConsumerDays: boolean;
  limitedCashing: boolean;
  offerAction?: OfferActionType;
  offerType?: OfferTypeType;
  active: boolean;
};

export type AddProductCategoryCommandType = {
  name: string;
  description: string;
  colour: string;
  limitedConsumerDays: boolean;
  limitedCashing: boolean;
  offerAction?: OfferActionType;
  offerType?: OfferTypeType;
  active: boolean;
};

export const fetchOfferTypes = async () => {
  const response = await api
    .get(`/offers/types`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchOfferActions = async () => {
  const response = await api
    .get(`/offers/actions`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchProducts = async () => {
  const result = await api
    .get(`products`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchProductCategories = async () => {
  const result = await api
    .get(`products/categories`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function updateProduct({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateProductCommandType>, "id">) {
  const result = await api
    .put(`products/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addProduct({
  ...added
}: AddProductCommandType) {
  const result = await api
    .post(`products`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteProduct({ id }: { id: number }) {
  const result = await api
    .delete(`products/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateProductCategory({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateProductCategoryCommandType>, "id">) {
  const result = await api
    .put(`products/categories/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addProductCategory({
  ...added
}: AddProductCategoryCommandType) {
  const result = await api
    .post(`products/categories`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteProductCategory({ id }: { id: number }) {
  const result = await api
    .delete(`products/categories/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}