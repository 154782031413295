import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  LinkBox,
  Value,
} from "@pnpm-monorepo/core/src/ui/components";
import { Route } from "..";
import React from "react";
import { Link } from "@tanstack/react-router";
import { SmallDateString } from "../../../../utils/renderers";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import { ConsumerType } from "../../../../utils/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import {
  consumerStatusesQueryOptions,
  consumerPlacesQueryOptions,
  useConsumerMutation,
} from "../-api/queryOptions";
import ChangeUserId from "./ChangeUserId";
import ChangePassword from "./ChangePassword";
import ChangeLogin from "./ChangeLogin";
import { ConsumerCommandType } from "../-api/consumers";

import { useSnackbar } from "notistack";

const Account = ({ consumer }: { consumer: ConsumerType }) => {
  const [edit, setEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = Route.useParams();

  const consumerStatusesQuery = useQuery(consumerStatusesQueryOptions());
  const consumerPlacesQuery = useQuery(consumerPlacesQueryOptions());

  const mutation = useConsumerMutation(params.consumerId);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...consumer,
    },
  });

  const onSubmit: SubmitHandler<ConsumerCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Status uppdaterad");
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit ? (
        <Card>
          <CardHeader
            title="Konto"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />

          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                E-post/Login
              </div>
              <div className="text-body-medium">
                {consumer.email ? (
                  <span>{consumer.email}</span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                UserId
              </div>
              <div className="text-body-medium">
                {consumer.userId ? (
                  <span>{consumer.userId}</span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Välkomstkod
              </div>
              <div className="text-body-medium">
                {consumer.receiverId ? (
                  <LinkBox className="whitespace-normal">
                    <Link
                      to={`/receivers/$receiverId`}
                      params={{ receiverId: consumer.receiverId.toString() }}
                    >
                      {consumer.code}
                    </Link>
                  </LinkBox>
                ) : (
                  consumer.code
                )}{" "}
                {consumer.source ? <span>({consumer.source})</span> : null}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Välkomstområde
              </div>
              <div className="text-body-medium">
                {consumer.welcomePlace ? (
                  <span>{consumer.welcomePlace.name}</span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Aktuellt område
              </div>
              <div className="text-body-medium">
                {consumer.place ? (
                  <span>{consumer.place.name}</span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Skapad
              </div>
              <div className="text-body-medium">
                {consumer.createdAt ? (
                  <span>
                    <SmallDateString value={consumer.createdAt} />
                  </span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="text-body-medium">
                {consumer.status ? (
                  <span>{consumer.status.name}</span>
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className="" variant="outlined">
          <CardHeader
            title={"Konto"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                E-post/Login
              </div>
              <div className="mt-2 px-3 flex items-center bg-surface-container">
                <span className="grow flex itemss-center">
                  {consumer.email ? consumer.email : null}
                </span>
                <ChangeLogin consumer={consumer} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Lösenord
              </div>
              <div className="mt-2 px-3 flex items-center bg-surface-container">
                <span className="grow flex itemss-center">**********</span>
                <ChangePassword consumer={consumer} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                UserId
              </div>
              <div className="mt-2 px-3 flex items-center bg-surface-container">
                <span className="grow flex itemss-center">
                  {consumer.userId ? consumer.userId : null}
                </span>
                <ChangeUserId consumer={consumer} />
              </div>
              <div className="mt-2 text-label-small text-on-surface-variant">
                UserId är kopplat till Azure AD B2C. En användare med samma
                UserId och Login måste även finnas där. Om UserId börjar på
                auth0, då är kontot inte kopplat mot Azure AD B2C.
              </div>
            </div>

            <div className="pt-4 border-t-[1px] border-outline-variant -mx-3"></div>

            {consumer.status && consumer.status.id === 30 ? (
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Aktuellt område
                </div>
                <div className="pt-2">
                  {!consumerPlacesQuery.isPending && (
                    <SelectWrapper
                      name="place"
                      control={control}
                      className="min-w-32"
                      options={consumerPlacesQuery.data}
                      getOptionLabel={(option: Value) => option?.name}
                    />
                  )}
                </div>
              </div>
            ) : null}

            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Status
              </div>
              <div className="pt-2">
                {!consumerStatusesQuery.isPending && (
                  <SelectWrapper
                    name="status"
                    control={control}
                    className="min-w-32"
                    options={consumerStatusesQuery.data}
                    getOptionLabel={(option: Value) => option?.name}
                  />
                )}
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default Account;
