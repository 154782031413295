import { queryOptions, useMutation } from "@tanstack/react-query";
import { addOffer, updateCompanyOfferProof, fetchCompanyOffers, fetchCompanyOffersProof } from "./companyOffers";
import { queryClient } from "../../../../../App";

export const companyOffersProofQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", "proofs", companyId],
    queryFn: () => fetchCompanyOffersProof(companyId),
  });

  export const companyOffersQueryOptions = (companyId: number | string) =>
    queryOptions({
      queryKey: ["companies", companyId, "offers"],
      queryFn: () => fetchCompanyOffers(companyId),
    });

  export const useAddOffer = (companyId: number | string) => {
    return useMutation({
      mutationKey: ["companies", "offers", companyId],
      mutationFn: addOffer,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };

  export const useUpdateCompanyOfferProof = (companyId: number | string) => {
    return useMutation({
      mutationKey: ["companies", "proofs-update", companyId],
      mutationFn: updateCompanyOfferProof,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  }