import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { IndustryType, PlaceType, ProductType, SortOrder, StatusType } from "../../../utils/types";
import { OffersSortBy, fetchOffers } from "./offers";

export const offersQueryOptions = (opts: {
  q?: string;
  page?: number;
  sortBy?: OffersSortBy;
  sortOrder?: SortOrder;
  place?: PlaceType[];
  status?: StatusType[];
  product?: ProductType[];
  category?: IndustryType[];
}) => {
  return queryOptions({
    queryKey: ["offers", opts.q, opts.page, opts.sortBy, opts.sortOrder, opts.place, opts.status, opts.product, opts.category],
    queryFn: () => fetchOffers(opts),
    placeholderData: keepPreviousData,
  });
};
