import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";

import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import {
  AddProductCommandType,
  productCategoriesQueryOptions,
  useAddProductMutation,
} from "../../../../utils/data/products";
import TextareaWrapper from "../../../../components/FormWrappers/TextareaWrapper";
import { useQuery } from "@tanstack/react-query";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  description: z.string().nullable(),
  price: z.string().refine((val) => /^[0-9]+(,[0-9]{1,2})?$/.test(val), {
    message:
      "Priset måste vara ett positivt nummer med högst två decimaler, separerade med ett kommatecken",
  }),
  productCategory: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Kategori är obligatoriskt",
    }),
  active: z.boolean(),
});

const AddDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const productCategoriesQuery = useQuery(
    productCategoriesQueryOptions("", "name", true)
  );

  const mutation = useAddProductMutation();

  const { control, handleSubmit, reset } = useForm<AddProductCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      price: "0",
      productCategory: undefined,
      active: true,
    },
  });

  const onSubmit: SubmitHandler<AddProductCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    mutation.reset();
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="filled"
        onClick={handleEdit}
        icon={<Icon icon="add" size={20} />}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<Icon icon="add" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till produkt?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Beskrivning
            </div>
            <div className="pt-2">
              <TextareaWrapper name="description" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Pris
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="price" control={control} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Kategori
            </div>
            <div className="pt-2">
              {!productCategoriesQuery.isPending ? (
                <SelectWrapper
                  name="productCategory"
                  control={control}
                  className="min-w-32"
                  options={productCategoriesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddDialog;
