import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  Icon,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";

import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import { AddOfferServiceCommandType, useAddOfferServiceMutation } from "../../../../utils/data/offerservices";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  active: z.boolean(),
});

const AddDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useAddOfferServiceMutation();

  const { control, handleSubmit, reset } = useForm<AddOfferServiceCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      active: true,
    },
  });

  const onSubmit: SubmitHandler<AddOfferServiceCommandType> = async (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    mutation.reset();
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="filled"
        onClick={handleEdit}
        icon={<Icon icon="add" size={20} />}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<Icon icon="add" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till erbjudandetjänst?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddDialog;
