/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from "react";
import { useImage } from "react-image";

import { cs } from "@pnpm-monorepo/core/src/utils";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import "dayjs/locale/sv"; // import locale

dayjs.locale("sv"); // use locale
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.guess();

import defaultImageThumbnail from "../assets/defaultImageThumbnail.jpg";
import { ObjectInterface } from "./form/types";

interface NumberLocalStringProps {
  value: number;
}

export const NumberLocalString: React.FC<NumberLocalStringProps> = ({
  value,
}) => {
  return value ? value.toLocaleString("sv") : 0;
};

interface TextContainerProps {
  children: ReactNode;
  className?: string;
}

export const TextContainer: React.FC<TextContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={cs("min-w-72 line-clamp-3", className)}>{children}</div>
  );
};

interface NoWrapProps {
  children: ReactNode;
}

export const NoWrap: React.FC<NoWrapProps> = ({ children }) => {
  return <span className="whitespace-nowrap block">{children}</span>;
};

interface NumberProps {
  children: ReactNode;
}

export const Number: React.FC<NumberProps> = ({ children }) => {
  return <span className="font-mono">{children}</span>;
};

interface CodeProps {
  children: ReactNode;
}

export const Code: React.FC<CodeProps> = ({ children }) => {
  return <code className="">{children}</code>;
};

interface YesNoProps {
  value: boolean;
}

export const YesNo: React.FC<YesNoProps> = ({ value }) => {
  return value === true ? <span>Ja</span> : <span>Nej</span>;
};

interface OrgNumberProps {
  value: string | null;
}

export const OrgNumber: React.FC<OrgNumberProps> = ({ value }) => {
  if (value != null && value.length === 10) {
    return <>{value.substring(0, 6) + "-" + value.substring(6)}</>;
  }

  return <>{value}</>;
};

interface DelayedProps {
  value: number;
}

export const Delayed: React.FC<DelayedProps> = ({ value }) => {
  return value !== 0 ? <>{value} dagar</> : <>Nej</>;
};

interface DateProps {
  value: string | Date | undefined;
}

export const SmallDateString: React.FC<DateProps> = ({ value }) => {
  return dayjs(value).isValid() ? dayjs.utc(value).local().format("YYYY-MM-DD") : "";
};

export const SmallDateTimeString: React.FC<DateProps> = ({ value }) => {
  return dayjs(value).isValid() ? dayjs.utc(value).local().format("YYYY-MM-DD HH:mm") : "";
};

export const YearMonthString: React.FC<DateProps> = ({ value }) => {
  return dayjs(value).isValid() ? dayjs(value).format("YYYY-MM") : "";
};

interface DefaultImageProps {
  alt?: string;
  className?: string;
}

export const FallbackImage: React.FC<DefaultImageProps> = ({
  alt = "",
  className,
}) => {
  return <img src={defaultImageThumbnail} alt={alt} className={className} />;
};

interface ImageProps {
  alt?: string;
  url: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = ({ alt = "", url, className }) => {
  const { src } = useImage({
    srcList: [url, defaultImageThumbnail],
  });

  return <img src={src} alt={alt} className={className} />;
};

export type SelectObjectFromArrayProps = {
  array: ObjectInterface[];
  selector: ObjectInterface | any;
  renderProperty: (selectedObject: ObjectInterface) => string;
}

export const SelectObjectFromArray: React.FC<SelectObjectFromArrayProps> = ({
  array,
  selector,
  renderProperty,
}) => {
  const isEqual = (obj1: ObjectInterface, obj2: ObjectInterface): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const selectedObject =
    typeof selector === "object"
      ? array.find((obj) => isEqual(obj, selector))
      : array.find((obj) => Object.values(obj).includes(selector));

  return <div>{selectedObject && renderProperty(selectedObject)}</div>;
};
