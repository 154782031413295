import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  LinkBox,
  List,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import { Route } from "..";
import { useQuery } from "@tanstack/react-query";
import {
  consumerReceiversQueryOptions,
  useReceiverConsumerRelationMutation,
} from "../-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { ReceiverType } from "../../../../utils/types";
import ReleaseRecevier from "./ReleaseRecevier";
import AddReceiver from "./AddReceiver";
import { SmallDateString } from "../../../../utils/renderers";
import { Link } from "@tanstack/react-router";

const Receivers: React.FC = () => {
  const params = Route.useParams();
  const { data, isPending } = useQuery(
    consumerReceiversQueryOptions(params.consumerId)
  );

  const updateMutation = useReceiverConsumerRelationMutation(params.consumerId);

  const handleSetConsumerReceiverRelation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    receiver: ReceiverType
  ): void => {
    event.stopPropagation(); // Stop the event from bubbling up
    if (!updateMutation.isPending) {
      updateMutation.mutate(
        {
          ...receiver,
          isActiveConsumer: true,
        },
        {
          onSuccess: () => {},
          onSettled: () => {},
        }
      );
    }
  };

  return (
    <Card className="">
      <CardHeader
        title="Inflyttade"
        actions={
          <>
            <AddReceiver />
          </>
        }
      />
      <CardContent>
        <div className="flex flex-col">
          {isPending ? (
            <DefaultPendingComponent />
          ) : (
            <>
              {data.length ? (
                <ReceiverTable
                  data={data}
                  setConsumerReceiverRelation={
                    handleSetConsumerReceiverRelation
                  }
                />
              ) : (
                //data.map((receiver: ReceiverType) => (
                // <List
                //   onClick={(e) =>
                //     handleSetConsumerReceiverRelation(e, receiver)
                //   }
                //   key={receiver.id}
                //   headline={
                //     <>
                //       {receiver.code} - {receiver.placeName}
                //     </>
                //   }
                //   supportingText={
                //     <>
                //       {receiver.fullName} / {receiver.week_ISO}
                //       {receiver.addressLine2 ? (
                //         <>{" "}/{" "}
                //           {receiver.addressLine2}, {receiver.addressLine3}{" "}
                //           {receiver.addressLine4}
                //         </>
                //       ) : undefined}
                //     </>
                //   }
                //   leadingItem={
                //     <>
                //       {receiver.isActiveConsumer ? (
                //         <Icon
                //           icon="check"
                //           className={cs(
                //             receiver.isActiveConsumer && "text-primary"
                //           )}
                //         />
                //       ) : (
                //         ""
                //       )}
                //     </>
                //   }
                //   trailingItem={
                //     <>
                //       <ReleaseRecevier receiverId={receiver.id} />
                //     </>
                //   }
                // />

                //)

                <div className="p-3 bg-tertiary-container text-center rounded-large text-body-medium">
                  Det finns ingen inflyttad kopplad till konsument.
                </div>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const ReceiverTable = ({
  data,
  setConsumerReceiverRelation,
}: {
  data: ReceiverType[];
  setConsumerReceiverRelation: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    receiver: ReceiverType
  ) => void;
}) => {
  return (
    <div className="w-full bg-inverse-on-surface rounded-large">
      <Table.Wrapper>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Kod</Table.HeadCell>
              <Table.HeadCell>Område</Table.HeadCell>
              <Table.HeadCell>Namn</Table.HeadCell>
              <Table.HeadCell>Adress</Table.HeadCell>
              <Table.HeadCell>Inflyttad</Table.HeadCell>
              <Table.HeadCell>Skapad</Table.HeadCell>
              <Table.HeadCell className="w-12"></Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.map((receiver: ReceiverType) => {
              return (
                <Table.Row key={receiver.id}>
                  <Table.RowCell>
                    <Link
                      to={`/receivers/$receiverId`}
                      params={(prev) => ({
                        ...prev,
                        receiverId: receiver.id,
                      })}
                      search={true}
                    >
                      <LinkBox>{receiver.code}</LinkBox>
                    </Link>
                  </Table.RowCell>
                  <Table.RowCell>{receiver.placeName}</Table.RowCell>
                  <Table.RowCell>{receiver.fullName}</Table.RowCell>
                  <Table.RowCell>
                    {receiver.addressLine2 ? (
                      <>
                        {receiver.addressLine2}, {receiver.addressLine3}{" "}
                        {receiver.addressLine4}
                      </>
                    ) : undefined}
                  </Table.RowCell>
                  <Table.RowCell>{receiver.week_ISO}</Table.RowCell>
                  <Table.RowCell>
                    <SmallDateString value={receiver.createdAt} />
                  </Table.RowCell>
                  <Table.RowCell className="">
                    <IconButton
                      onClick={(e) => setConsumerReceiverRelation(e, receiver)}
                      variant={receiver.isActiveConsumer ? "tonal" : "standard"}
                      icon={<Icon icon="check" />}
                    />
                    <ReleaseRecevier receiverId={receiver.id} />
                  </Table.RowCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </Table.Wrapper>
    </div>
  );
};

export default Receivers;
