import { cs } from "@pnpm-monorepo/core/src/utils";
import { Link } from "@tanstack/react-router";

export type TabProps = {
  id: string;
  icon?: React.ReactNode;
  header: string;
  to: string;
  params?: object;
  search?: boolean;
  activeOptions?: object;
};

export type TabsProps = {
  tabs: TabProps[];
  className?: string;
};

const TabsPrimary: React.FC<TabsProps> = (props) => {
  const { tabs, className } = props;

  return (
    <div
      className={cs(
        "flex overflow-auto mb-2 rounded-full p-[2px] justify-center h-[88px] gap-2 opacity-100 my-0 pt-[9px] transition-all",
        "h-[72px]",
        className
      )}
    >
      <div className="flex justify-start w-full h-full text-center whitespace-nowrap overflow-auto rounded-[40px] bg-surface-container-lowest">
        {tabs &&
          tabs.map((tab: TabProps) => (
            <Link key={tab.id} {...tab} className="flex grow">
              {({ isActive }) => (
                <div
                  className={cs(
                    "text-title-medium bg-surface-container-lowest inline-flex relative grow items-center justify-center text-on-surface-variant leading-[44px] no-underline cursor-pointer align-middle px-6 py-0 rounded-[40px] border-[none]",
                    "hover:bg-surface-container hover:text-on-surface",
                    // before
                    "before:absolute  before:bg-secondary-container before:w-full before:h-full before:rounded-[40px] before:opacity-0 before:duration-[0.2s] before:transition-[transform,opacity] before:ease-linear before:scale-x-[0.32]",
                    isActive && "before:opacity-100 before:scale-x-100"
                  )}
                >
                  {tab.icon && (
                    <span className="z-10 flex mr-[8px]">{tab.icon}</span>
                  )}
                  {tab.header && (
                    <span className={cs("z-10", isActive && "font-medium")}>
                      {tab.header}
                    </span>
                  )}
                </div>
              )}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default TabsPrimary;
