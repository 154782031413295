import {
  ErrorComponent as RouterErrorComponent,
  ErrorComponentProps
} from "@tanstack/react-router";
import { NotFoundError } from "../utils/errors";


export function ErrorComponent({ error }: ErrorComponentProps) {
  if (error instanceof NotFoundError) {
    return (
      <div
        id="offer-notfound-page"
        className="flex flex-col h-full w-fill justify-center items-center"
      >
        {error ? (
          <div className="bg-tertiary-container text-on-tertiary-container rounded-small p-4">
            <p className="text-headline-medium">{error.message}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <RouterErrorComponent error={error} />;
}