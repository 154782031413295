import { useState } from "react";
import {
  Button,
  Dialog,
  Icon,
  Map2,
  MarkerProps,
} from "@pnpm-monorepo/core/src/ui/components";

export interface MapDialogProps {
  latitude: number;
  longitude: number;
  onPlaceSelectOk?: (position: MarkerProps) => void;
  address?: string;
}

const MapDialog = (props: MapDialogProps) => {
  
  const { latitude, longitude, onPlaceSelectOk } = props;
  const initialPosition = { lat: latitude, lng: longitude };

  const [open, setOpen] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] =
    useState<MarkerProps>(initialPosition);

  function handleOpen(): void {
    setOpen(true);
    setMarkerPosition(initialPosition)
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOnPlaceOk(): void {
    if (markerPosition && onPlaceSelectOk) onPlaceSelectOk(markerPosition);
    handleClose();
  }

  return (
    <>
      <Button
        iconLeft={<Icon icon="pin_drop" size={20} />}
        variant="outlined"
        onClick={handleOpen}
      >
        Sätt Longitude/Latitude
      </Button>
      <Dialog
        icon={<Icon icon="pin_drop" />}
        open={open}
        onClose={handleClose}
        headline="Sätt Longitude/Latitude"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            {markerPosition && (
              <Button variant="filled" onClick={handleOnPlaceOk}>
                Välj plats
              </Button>
            )}
          </>
        }
      >
        <div className="h-full mt-10">
          <Map2
            longitude={longitude}
            latitude={latitude}
            className="h-[500px]"
            markerPosition={markerPosition}
            setMarkerPosition={setMarkerPosition}
          />
        </div>
      </Dialog>
    </>
  );
};

export default MapDialog;
