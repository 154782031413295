import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchNotificationScheduler, updateSchedule } from "./notification";
import { queryClient } from "../../../App";

export const notificationSchedulerQueryOptions = (
  q: string = "",
  propertyName: string
) =>
  queryOptions({
    queryKey: ["notifications"],
    queryFn: () => fetchNotificationScheduler(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        } else if (event.executer) {
          const executerName = event.executer.name || "";
          const executerContentName = event.executer.content?.name || "";
          return (
            executerName.toLowerCase().includes(q.toLowerCase()) ||
            executerContentName.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

  export const useUpdateScheduleMutation = (scheduleId: number) => {
    return useMutation({
      mutationKey: ["notifications", "update", scheduleId],
      mutationFn: updateSchedule,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };