import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";

import { Route } from "../route";
import TextFieldWrapper from "../../../components/FormWrappers/TextFieldWrapper";
import { AddNewReceiverCommandType } from "../-api/receivers";
import { useCreateReceiverMutation } from "../-api/queryOptions";
import SelectWrapper from "../../../components/FormWrappers/SelectWrapper";
import { useNavigate } from "@tanstack/react-router";
import {
  placesQueryOptions,
  weeksQueryOptions,
} from "../../../utils/data/common/queryOptions";

const schema = z.object({
  firstName: z.string().min(1, "Förnamn är obligatoriskt"),
  lastName: z.string().min(1, "Efternamn är obligatoriskt"),
  addressLine2: z.string(),
  addressLine3: z.string().max(10, "Max 10 tecken"),
  addressLine4: z.string(),
  place: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Område är obligatoriskt" }),
  week_ISO: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Inflyttad vecka är obligatoriskt",
    }),
});

const AddNewReceiver = () => {
  const navigate = useNavigate({ from: Route.fullPath });
  const [open, setOpen] = useState<boolean>(false);

  const receiverPlacesQuery = useQuery(placesQueryOptions());
  const receiverWeeksQuery = useQuery(weeksQueryOptions());

  const mutation = useCreateReceiverMutation();

  const { control, handleSubmit, reset } = useForm<AddNewReceiverCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      place: undefined,
      week_ISO: undefined,
    },
  });

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<AddNewReceiverCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/receivers/$receiverId",
            search: (old) => ({
              ...old,
            }),
            params: { receiverId: data.id },
          });
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button
          variant="filled"
          iconLeft={<Icon icon="add" size={20} />}
          onClick={handleAdd}
        >
          Lägg till
        </Button>
      </>

      <Dialog
        icon={<Icon icon={"emoji_people"} />}
        open={open}
        onClose={handleClose}
        headline="Lägg till inflyttad"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="flex gap-2">
            <div className="mb-4 grow">
              <div className="text-label-medium text-on-surface-variant">
                Förnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="firstName" control={control} />
              </div>
            </div>
            <div className="mb-4 grow">
              <div className="text-label-medium text-on-surface-variant">
                Efternamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="lastName" control={control} />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Adress
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="addressLine2" control={control} />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="mb-4 grow basis-1/5">
              <div className="text-label-medium text-on-surface-variant">
                Postnummer
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine3" control={control} />
              </div>
            </div>
            <div className="mb-4 grow">
              <div className="text-label-medium text-on-surface-variant">
                Postort
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine4" control={control} />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Område
            </div>
            <div className="pt-2">
              {!receiverPlacesQuery.isPending ? (
                <SelectWrapper
                  name="place"
                  control={control}
                  className="min-w-32"
                  options={receiverPlacesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Inflyttad vecka
            </div>
            <div className="pt-2">
              {!receiverWeeksQuery.isPending ? (
                <SelectWrapper
                  name="week_ISO"
                  control={control}
                  className="min-w-32"
                  options={receiverWeeksQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewReceiver;
