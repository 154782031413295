import { useCombobox, useMultipleSelection } from "downshift";
import React from "react";
import { cs } from "../../../utils";
import Icon from "../Icon";
import InputChip from "../InputChip";

export default function MultipleComboBox({
    selectedItems,
    setSelectedItems,
    items,
    placeholder,
  }) {
    const [inputValue, setInputValue] = React.useState("");
  
    function getFilteredItems(selectedItems, inputValue) {
      const lowerCasedInputValue = inputValue.toLowerCase();
      return items.filter(
        (item) =>
          !selectedItems.includes(item) &&
          item.name.toLowerCase().includes(lowerCasedInputValue)
      );
    }
  
    const filteredItems = React.useMemo(
      () => getFilteredItems(selectedItems, inputValue),
      [selectedItems, inputValue]
    );
  
    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
      useMultipleSelection({
        selectedItems,
        onStateChange({ selectedItems: newSelectedItems, type }) {
          switch (type) {
            case useMultipleSelection.stateChangeTypes
              .SelectedItemKeyDownBackspace:
            case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
            case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
            case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
              setSelectedItems(newSelectedItems);
              break;
            default:
              break;
          }
        },
      });
  
    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      items: filteredItems,
      itemToString(item) {
        return item ? item.name : "";
      },
      defaultHighlightedIndex: 0, // after selection, highlight the first item.
      selectedItem: null,
      inputValue,
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges;
  
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
              highlightedIndex: 0, // with the first option highlighted.
            };
          default:
            return changes;
        }
      },
      onStateChange({
        inputValue: newInputValue,
        type,
        selectedItem: newSelectedItem,
      }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (newSelectedItem) {
              setSelectedItems([...selectedItems, newSelectedItem]);
              setInputValue("");
            }
            break;
  
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(newInputValue);
  
            break;
          default:
            break;
        }
      },
    });
  
    return (
      <div className="w-full relative">
        <div className="flex flex-col gap-1">
          <div
            className={cs(
              "w-full text-body-medium outline-none bg-surface inline-flex gap-2 items-center flex-wrap p-2 px-3",
              "peer flex min-h-[48px] w-full grow items-center rounded-[8px] border-outline border-[1px]",
              selectedItems.length > 0 && "bg-secondary-container"
            )}
          >
            <div className="flex flex-wrap gap-2">
              {selectedItems.map(
                function renderSelectedItem(selectedItemForRender, index) {
                  return (
                    <InputChip
                      key={`selected-item-${index}`}
                      onClick={() => {
                        removeSelectedItem(selectedItemForRender);
                      }}
                    >
                      {selectedItemForRender.name}
                    </InputChip>
                  );
                }
              )}
            </div>
            <div className="flex items-center gap-0.5 grow">
              <input
                placeholder={placeholder}
                className="w-full grow outline-none placeholder:text-on-surface bg-inherit"
                {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
              />
              <button
                aria-label="toggle menu"
                className="pl-2 flex items-center"
                type="button"
                {...getToggleButtonProps()}
              >
                <Icon icon="arrow_drop_down" />
              </button>
            </div>
          </div>
        </div>
        <ul
          className={`absolute w-full bg-surface mt-[2px] shadow-mm-2 rounded-b-small max-h-80 overflow-y-auto p-0 z-10 ${
            !(isOpen && filteredItems.length) && "hidden"
          }`}
          {...getMenuProps()}
        >
          {isOpen &&
            filteredItems.map((item, index) => (
              <li
                className={cs(
                  highlightedIndex === index && "bg-surface-container-low",
                  selectedItem === item && "font-bold",
                  "py-2 px-3 shadow-sm flex flex-col cursor-pointer text-label-large"
                )}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                <span>{item.name}</span>
              </li>
            ))}
        </ul>
      </div>
    );
  }