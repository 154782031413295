import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";
import { CampaignBasisType } from "../../types";
import { NotFoundError } from "../../errors";

export const fetchCampaignBasis = async (id: number | string) => {
  const result = await api
    .get(`campaignbasis/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(
          `Kampanjunderlag med id "${id}" hittades inte!`
        );
      }
      throw err;
    });

  return result;
};

export async function updateCampaignBasis({
  id,
  ...updated
}: PickAsRequired<Partial<CampaignBasisType>, "id">) {
  const result = await api
    .put(`campaignbasis/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function createCampaignBasis({
  ...insert
}: Partial<CampaignBasisType>) {
  const result = await api
    .post(`campaignbasis`, {
      ...insert,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function createCampaign({ id }: { id: number }) {
  const result = await api
    .post(`campaignbasis/${id}/createcampaign`, null)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteCampaignBasis({ id }: { id: number }) {
  const result = await api
    .delete(`campaignbasis/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
