import api from "../../../../../utils/api";

export const fetchCompanyOffers = async (id: number | string) => {
  const companyOffers = await api
    .get(`companies/${id}/offers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyOffers;
};

export const fetchCompanyOffersProof = async (id: number | string) => {
  const companyOffers = await api
    .get(`companies/${id}/proofs`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyOffers;
};

export async function addOffer({ companyId }: { companyId: number | string }) {
  const response = await api
    .post(`companies/${companyId}/offers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function updateCompanyOfferProof({
  companyId,
  offers,
}: {
  companyId: number | string;
  offers: string[];
}) {
  const result = await api
    .put(`companies/${companyId}/proofs`, {
      companyId,
      offers,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
