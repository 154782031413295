import { createFileRoute, Link, Outlet } from "@tanstack/react-router";
import {
  Button,
  Divider,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import LogDialog from "../../../../offers_/$offerId/-components/LogDialog";
import EventDialog from "../../../../offers_/$offerId/-components/EventDialog";
import { offerQueryOptions } from "../../../../offers_/$offerId/-api/queryOptions";
import { useSuspenseQuery } from "@tanstack/react-query";
import StatusChange2 from "../../../../offers_/$offerId/-components/StatusChange2";

export const Route = createFileRoute("/companies/$companyId/offers/$offerId")({
  component: CompanyOfferComponent,
  loader: ({ context: { queryClient }, params: { offerId } }) => {
    queryClient.ensureQueryData(offerQueryOptions(offerId));
  },
});

function CompanyOfferComponent() {
  const params = Route.useParams();
  const offerQuery = useSuspenseQuery(offerQueryOptions(params.offerId));
  const offer = offerQuery.data;

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex mb-6 justify-start gap-2">
        <div className="flex-1 flex items-center gap-2">
          <Link
            to={"/companies/$companyId/offers"}
            params={{ companyId: params.companyId }}
            search={true}
            className="flex items-center"
          >
            <Button variant="tonal" iconLeft={<Icon icon="close" />}>
              Stäng
            </Button>
          </Link>
          <StatusChange2 offer={offer} />
        </div>

        <div className="flex items-center gap-2 ">
          
          <EventDialog title={`Konsumenthändelser ${offer?.number}`} />
          <LogDialog />
        </div>
      </div>
      <Outlet />
    </div>
  );
}
