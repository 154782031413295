import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { ProductCategoryType } from "../../../../utils/types";
import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import {
  offerActionsQueryOptions,
  offerTypesQueryOptions,
  UpdateProductCategoryCommandType,
  useUpdateProductCategoryMutation,
} from "../../../../utils/data/products";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import TextareaWrapper from "../../../../components/FormWrappers/TextareaWrapper";
import { useQuery } from "@tanstack/react-query";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  description: z.string().nullable(),
  colour: z.string().nullable(),
  offerType: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Typ är obligatoriskt",
    }),
  offerAction: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Händelse är obligatoriskt",
    }),
  active: z.boolean(),
  limitedCashing: z.boolean(),
  limitedConsumerDays: z.boolean(),
});

const EditDialog = ({ obj }: { obj: ProductCategoryType }) => {
  const [open, setOpen] = useState<boolean>(false);

  const offerActionsQuery = useQuery(offerActionsQueryOptions());
  const offerTypesQuery = useQuery(offerTypesQueryOptions());

  const mutation = useUpdateProductCategoryMutation(Number(obj.id));

  const { control, handleSubmit, reset, watch } =
    useForm<UpdateProductCategoryCommandType>({
      resolver: zodResolver(schema),
      defaultValues: {
        name: obj.name,
        description: obj.description,
        colour: obj.colour,
        offerAction: obj.offerAction,
        offerType: obj.offerType,
        active: obj.active,
        limitedCashing: obj.limitedCashing,
        limitedConsumerDays: obj.limitedConsumerDays,
      },
    });

  const colourChange = watch("colour");

  const onSubmit: SubmitHandler<UpdateProductCategoryCommandType> = async (
    data
  ) => {
    return mutation.mutate(
      {
        ...data,
        id: Number(obj.id),
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const handleEdit = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleEdit} icon={<Icon icon="edit" size={22} />} />
      <Dialog
        icon={<Icon icon="edit" size={22} />}
        open={open}
        onClose={handleClose}
        headline="Uppdatera produktkategori?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="mt-6">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Namn
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="name" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Beskrivning
            </div>
            <div className="pt-2">
              <TextareaWrapper name="description" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div
              className="text-label-medium text-on-surface-variant "
            >
              Bakgrundsfärg
            </div>

            <div className="pt-2">
              <TextFieldWrapper name="colour" control={control} style={{ background: colourChange }} />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">Typ</div>
            <div className="pt-2">
              {!offerTypesQuery.isPending ? (
                <SelectWrapper
                  name="offerType"
                  control={control}
                  className="min-w-32"
                  options={offerTypesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Händelse
            </div>
            <div className="pt-2">
              {!offerActionsQuery.isPending ? (
                <SelectWrapper
                  name="offerAction"
                  control={control}
                  className="min-w-32"
                  options={offerActionsQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              ) : null}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Begränsad inlösen
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="limitedCashing" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Tidsbegränsad per konsument
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="limitedConsumerDays" control={control} />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Aktiv
            </div>
            <div className="pt-2 flex w-full">
              <SwitchWrapper name="active" control={control} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDialog;
