import Menu2 from "@pnpm-monorepo/core/src/ui/components/Menu2";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { useSnackbar } from "notistack";
import { useUpdateRequestStatusMutation } from "../../../../../utils/data/request";
import { Icon } from "@pnpm-monorepo/core/src/ui/components";

const RequestStatusDropdown = ({ row, options }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateOfferStatus = useUpdateRequestStatusMutation(row.id);

  function handleStatusChange(statusId: number): void {
    updateOfferStatus.mutate(
      {
        id: row.id,
        statusId: statusId,
      },
      {
        onSuccess: () => {},
        onSettled: () => {
          enqueueSnackbar(`${row.ticketNumber} uppdaterad`);
        },
      }
    );
  }

  return (
    <Menu2.DropdownMenu>
      <Menu2.DropdownMenuTrigger
        className={cs(
          "outline-none items-center box-content cursor-pointer flex justify-center px-4 h-8 pr-2 hover:shadow-mm-1",
          "rounded-small border-[1px] transition-all duration-300 ease-in-out",
          "text-on-surface-container bg-surface hover:bg-surface-container-low border-outline"
        )}
      >
        <span
          className={cs(
            "text-ellipsis whitespace-nowrap select-none text-label-large",
            "pr-2"
          )}
        >
          {row.status.name}
        </span>
        <div
          className={cs(
            "flex items-center transition-all duration-300 ease-in-out w-[0px]",
            "w-[18px]"
          )}
        >
          <Icon
            icon="arrow_drop_down"
            size={21}
            className="text-on-surface-container"
          />
        </div>
      </Menu2.DropdownMenuTrigger>
      <Menu2.DropdownMenuContent
        align="center"
        forceMount
        className="min-w-40 m-2"
      >
        {options?.map((option) => (
          <Menu2.DropdownMenuItem
            key={option.id}
            active={option.id === row.status.id}
            disabled={option.id === row.status.id}
            onSelect={() => handleStatusChange(option.id)}
          >
            {option.name}
          </Menu2.DropdownMenuItem>
        ))}
      </Menu2.DropdownMenuContent>
    </Menu2.DropdownMenu>
  );
};

export default RequestStatusDropdown;
