import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useEffect, useMemo, useState } from "react";
import { Route } from "..";
import { useUpdateConsumerPasswordMutation } from "../-api/queryOptions";

import { ConsumerType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ChangeConsumerPasswordCommandType } from "../-api/consumers";
import { useSnackbar } from "notistack";

const schema = z.object({
  id: z.number(),
  userId: z.string(),
  password: z.string().min(1, { message: "Lösenord är obligatoriskt" }),
});

const ChangePassword = ({ consumer }: { consumer: ConsumerType }) => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [canChange, setCanChange] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const values = useMemo(() => {
    return { id: consumer.id, userId: consumer.userId, password: "" };
  }, [consumer]);

  const [password, setPassword] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [passwordLength] = useState<number>(8);
  const [useSymbols] = useState<boolean>(false);
  const [useNumbers] = useState<boolean>(true);
  const [useLowerCase] = useState<boolean>(true);
  const [useUpperCase] = useState<boolean>(true);

  useEffect(() => {
    if (consumer.userId.includes("auth0")) {
      setCanChange(false);
    } else {
      setCanChange(true);
    }
  }, [consumer.userId]);

  const mutation = useUpdateConsumerPasswordMutation(params.consumerId);

  const { control, handleSubmit, reset, watch, setValue, register } = useForm({
    resolver: zodResolver(schema),
    values,
  });

  const watchPassword = watch("password");

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const generatePassword = () => {
    let charset = "";
    let newPassword = "";

    if (useSymbols) charset += "!@#$%^&*()";
    if (useNumbers) charset += "0123456789";
    if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setPassword(newPassword);
    setValue("password", newPassword);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(password)
      .then(() => {
        setSuccessMessage("Lösenordet har kopierats till urklipp!");
        setTimeout(() => setSuccessMessage(""), 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    reset();
    mutation.reset();
  };

  const onSubmit: SubmitHandler<ChangeConsumerPasswordCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Lösenord uppdaterad");
          reset(data);
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button onClick={handleAdd}>Ändra</Button>
      </>

      <Dialog
        icon={<Icon icon={"password"} />}
        open={open}
        onClose={handleClose}
        headline="Ändra Lösenord"
        text={
          !canChange ? (
            <>
              UserId {consumer.userId} innehåller &quot;auth0&quot;. Lösenord
              kan därför inte ändras.
            </>
          ) : (
            successMessage
          )
        }
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Stäng
            </Button>
            {canChange && (
              <Button
                variant="filled"
                onClick={handleSubmit(onSubmit)}
                disabled={mutation.isPending}
              >
                Spara
              </Button>
            )}
          </>
        }
      >
        <div className="my-2">
          {mutation.error && (
            <div className="flex flex-col text-body-medium break-words my-2 mb-6 p-3 bg-error-container text-on-error-container">
              {Object.entries(mutation.error.response.data.errors).map(
                ([key, obj]) => (
                  <div key={key}>{obj[0]}</div>
                )
              )}
            </div>
          )}

          {/* {mutation.isSuccess && (
            <div className="flex text-body-medium mb-6 my-2 p-3 bg-tertiary-container text-on-tertiary-container">
              Lösenord uppdaterad
            </div>
          )} */}

          {canChange && (
            <>
              <div className="mb-4 flex gap-2 justify-end">
                {watchPassword ? (
                  <Button
                    onClick={handleCopyClick}
                    variant="outlined"
                    disabled={mutation.isPending}
                  >
                    Kopiera
                  </Button>
                ) : null}
                <Button
                  variant="tonal"
                  onClick={generatePassword}
                  disabled={!canChange || mutation.isPending}
                  iconLeft={<Icon icon={"auto_fix_high"} size={18} />}
                >
                  Generera lösenord
                </Button>
              </div>

              <div className="text-label-medium text-on-surface-variant">
                Lösenord
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="password"
                  control={control}
                  rightElement={
                    watchPassword ? (
                      <IconButton
                        onClick={() => {
                          setValue("password", "");
                          mutation.reset();
                        }}
                        size="small"
                        disabled={mutation.isPending}
                        icon={<Icon icon={"clear"} />}
                      />
                    ) : null
                  }
                />
                <input type="hidden" {...register("id")} />
                <input type="hidden" {...register("userId")} />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ChangePassword;
