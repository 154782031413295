import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  ErrorComponent,
  RouterProvider,
  createRouter,
} from "@tanstack/react-router";

import { ModalProvider } from "./components/Modals/ModalProvider";

// MSAL imports
import {
  MsalProvider,
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import SignIn from "./authentication/SignIn";

import { SnackbarProvider  } from "notistack";

// eslint-disable-next-line react-refresh/only-export-components
export const queryClient = new QueryClient();

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

const router = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

type AppProps = {
  instance: IPublicClientApplication;
};

function App({ instance }: AppProps) {
  return (
    <MsalProvider instance={instance}>
      <>
        <UnauthenticatedTemplate>
          <SignIn />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              maxSnack={3}
              preventDuplicate
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              // action={(snackbarId) => (
              //   <button onClick={() => closeSnackbar(snackbarId)}>
              //     Stäng
              //   </button>
              // )}
            >
              <RouterProvider router={router} />
            </SnackbarProvider>
            <ModalProvider />
          </QueryClientProvider>
        </AuthenticatedTemplate>
      </>
    </MsalProvider>
  );
}

export default App;
