import { MdDelete } from "@pnpm-monorepo/core/src/ui/icons";

import { Dialog, Button } from "@pnpm-monorepo/core/src/ui/components";
import { useDeleteConfirm } from "../../utils/useDeleteConfirm";

export const DeleteConfirmModal = () => {
  const confirm = useDeleteConfirm();

  return (
    <Dialog
      icon={<MdDelete />}
      open={confirm.isOpen}
      onClose={confirm.onClose}
      headline={confirm.headline}
      actions={
        <>
          <Button variant="text" onClick={confirm.onClose}>
            Avbryt
          </Button>
          <Button variant="filled" onClick={confirm.onConfirm}>
            Ok
          </Button>
        </>
      }
    ></Dialog>
  );
};
