"use client";
import * as React from "react";

import Badge from "../Badge";

import { cs } from "../../../utils";
import { NavigationRailItemProps } from "./NavigationRailItem.types";

const NavigationRailItem: React.FC<NavigationRailItemProps> = ({
  className,
  children,
  label,
  icon,
  badge,
  badgeContent,
  badgeSize,
  badgeInvisible,
  selected,
  onClick
}) => {
  const Icon = icon
    ? React.cloneElement(icon as React.ReactElement, {
        fill: selected ? "fill" : undefined,
        className: cs("", selected ? "" : ""),
      })
    : null;

  const IconRender = <span className="nav-rail__icon">{Icon}</span>;

  return (
    <div
      className={cs("group nav-rail__link ", selected && "active")}
      onClick={onClick}
    >
      {badge ? (
        <Badge
          size={badgeSize}
          badgeContent={badgeContent}
          invisible={badgeInvisible}
        >
          {IconRender}
        </Badge>
      ) : (
        IconRender
      )}

      {label && (
        <span className="text-label-medium tracking-[.1px] text-center block">
          {label}
        </span>
      )}
    </div>
  );
};

export default NavigationRailItem;
