import { CircularProgress } from "@pnpm-monorepo/core/src/ui/components";
import { useRouterState } from "@tanstack/react-router";

export default function RouterProgress() {
    const isLoading = useRouterState({ select: (s) => s.status === "pending" });
  
    return (
      <div
      className={`inline-block animate-spin transition ${
        isLoading ? "opacity-1 duration-300 " : "opacity-0 duration-500"
      }`}
    >
      <CircularProgress progress={0.3} />
    </div>
    );
  }