import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";

import {
  Button,
  Dialog,
  IconButton,
  Icon,
  Menu2,
} from "@pnpm-monorepo/core/src/ui/components";

import { useDeleteConsumerMutation } from "../-api/queryOptions";

import { ConsumerType } from "../../../../utils/types";

export function MoreMenu({ consumer }: { consumer: ConsumerType }) {
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Menu2.DropdownMenu>
        <Menu2.DropdownMenuTrigger asChild>
          <div>
            <IconButton icon={<Icon icon="more_vert" />} />
          </div>
        </Menu2.DropdownMenuTrigger>
        <Menu2.DropdownMenuContent
          align="end"
          alignOffset={8}
          forceMount
        >
          <Menu2.DropdownMenuItem onClick={handleDelete}>
            <Icon icon="delete" size={24} className="pr-[12px] text-on-surface-variant" />
            <span>Ta bort</span>
          </Menu2.DropdownMenuItem>
        </Menu2.DropdownMenuContent>
      </Menu2.DropdownMenu>
      <DeleteConsumer
        open={open}
        onClose={handleClose}
        consumerId={consumer.id}
      />
    </>
  );
}

export const DeleteConsumer = ({
  open,
  onClose,
  consumerId,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
  consumerId: number;
}) => {
  const navigate = useNavigate({ from: `/consumers/${consumerId}` });

  const deleteConsumer = useDeleteConsumerMutation(consumerId);

  const handleClose = (): void => {
    onClose(false);
  };

  const handleOk = (): void => {
    deleteConsumer.mutate(
      {
        id: consumerId,
      },
      {
        onSuccess: () => {
          navigate({
            to: "/consumers",
            search: (old) => ({
              ...old,
            }),
          });
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        icon={<Icon icon="delete" />}
        open={open}
        onClose={handleClose}
        headline="Ta bort konsument?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={deleteConsumer.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};
