import { useState } from "react";
import isEmpty from "lodash/isEmpty";

import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdArrowForward } from "@pnpm-monorepo/core/src/ui/icons";
import { Route } from "../route";
import {
  useQueryGetWorkOrder,
  useMutationCreateWorkorder,
} from "./ExternalGrus";
import { CampaignBasisType } from "../../../../../../utils/types";

const CreateGrusWorkorderModal = ({
  campaign,
}: {
  campaign: CampaignBasisType;
}) => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);

  const workorder = useQueryGetWorkOrder(params.campaignId);
  const mutation = useMutationCreateWorkorder(params.campaignId);

  function handleCreateWorkorder(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    const command = {
      contract: {
        id: campaign.contract.rusGuid,
        externalId: campaign.id,
        sellerId: campaign.contract.sellerRUSGuid,
        number: campaign.contract.number,
        customer: {
          id: campaign.contract.company.rusGuid,
          name: campaign.contract.company.name,
          organizationNumber: campaign.contract.company.organizationNumber,
          number: campaign.contract.company.number,
          chain: "",
          addressline1: campaign.contract.company.addressline1,
          addressline2: campaign.contract.company.addressline2,
          addressline3: campaign.contract.company.addressline3,
          addressline4: campaign.contract.company.addressline4,
          contact: campaign.contract.company.contact,
          phone: campaign.contract.company.phone,
          cellphone: campaign.contract.company.cellphone,
          email: campaign.contract.company.email,
        },
        workorder: {
          designOther: campaign.workOrderNote,
          campaignMonth: campaign.campaignMonth,
          basisText: campaign.basisText,
          assigned: campaign.assigned ? campaign.assigned.email : null,
        },
      },
    };

    mutation.mutate(command, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  }

  return (
    <>
      {workorder.isSuccess &&
      isEmpty(workorder.data) &&
      Boolean(campaign.workOrderNote) ? (
        <Button
          iconLeft={<MdArrowForward size={20} />}
          variant="filled"
          onClick={handleCreateWorkorder}
        >
          GRUS
        </Button>
      ) : null}
      <Dialog
        icon={<MdArrowForward />}
        open={open}
        onClose={handleClose}
        headline="Är du säker på att du vill skapa en arbetsorder utifrån valt
        kampanjunderlag?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={mutation.isPending}
            >
              Skapa arbetsorder
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default CreateGrusWorkorderModal;
