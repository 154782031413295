import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Map,
  MarkerProps,
} from "@pnpm-monorepo/core/src/ui/components";

import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";

import { AddressType } from "../../../../../../utils/types";
import { useUpdateAddressMutation } from "../-api/queryOptions";
import MapDialog from "./MapDialog";

const Address = ({ address }: { address: AddressType }) => {
  const [edit, setEdit] = React.useState(false);

  const mutation = useUpdateAddressMutation(address.id);

  const latitude = parseFloat(address.latitude.replace(",", "."));
  const longitude = parseFloat(address.longitude.replace(",", "."));

  const { control, handleSubmit, reset, setValue } = useForm<AddressType>({
    defaultValues: {
      id: address.id,
      contact: address.contact,
      email: address.email,
      cellphone: address.cellphone,
      phone: address.phone,
      webSite: address.webSite,
      webSiteDisplayText: address.webSiteDisplayText,
      instagram: address.instagram,
      instagramDisplayText: address.instagramDisplayText,
      facebook: address.facebook,
      facebookDisplayText: address.facebookDisplayText,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      addressLine4: address.addressLine4,
      longitude: address.longitude,
      latitude: address.latitude,
    },
  });

  const onSubmit: SubmitHandler<AddressType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  function onPlaceSelectOk(position: MarkerProps): void {
    setValue("latitude", position.lat.toString().replace(".", ","));
    setValue("longitude", position.lng.toString().replace(".", ","));
  }

  return (
    <>
      {!edit && (
        <Card
          image={
            <Map
              longitude={longitude}
              latitude={latitude}
              className="h-[200px]"
            />
          }
        >
          <CardHeader
            title={"Adress"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Adress
              </div>
              <div className="text-body-medium">
                {address.formattedAddress ? (
                  address.formattedAddress
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kontaktperson
              </div>
              <div className="text-body-medium">
                {address.contact ? (
                  address.contact
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Epost
              </div>
              <div className="text-body-medium">
                {address.email ? (
                  address.email
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Telefon
              </div>
              <div className="text-body-medium">
                {address.phone ? (
                  address.phone
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Mobil
              </div>
              <div className="text-body-medium">
                {address.cellphone ? (
                  address.cellphone
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida
              </div>
              <div className="text-body-medium">
                {address.webSite ? (
                  address.webSite
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida visningsnamn
              </div>
              <div className="text-body-medium">
                {address.webSiteDisplayText ? (
                  address.webSiteDisplayText
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Facebook
              </div>
              <div className="text-body-medium">
                {address.facebook ? (
                  address.facebook
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Facebook visningsnamn
              </div>
              <div className="text-body-medium">
                {address.facebookDisplayText ? (
                  address.facebookDisplayText
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Instagram
              </div>
              <div className="text-body-medium">
                {address.instagram ? (
                  address.instagram
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Instagram visningsnamn
              </div>
              <div className="text-body-medium">
                {address.instagramDisplayText ? (
                  address.instagramDisplayText
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Longitude/Latitude
              </div>
              <div className="text-body-medium">
                {address.longitude ? (
                  address.longitude
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
                /
                {address.latitude ? (
                  address.latitude
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Adress"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <CardContent className=" border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Adress 1
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine1" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Adress 2
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine2" control={control} />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Postnummer
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressLine3" control={control} />
                </div>
              </div>
              <div className="mb-6 grow">
                <div className="text-label-medium text-on-surface-variant">
                  Ort
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressLine4" control={control} />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kontaktperson
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="contact" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Epost
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="email" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Telefon
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="phone" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Mobil
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="cellphone" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="webSite" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida visningsnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="webSiteDisplayText" control={control} />
              </div>
            </div>

            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Facebook
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="facebook" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Facebook visningsnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="facebookDisplayText"
                  control={control}
                />
              </div>
            </div>

            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Instagram
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="instagram" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Instagram visningsnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="instagramDisplayText"
                  control={control}
                />
              </div>
            </div>

            <div className="flex gap-2">
              <div className="mb-6 grow">
                <div className="text-label-medium text-on-surface-variant">
                  Longitude
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="longitude" control={control} />
                </div>
              </div>
              <div className="mb-6 grow">
                <div className="text-label-medium text-on-surface-variant">
                  Latitude
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="latitude" control={control} />
                </div>
              </div>
            </div>
            <MapDialog
              longitude={longitude}
              latitude={latitude}
              onPlaceSelectOk={onPlaceSelectOk}
            />
          </CardContent>
          <div className="flex items-center justify-end gap-2 p-3">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default Address;
