import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../main";
import { loginRequest } from "../authentication/config";

const apiConfig = {
  baseURL: import.meta.env.VITE_API_URL,
};

const api = axios.create(apiConfig);

api.interceptors.request.use(async (config) => {
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers.Authorization = bearer;

    return config;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenRedirect(loginRequest)
        .then((resp: { accessToken: unknown; }) => {
          return resp.accessToken;
        })
        .catch((err: unknown) => {
          //routeToHome();??
          console.log(err);
        });
    }
  }
});

export default api