import React, { Suspense, useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
  Dialog,
} from "@pnpm-monorepo/core/src/ui/components";

import { MdAdd, MdDelete } from "@pnpm-monorepo/core/src/ui/icons";

import { FallbackImage, Image } from "../../../../../../utils/renderers";
import ImageUploader from "../../../../../../components/ImageUploader";
import { StoreType } from "../../../../../../utils/types";

import {
  useAddStoreImage,
  useDeleteStoreImage,
} from "../-api/queryOptions";

export default function StoreImage({ store }: { store: StoreType }) {
  const [edit, setEdit] = React.useState(false);
  const { images } = store;

  const logotype =
    images?.length && images.filter((x) => x.imageCategoryId === 1);
  const other = images?.length && images.filter((x) => x.imageCategoryId !== 1);

  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title="Bilder"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant mb-2">
                Logotyp
              </div>
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={logotype?.length ? logotype[0].url : ""}
                      className="object-cover max-h-[200px]"
                    />
                  </Suspense>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant mb-2">
                Butiksbild
              </div>
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={other?.length ? other[0].url : ""}
                      className="object-cover "
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Bilder"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />

          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant mb-2">
                Logotyp
              </div>
              <div className="flex justify-start gap-2 py-3">
                <AddImage storeId={store.id} categoryId={1} companyId={store.companyId} />
                {logotype?.length ? (
                  <RemoveImage storeId={store.id} imageId={logotype[0].id} />
                ) : null}
              </div>
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={logotype?.length ? logotype[0].url : ""}
                      className="object-cover max-h-[200px]"
                    />
                  </Suspense>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant mb-2">
                Butiksbild
              </div>
              <div className="flex justify-start gap-2 py-3">
                <AddImage storeId={store.id} categoryId={3} companyId={store.companyId} />
                {other?.length ? (
                  <RemoveImage storeId={store.id} imageId={other[0].id} />
                ) : null}
              </div>
              <div className="text-body-medium">
                <div className="overflow-hidden">
                  <Suspense
                    fallback={<FallbackImage className="object-cover" />}
                  >
                    <Image
                      url={other?.length ? other[0].url : ""}
                      className="object-cover "
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

const AddImage = ({
  storeId,
  companyId,
  categoryId,
}: {
  storeId: number;
  companyId: number;
  categoryId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useAddStoreImage(storeId, categoryId);

  function handleAddImage(): void {
    setOpen(true);
  }

  function handleOk(blob: Blob, image: File): void {
    const file = new File([blob], image.name, { type: image.type });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", storeId.toString());
    formData.append("companyId", companyId.toString());
    formData.append("categoryId", categoryId.toString());

    mutation.mutate(
      {
        storeId,
        formData,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdAdd size={18} />}
        onClick={handleAddImage}
      >
        Välj en bild
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        headline="Lägg till bild"
        icon={<MdAdd />}
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
          </>
        }
      >
        <div className="flex flex-col justify-center items-center">
          <ImageUploader
            onImageUpload={handleOk}
            isPending={mutation.isPending}
            thumbnail={true}
            useCrop={false}
          />
        </div>
      </Dialog>
    </>
  );
};

const RemoveImage = ({
  storeId,
  imageId,
}: {
  storeId: number;
  imageId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const mutation = useDeleteStoreImage(storeId, imageId);

  function handleDeleteImage(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    mutation.mutate(
      {
        storeId: storeId,
        imageId: imageId,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdDelete size={18} />}
        onClick={handleDeleteImage}
      >
        Ta bort befintlig bild
      </Button>
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort befintlig bild?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={mutation.isPending}
            >
              Ok
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};
