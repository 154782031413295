import { LinkBox } from "@pnpm-monorepo/core/src/ui/components";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/adminland/")({
  component: AdminlandIndexComponent,
});

function AdminlandIndexComponent() {
  return (
    <div className="flex-1 flex flex-col">
      <div className="p-6 py-4 flex flex-col gap-4 grow">
        <div className="flex flex-col w-full gap-4">
          <div className="flex items-center">
            <h1 className="flex-1 text-headline-large flex items-center">
              Adminland
            </h1>
          </div>
        </div>
        <div>
          <h2 className="text-title-medium">Grunddata</h2>
          <div className="my-2 text-body-medium flex gap-2 flex-wrap">
            <Link to="/adminland/offerservices">
              <LinkBox>Erbjudandetjänster</LinkBox>
            </Link>
            <Link to="/adminland/offerworths">
              <LinkBox>Erbjudandevärde</LinkBox>
            </Link>
            <Link to="/adminland/industries">
              <LinkBox>Kategori</LinkBox>
            </Link>
            <Link to="/adminland/chains">
              <LinkBox>Kedja</LinkBox>
            </Link>
            <Link to="/adminland/places">
              <LinkBox>Område</LinkBox>
            </Link>
            <Link to="/adminland/products">
              <LinkBox>Produkt</LinkBox>
            </Link>
            <Link to="/adminland/productcategories">
              <LinkBox>Produktkategori</LinkBox>
            </Link>
            <Link to="/adminland/systemsettings">
              <LinkBox>Systeminställningar</LinkBox>
            </Link>
            <Link to="/adminland/sellers">
              <LinkBox>Säljare</LinkBox>
            </Link>
          </div>
        </div>
        <div>
          <h2 className="text-title-medium">Avisering (push)</h2>
          <div className="my-2 text-body-medium flex gap-2">
            <Link to="/adminland/notifications/scheduler">
              <LinkBox>Aviseringsschema</LinkBox>
            </Link>
          </div>
        </div>
        <div>
          <h2 className="text-title-medium">Support</h2>
          <div className="my-2 text-body-medium flex gap-2">
            <Link to="/adminland/support/mobilkupongen">
              <LinkBox>mobilkupongen.se</LinkBox>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
