import {
  ErrorComponent,
  Link,
  Outlet,
  createFileRoute,
} from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import {
  IconButton,
  TopAppBar,
} from "@pnpm-monorepo/core/src/ui/components";

import { MdArrowBack } from "@pnpm-monorepo/core/src/ui/icons";
import {
  campignQueryActivityOptions,
  campignQueryOptions,
} from "./-api/queryOptions";
import DefaultPendingComponent from "../../../components/PendingComponent";
import { MoreMenu } from "./-components/MoreMenu";
import Tabs from "../../../components/Tabs";

export const Route = createFileRoute("/campaigns/$campaignId")({
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    queryClient.ensureQueryData(campignQueryOptions(campaignId));
    queryClient.ensureQueryData(campignQueryActivityOptions(campaignId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CampaignComponent,
});

function CampaignComponent() {
  const params = Route.useParams();
  const campaignQuery = useSuspenseQuery(
    campignQueryOptions(params.campaignId)
  );

  const campaign = campaignQuery.data;

  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        left={
          <Link to={"/campaigns"} search={true}>
            <IconButton icon={<MdArrowBack />} />
          </Link>
        }
        right={
          <div className="flex gap-2 items-center">
            <MoreMenu campaign={campaign} />
          </div>
        }
        headline={<>Kampanj {campaign.name}</>}
        className="sticky top-0 z-50 bg-surface"
      />
      <div className="flex-1 flex flex-col">
        <div className="p-6 flex flex-col gap-4 grow">
        <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "1",
                  header: "Inställningar",
                  to: "/campaigns/$campaignId",
                  params: { campaignId: params.campaignId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "2",
                  header: "Urval",
                  to: "/campaigns/$campaignId/selections",
                  params: { campaignId: params.campaignId },
                  search: true,
                  activeOptions: { exact: true },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>

          <div className="flex justify-center gap-2 mx-auto w-full lg:max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
