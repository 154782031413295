import { Search3Props } from "./Search3.types";
import IconButton from "../IconButton";
import Icon from "../Icon";
import { useCombobox } from "downshift";
import debounce, { cs } from "../../../utils";
import React, { useState } from "react";

function Search3<T>({
  onSelect,
  loading = false,
  placeholder = "Search...",
  renderOption,
  getOptionLabel,
  noResultsMessage = "No results found",
  getItems,
}: Search3Props<T>) {
  const [items, setItems] = useState<[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        getItems(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    fetch({ q: "" }, (results: T[]) => {
      if (active) {
        let newOptions: T[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setItems(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fetch]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    inputValue,
    getItemProps,
    selectedItem,
    setInputValue,
  } = useCombobox({
    items,
    onInputValueChange: ({ inputValue }) => {
      fetch({ q: inputValue }, (results: T[]) => {
        let newOptions: T[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setItems(newOptions);
      });
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onSelect(selectedItem);
      }
    },
    itemToString(item) {
      return "";
    },
  });

  return (
    <div className="">
      <div className="relative">
        <input
          {...getInputProps()}
          className={cs(
            "flex w-full bg-surface-container-low rounded-extra-large p-4 pl-6 pr-16 placeholder:text-on-surface-variant text-body-large outline-none h-[56px]",
            !!isOpen && "rounded-b-none"
          )}
          placeholder={placeholder}
        />
        {inputValue.length > 0 ? (
          <IconButton
            variant="standard"
            className="absolute right-5 top-2.5"
            icon={<Icon icon="clear" size={20} />}
            onClick={() => setInputValue("")}
          />
        ) : null}
        <ul
          {...getMenuProps()}
          className={cs(
            "!absolute z-10 bg-surface-container-low w-full max-h-60 overflow-y-auto rounded-b-extra-large text-label-large border-t-[1px] border-outline",
            // !(isOpen && items.length) && 'hidden'
            !isOpen && "hidden"
          )}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                key={index}
                {...getItemProps({
                  item,
                  index,
                })}
                className={cs(
                  highlightedIndex === index && "bg-surface-container-highest",
                  selectedItem === item && "font-bold",
                  "py-2 px-6 flex flex-col cursor-pointer"
                )}
              >
                {renderOption ? renderOption(item) : String(item)}
              </li>
            ))}
          {isOpen && inputValue.length > 0 && items.length === 0 && (
            <li className="py-2 px-6 flex flex-col">{noResultsMessage}</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Search3;
