import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchOfferEvents, removeOfferEvent } from "./events";
import { queryClient } from "../../../../../App";

export const offerEventsQueryOptions = (
  offerId: number | string,
  opts: {
    page: number;
    events: string;
    search?: string;
  },
  options?: object
) =>
  queryOptions({
    queryKey: ["offers", "events", offerId, opts],
    queryFn: () => fetchOfferEvents({ id: offerId, ...opts }),
    placeholderData: (previousData) => previousData,
    ...options
  });

export const useRemoveOfferEventMutation = (offerId: number) => {
  return useMutation({
    mutationKey: ["offers", "events", offerId],
    mutationFn: removeOfferEvent,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};
