import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  fetchProducts,
  fetchProductCategories,
  updateProduct,
  addProduct,
  deleteProduct,
  fetchOfferActions,
  fetchOfferTypes,
  updateProductCategory,
  deleteProductCategory,
  addProductCategory,
} from "./products";
import { queryClient } from "../../../App";

export const offerActionsQueryOptions = () =>
  queryOptions({
    queryKey: ["offers", "actions"],
    queryFn: () => fetchOfferActions(),
  });

export const offerTypesQueryOptions = () =>
  queryOptions({
    queryKey: ["offers", "types"],
    queryFn: () => fetchOfferTypes(),
  });

export const productsQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["products"],
    queryFn: () => fetchProducts(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const productCategoriesQueryOptions = (
  q: string = "",
  propertyName: string,
  onlyActive?: boolean
) =>
  queryOptions({
    queryKey: ["productcategories"],
    queryFn: () => fetchProductCategories(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];

          const matchesQuery =
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase());

          const isActive = onlyActive ? event.active === true : true;

          return matchesQuery && isActive;
        }
        return false;
      }),
  });

export const useUpdateProductMutation = (id: number) => {
  return useMutation({
    mutationKey: ["product", "update", id],
    mutationFn: updateProduct,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddProductMutation = () => {
  return useMutation({
    mutationKey: ["product", "add"],
    mutationFn: addProduct,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteProductMutation = () => {
  return useMutation({
    mutationKey: ["product", "delete"],
    mutationFn: deleteProduct,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useUpdateProductCategoryMutation = (id: number) => {
  return useMutation({
    mutationKey: ["productcategory", "update", id],
    mutationFn: updateProductCategory,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddProductCategoryMutation = () => {
  return useMutation({
    mutationKey: ["productcategory", "add"],
    mutationFn: addProductCategory,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteProductCategoryMutation = () => {
  return useMutation({
    mutationKey: ["productcategory", "delete"],
    mutationFn: deleteProductCategory,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};