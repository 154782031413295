import { Link } from "@tanstack/react-router";

import {
  Card,
  CardContent,
  CardHeader,
  LinkBox,
} from "@pnpm-monorepo/core/src/ui/components";

import { ReceiverType } from "../../../../utils/types";
import { SmallDateString } from "../../../../utils/renderers";

export default function Receiver({ receiver }: { receiver: ReceiverType }) {
  return (
    <Card className="">
      <CardHeader title={receiver.code + " - " + receiver.fullName} />
      <CardContent>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">Kod</div>
          <div className="text-body-medium">
            {receiver.code ? (
              <span>{receiver.code} </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Konsument
          </div>
          <div className="text-body-medium">
            {receiver.isConsumer ? (
              <> Ja, {" "}
                <LinkBox className="whitespace-normal">
                  <Link
                    to={`/consumers/$consumerId`}
                    params={{
                      consumerId: receiver.consumerId,
                    }}
                  >
                    {receiver.code}
                  </Link>
                </LinkBox>
              </>
            ) : (
              <span className="">Nej</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">Namn</div>
          <div className="text-body-medium">
            {receiver.fullName ? (
              <span>{receiver.fullName}</span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Konsumentdatum
          </div>
          <div className="text-body-medium">
            {receiver.createdAt ? (
              <span>
                <SmallDateString value={receiver.createdAt} />
              </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Område
          </div>
          <div className="text-body-medium">
            {receiver.place ? (
              <span>{receiver.place.name}</span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Inflyttade vecka
          </div>
          <div className="text-body-medium">
            {receiver.week_ISO ? (
              <span>{receiver.week_ISO}</span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            RUS Id
          </div>
          <div className="text-body-medium">
            {receiver.rusId ? (
              <span>{receiver.rusId}</span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Adress
          </div>
          <div className="text-body-medium">
            {receiver.formattedAddress &&
            receiver.formattedAddress !== ",  " ? (
              <span>{receiver.formattedAddress}</span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            POD Lidh
          </div>
          <div className="text-body-medium">
            {receiver.podLidhDate ? (
              <span>
                <SmallDateString value={receiver.podLidhDate} />
              </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            POD DRS
          </div>
          <div className="text-body-medium">
            {receiver.poddrsDate ? (
              <span>
                <SmallDateString value={receiver.poddrsDate} />
              </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Distribuerad Lidh
          </div>
          <div className="text-body-medium">
            {receiver.distributionLidhDate ? (
              <span>
                <SmallDateString value={receiver.distributionLidhDate} />
              </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-label-medium text-on-surface-variant">
            Distribuerad DRS
          </div>
          <div className="text-body-medium">
            {receiver.distributionDRSDate ? (
              <span>
                <SmallDateString value={receiver.distributionDRSDate} />
              </span>
            ) : (
              <span className="">&lt;Ej angivet&gt;</span>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
