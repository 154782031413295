import { createFileRoute, ErrorComponent } from "@tanstack/react-router";
import { OfferComponent } from "../../../../offers_/$offerId";
import DefaultPendingComponent from "../../../../../components/PendingComponent";
import { offerQueryOptions } from "../../../../offers_/$offerId/-api/queryOptions";

export const Route = createFileRoute("/companies/$companyId/offers/$offerId/")({
  loader: ({ context: { queryClient }, params: { offerId } }) => {
    queryClient.ensureQueryData(offerQueryOptions(offerId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: OfferComponent,
});
