import React from "react";

interface TextWithLineBreaksProps {
  text: string;
}

const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({ text }) => {
  return (
    <>
      {text
        ? text.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))
        : undefined}
    </>
  );
};

export default TextWithLineBreaks;
