import { queryOptions } from "@tanstack/react-query";
import { fetchCompanyContracts, fetchCompanyLogs } from "./company";

export const companyContractsQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["company", companyId, "contracts"],
    queryFn: () => fetchCompanyContracts(companyId),
  });

  export const companyLogsQueryOptions = (id: number | string) =>
    queryOptions({
      queryKey: ["company", id, "logs"],
      queryFn: () => fetchCompanyLogs(id),
    });