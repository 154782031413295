import React, { ChangeEvent } from "react";
import { SwitchProps } from "./Switch.types";
import { cs } from "../../../utils";

const Switch: React.FC<SwitchProps> = ({
  name,
  checked,
  onChange,
  invalid,
  error,
  disabled = false,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={cs(invalid && "border border-error")}>
      <label className="relative flex w-fit items-center">
        <input
          name={name}
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <div
          className={cs(
            "h-[32px] w-[52px] cursor-pointer rounded-full bg-surface-container-highest",
            "after:absolute after:left-[8px] after:top-[8px] after:h-[16px] after:w-[16px] after:rounded-full",
            "after:bg-white after:ring-primary after:transition-all hover:after:ring-[7px] focus:after:ring-[7px]",
            "peer-checked:bg-primary peer-checked:after:translate-x-[20px]"
          )}
        ></div>
      </label>

      {invalid && error && (
        <div className="text-label-medium my-2 text-error">{error.message}</div>
      )}
    </div>
  );
};

export default Switch;
