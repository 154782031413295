"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { LinkBoxProps } from "./LinkBox.types";

const LinkBox: React.FC<LinkBoxProps> = (props) => {
  const { className, children } = props;

  return (
    <span
      className={cs(
        "mx-[0px] my-[0px] w-fit gap-2 truncate text-body-small text-primary rounded-small py-[8px] underline-offset-2 underline",
        className
      )}
    >
      {children}
    </span>
  );
};

export default LinkBox;
