import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../utils/api";
import { NotFoundError } from "../../../../utils/errors";

export type UpdateReceiverWelcomeOfferCompanyCommandType = {
  id: number;
  receiverId: number;
  welcomeOfferActiveDate: Date | string | undefined;
};

export type CreateConsumerCommandType = {
  id: number;
  email: string;
};

export const fetchReceiverById = async (id: number | string) => {
  const recevier = await api
    .get(`receivers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Inflyttad med id "${id}" hittades inte!`);
      }
      throw err;
    });

  return recevier;
};

export const fetchReceiverAffiliations = async (id: number | string) => {
  const recevier = await api
    .get(`receivers/${id}/companies`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return recevier;
};

export const updateReceiverAffiliation = async ({
  id,
}: {
  id: number | string;
}) => {
  const result = await api
    .put(`/receivers/${id}/companies`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateReceiverAffiliationRow = async ({
  id,
  receiverId,
  ...updated
}: PickAsRequired<
  Partial<UpdateReceiverWelcomeOfferCompanyCommandType>,
  "id"
>) => {
  const result = await api
    .put(`/receivers/${receiverId}/companies/${id}`, { id, ...updated })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function createConsumer({ id, email }: CreateConsumerCommandType) {
  const result = await api
    .post(`receivers/${id}/consumer`, {
      id,
      email,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export const deleteReceiver = async ({ id }: { id: number }) => {
  const result = await api
    .delete(`receivers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
