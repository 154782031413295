import React from "react";

import { createFileRoute, useNavigate, Link } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { zodSearchValidator, fallback } from "@tanstack/router-zod-adapter";
import { useMediaQuery } from "usehooks-ts";
import { z } from "zod";
import debounce from "lodash/debounce";

import {
  Button,
  IconButton,
  NavigationDrawer,
  Search,
  Table,
  LinkBox,
  Tooltip,
  Icon,
  MultipleComboBox,
} from "@pnpm-monorepo/core/src/ui/components";

import {
  MdClose,
  MdTune,
  MdRefresh,
  MdInfo,
} from "@pnpm-monorepo/core/src/ui/icons";
import { cs } from "@pnpm-monorepo/core/src/utils";

import ErrorEmoji from "../../components/ErrorEmoji";
import RouterProgress from "../../components/RouterProgress";
import { SmallDateString, Number } from "../../utils/renderers";
import {
  CustomHistoryState,
  IndustryType,
  PlaceType,
  ProductType,
  SortOrder,
  StatusType,
} from "../../utils/types";
import { offersQueryOptions } from "./-api/queryOptions";
import { OffersSortBy } from "./-api/offers";
import Pagination from "../../components/Pagination";
import {
  industriesQueryOptions,
  offerStatusesQueryOptions,
  placesQueryOptions,
  productsQueryOptions,
} from "../../utils/data/common";

const offerSearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  sortBy: fallback(
    z.enum([
      "number",
      "companyName",
      "updatedAt",
      "productName",
      "statusName",
      "priority",
    ]),
    "updatedAt"
  ).default("updatedAt"),
  sortOrder: fallback(z.enum(["asc", "desc"]), "desc").default("desc"),
  q: fallback(z.string(), "").default(""),
  place: fallback(z.number().array(), []).default([]),
  status: fallback(z.number().array(), []).default([]),
  product: fallback(z.number().array(), []).default([]),
  category: fallback(z.number().array(), []).default([]),
});

export type OffersSearchParams = z.infer<typeof offerSearchSchema>;

export const Route = createFileRoute("/offers")({
  validateSearch: zodSearchValidator(offerSearchSchema),
  loaderDeps: ({ search }: { search: OffersSearchParams }) => ({
    q: search.q,
    page: search.page,
    sortBy: search.sortBy,
    sortOrder: search.sortOrder,
    place: search.place,
    status: search.status,
    product: search.product,
    category: search.category,
  }),
  loader: async (opts) => {
    const queryClient = opts.context.queryClient;
    await queryClient.cancelQueries("offers");
    return queryClient.ensureQueryData(offersQueryOptions(opts.deps));
  },
  component: OffersComponent,
});

function OffersComponent() {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const { page, sortBy, sortOrder, q, place, status, product, category } =
    Route.useSearch();
  const offersQuery = useSuspenseQuery(
    offersQueryOptions(Route.useLoaderDeps())
  );

  const offers = offersQuery.data;

  const placeQuery = useQuery(placesQueryOptions());
  const industriesQuery = useQuery(industriesQueryOptions());
  const productQuery = useQuery(productsQueryOptions());
  const offerStatusesQuery = useQuery(offerStatusesQueryOptions());
  const places = placeQuery.data;
  const industries = industriesQuery.data;
  const products = productQuery.data;
  const offerStatuses = offerStatusesQuery.data;

  const [searchDraft, setSearchDraft] = React.useState(q ?? "");
  const [selectedPlacesDraft, setSelectedPlacesDraft] = React.useState<
    PlaceType[]
  >([]);
  const [selectedStatusDraft, setSelectedStatusDraft] = React.useState<
    StatusType[]
  >([]);
  const [selectedProductDraft, setSelectedProductDraft] = React.useState<
    ProductType[]
  >([]);
  const [selectedIndustryDraft, setSelectedIndustryDraft] = React.useState<
    IndustryType[]
  >([]);

  React.useEffect(() => {
    if (places && place && place.length > 0) {
      const p = places.filter((x: PlaceType) => place?.includes(x.id));
      setSelectedPlacesDraft(p);
    }
  }, [place, places]);

  React.useEffect(() => {
    if (offerStatuses && status && status.length > 0) {
      const s = offerStatuses.filter((x: StatusType) => status?.includes(x.id));
      setSelectedStatusDraft(s);
    }
  }, [status, offerStatuses]);

  React.useEffect(() => {
    if (products && product && product.length > 0) {
      const p = products.filter((x: ProductType) => product?.includes(x.id));
      setSelectedProductDraft(p);
    }
  }, [product, products]);

  React.useEffect(() => {
    if (industries && category && category.length > 0) {
      const p = industries.filter((x: IndustryType) =>
        category?.includes(x.id)
      );
      setSelectedIndustryDraft(p);
    }
  }, [category, industries]);

  const handleSearch = (v: string) => {
    setSearchDraft(v);
  };

  React.useEffect(() => {
    const debouncedNavigate = debounce(() => {
      navigate({
        search: (old) => ({
          ...old,
          q: searchDraft,
          page: 1,
        }),
        replace: true,
      });
    }, 300);

    debouncedNavigate();

    return () => debouncedNavigate.cancel();
  }, [searchDraft, navigate]);

  const setSortBy = (sortBy: OffersSortBy) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortBy,
        };
      },
      replace: true,
    });

  const setSortOrder = (sortOrder: SortOrder) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortOrder,
        };
      },
      replace: true,
    });

  const setPage = (page: number) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          page,
        };
      },
      replace: true,
    });

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          place: selectedPlacesDraft.map((x: PlaceType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedPlacesDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          status: selectedStatusDraft.map((x: StatusType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedStatusDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          product: selectedProductDraft.map((x: ProductType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedProductDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          category: selectedIndustryDraft.map((x: IndustryType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedIndustryDraft]);

  function handleOpenFilter(): void {
    setOpen(!open);
  }

  function handleClearFilter(): void {
    setPage(1);
    setSelectedPlacesDraft([]);
    setSelectedStatusDraft([]);
    setSelectedProductDraft([]);
    setSelectedIndustryDraft([]);
  }

  function handleClearAllFilter(): void {
    setPage(1);
    setSearchDraft("");
    setSelectedPlacesDraft([]);
    setSelectedStatusDraft([]);
    setSelectedProductDraft([]);
    setSelectedIndustryDraft([]);
  }

  return (
    <div>
      <NavigationDrawer
        open={open}
        variant={matches ? "standard" : "modal"}
        onClose={handleOpenFilter}
      >
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-6 py-5 block mb-16">
            <div className="flex items-center justify-end gap-1">
              <Button
                disabled={
                  selectedPlacesDraft.length === 0 &&
                  selectedStatusDraft.length === 0 &&
                  selectedProductDraft.length === 0 &&
                  selectedIndustryDraft.length === 0 &&
                  searchDraft.length === 0
                }
                iconLeft={<MdRefresh size={24} />}
                onClick={handleClearAllFilter}
              >
                Återställ alla
              </Button>
              <IconButton icon={<MdClose />} onClick={handleOpenFilter} />
            </div>

            <div className="text-title-medium mt-2 py-1">Filter</div>

            <div className="my-8">
              <div className="my-4">
                {places && (
                  <MultipleComboBox
                    items={places}
                    selectedItems={selectedPlacesDraft}
                    setSelectedItems={setSelectedPlacesDraft}
                    placeholder={"Område"}
                  />
                )}
              </div>

              <div className="my-4">
                {offerStatuses && (
                  <MultipleComboBox
                    items={offerStatuses}
                    selectedItems={selectedStatusDraft}
                    setSelectedItems={setSelectedStatusDraft}
                    placeholder={"Status"}
                  />
                )}
              </div>

              <div className="my-4">
                {products && (
                  <MultipleComboBox
                    items={products}
                    selectedItems={selectedProductDraft}
                    setSelectedItems={setSelectedProductDraft}
                    placeholder={"Produkt"}
                  />
                )}
              </div>

              <div className="my-4">
                {industries && (
                  <MultipleComboBox
                    items={industries}
                    selectedItems={selectedIndustryDraft}
                    setSelectedItems={setSelectedIndustryDraft}
                    placeholder={"Kategori"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </NavigationDrawer>
      <div
        id="offers-content"
        className={cs(
          "relative transition-all",
          open === true && matches ? "ml-80" : "ml-0"
        )}
      >
        <div className="z-10 flex min-w-[300px] flex-col text-on-surface sticky top-0 bg-surface">
          <div className=" block z-50 pb-3 pt-4 px-4">
            <Search
              id="offer-search"
              name="search"
              placeholder="Sök erbjudanden"
              clearOnEscape={true}
              onChange={handleSearch}
              defaultValue={searchDraft}
            />

            <div className="mt-4 mb-2 flex gap-2 items-center">
              <Button
                variant={open ? "filled" : "outlined"}
                iconLeft={open ? <MdClose size={20} /> : <MdTune size={20} />}
                onClick={handleOpenFilter}
              >
                Filter
              </Button>

              {selectedPlacesDraft.length !== 0 ||
              selectedStatusDraft.length !== 0 ||
              selectedProductDraft.length !== 0 ||
              selectedIndustryDraft.length !== 0 ? (
                <Button onClick={handleClearFilter}>Rensa allt</Button>
              ) : (
                <></>
              )}

              <RouterProgress />
            </div>
          </div>
        </div>

        {offers?.count === 0 ? (
          <ErrorEmoji message="Hittade inga erbjudanden.">
            <Button
              variant="outlined"
              className="mt-4"
              onClick={handleClearFilter}
            >
              Rensa filter och försök igen
            </Button>
          </ErrorEmoji>
        ) : (
          <>
            <Pagination
              maxVisiblePages={10}
              totalItems={offers.count}
              itemsPerPage={25}
              currentPage={page}
              onPageChange={setPage}
            />
            <div className="flex flex-col w-full my-6 px-4 text-body-medium">
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("number");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Nummer{" "}
                          {sortBy === "number" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("companyName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Företag{" "}
                          {sortBy === "companyName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("productName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Produkt{" "}
                          {sortBy === "productName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>Erbjudande</Table.HeadCell>
                      <Table.HeadCell justify="justify-end">
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("priority");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Prioritet{" "}
                          {sortBy === "priority" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell justify="justify-end">
                        Öppnade
                      </Table.HeadCell>
                      <Table.HeadCell justify="justify-end">
                        Sparade
                      </Table.HeadCell>
                      <Table.HeadCell justify="justify-end">
                        Inlösta
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("statusName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Status{" "}
                          {sortBy === "statusName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("updatedAt");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Uppdaterad{" "}
                          {sortBy === "updatedAt" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell></Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {offers?.results?.map((offer) => {
                      return (
                        <Table.Row
                          key={offer.id}
                          className={cs(
                            offer.notVisibleInAppText &&
                              offer.statusId !== 60 &&
                              "text-on-error-container bg-error-container"
                          )}
                        >
                          <Table.RowCell>
                            <Link
                              to={`/offers/$offerId`}
                              params={(prev) => ({
                                ...prev,
                                offerId: offer.id,
                              })}
                              state={
                                {
                                  from: `/offers/${offer.id}`,
                                } as CustomHistoryState
                              }
                              search={true}
                            >
                              <LinkBox>{offer.number}</LinkBox>
                            </Link>
                          </Table.RowCell>
                          <Table.RowCell>
                            <Link
                              to={`/companies/$companyId`}
                              params={(prev) => ({
                                ...prev,
                                companyId: offer.companyId,
                              })}
                              search={true}
                            >
                              <LinkBox>{offer.companyName}</LinkBox>
                            </Link>
                          </Table.RowCell>
                          <Table.RowCell>{offer.productName}</Table.RowCell>
                          <Table.RowCell>
                            {offer.offerWorthText} {offer.offerPrepositionText}{" "}
                            {offer.offerServiceText}
                          </Table.RowCell>
                          <Table.RowCell justify="justify-end">
                            <Number>{offer.priority}</Number>
                          </Table.RowCell>
                          <Table.RowCell justify="justify-end">
                            <Number>{offer.offerViewCount}</Number>
                          </Table.RowCell>
                          <Table.RowCell justify="justify-end">
                            <Number>{offer.offerSavedCount}</Number>
                          </Table.RowCell>
                          <Table.RowCell justify="justify-end">
                            <Number>{offer.offerUsedCount}</Number>
                          </Table.RowCell>
                          <Table.RowCell>{offer.statusName}</Table.RowCell>
                          <Table.RowCell>
                            <SmallDateString value={offer.updatedAt} />
                          </Table.RowCell>
                          <Table.RowCell>
                            {offer.notVisibleInAppText && (
                              <Tooltip content={offer.notVisibleInAppText}>
                                <MdInfo color="text-primary" />
                              </Tooltip>
                            )}
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
              <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
