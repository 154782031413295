import { NumberInput } from "@pnpm-monorepo/core/src/ui/components";
import { useController, FieldValues, Control } from "react-hook-form";

interface NumberInputWrapperProps {
    name: string;
    control: Control<FieldValues>;
    defaultValue?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
  }
  
  const NumberInputWrapper: React.FC<NumberInputWrapperProps> = ({
    name,
    control,
    defaultValue,
    min,
    max,
    disabled,
  }) => {
    const {
      field: { onChange, onBlur, value },
      fieldState: { invalid, error },
    } = useController({
      name,
      control,
      defaultValue: defaultValue || min,
      rules: { required: "This field is required" },
    });
  
    const handleChange = (newValue: number) => {
      onChange(newValue);
    };
  
    return (
      <NumberInput
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        min={min}
        max={max}
        disabled={disabled}
        invalid={invalid}
        error={error}
      />
    );
  };
  
  export default NumberInputWrapper;