import api from "../../../utils/api";
import { SortOrder, StatusType } from "../../../utils//types";

export type CampaignsSortBy = "name" | "campaignDate" | "statusName";

export type AddNewCampaignCommandType = {
  name: string;
  campaignDate: string;
};

export const fetchCampaigns = async ({
  q,
  page,
  sortBy,
  sortOrder,
  status,
}: {
  q?: string;
  page?: number;
  sortBy?: CampaignsSortBy;
  sortOrder?: SortOrder;
  status?: StatusType[];
} = {}) => {
  const campaigns = await api
    .get(`campaigns`, {
      params: {
        search: q,
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        status: status?.join(","),
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return campaigns;
};

export async function createCampaign({
  ...data
}: Partial<AddNewCampaignCommandType>) {
  const result = await api
    .post(`campaigns`, {
      ...data,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}
