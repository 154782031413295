import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  FilterChip,
  Icon,
  IconButton,
  Search,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import { Route } from "../route";
import { useQuery } from "@tanstack/react-query";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { offerEventsQueryOptions } from "../events/-api/queryOptions";
import { useMsal } from "@azure/msal-react";
import { OfferEventType } from "../../../../utils/types";
import { NoWrap, SmallDateTimeString } from "../../../../utils/renderers";
import { EventActions } from "../events/-components/EventActions";

type OfferEventResultType = {
  results: OfferEventType[];
  count: number;
  hasMore: boolean;
};

function Result({
  events,
  roles,
}: {
  events: OfferEventResultType;
  roles: string[];
}) {
  const resultCount = (
    <div id="offer-events-result" className="flex w-full mb-2 px-4">
      <div className="text-label-medium grow">
        Hittade {events?.count} händelser
        {events?.count > 50 ? <>, visar de 50 senaste.</> : null}
      </div>
    </div>
  );

  return events?.results.length ? (
    <>
      {resultCount}
      <Table.Wrapper>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Tidpunkt</Table.HeadCell>
              <Table.HeadCell>Händelse</Table.HeadCell>
              <Table.HeadCell>Område</Table.HeadCell>
              <Table.HeadCell>Butik</Table.HeadCell>
              {roles.includes("Mobilkupongen.Admin") ||
              roles.includes("Mobilkupongen.Writer") ? (
                <>
                  <Table.HeadCell className="border-r-0">
                    Användare
                  </Table.HeadCell>
                  <Table.HeadCell className="sticky right-0 bg-surface-container-highest shadow-sticky-left"></Table.HeadCell>
                </>
              ) : undefined}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {events.results.map((event, index: number) => {
              return (
                <Table.Row key={index}>
                  <Table.RowCell>
                    <NoWrap>
                      <SmallDateTimeString value={event.createdAt} />
                    </NoWrap>
                  </Table.RowCell>
                  <Table.RowCell>{event.event}</Table.RowCell>
                  <Table.RowCell>{event.placeName}</Table.RowCell>
                  <Table.RowCell>{event.storeName}</Table.RowCell>
                  {roles.includes("Mobilkupongen.Admin") ||
                  roles.includes("Mobilkupongen.Writer") ? (
                    <>
                      <Table.RowCell className="border-r-0">
                        {event.consumerEMail}
                      </Table.RowCell>
                      <Table.RowCell
                        justify="justify-center"
                        className=" sticky right-0 bg-surface overflow-hidden shadow-sticky-left w-16"
                      >
                        {event.event === "Inlöst" ? (
                          <>
                            <EventActions event={event} />
                          </>
                        ) : null}
                      </Table.RowCell>
                    </>
                  ) : undefined}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </Table.Wrapper>
    </>
  ) : (
    <div className="p-3 bg-tertiary-container text-center rounded-large">
      Det finns inga konsumenthändelser.
    </div>
  );
}

const EventDialog = ({ title }: { title: string }) => {
  const [open, setOpen] = useState<boolean>(false);

  const [filter, setFilter] = useState(["Inlöst", "Sparat", "Öppnat"]);
  const [search, setSearch] = useState("");
  const params = Route.useParams();

  const [roles, setRoles] = useState<string[]>([]);
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const idTokenClaims = account.idTokenClaims;
      const userRoles = idTokenClaims?.roles || [];
      setRoles(userRoles);
    }
  }, [accounts]);

  //const { page, events } = Route.useSearch();

  const offerEventsQuery = useQuery(
    offerEventsQueryOptions(params.offerId, {
      page: 1,
      events: filter.join(","),
      search: search,
    })
  );

  function handleFilterClick(v: string): void {
    // Check if the item already exists in the array
    const index = filter.findIndex((i) => i === v);

    if (index === -1) {
      // Item does not exist, add it
      setFilter([...filter, v]);
    } else {
      // Item exists, remove it
      const updatedItems = [...filter];
      updatedItems.splice(index, 1);
      setFilter(updatedItems);
    }
  }

  const handleSearch = (v: string) => {
    setSearch(v);
  };

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="text"
        iconLeft={<Icon icon="people" size={20} />}
        onClick={handleOpen}
        className="lg:flex hidden"
      >
        Konsumenthändelser
      </Button>
      <IconButton
        icon={<Icon icon="people" />}
        className="lg:hidden flex"
        variant="standard"
        onClick={handleOpen}
      />
      <Dialog
        icon={<Icon icon="people" />}
        open={open}
        onClose={handleClose}
        headline={title}
        fullScreen
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Stäng
            </Button>
          </>
        }
      >
        <div className="mt-4">
          <div className="flex flex-col lg:flex-row w-full gap-2">
            <div className="flex flex-col gap-4 grow">
              <div className="flex w-full justify-start lg:justify-center items-center">
                <div className="w-full lg:max-w-2xl">
                  <Search
                    size="small"
                    id="offer-search"
                    name="search"
                    placeholder="Sök efter användare"
                    clearOnEscape={true}
                    onChange={handleSearch}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="flex gap-2 justify-start lg:justify-center items-center">
                <FilterChip
                  onClick={() => handleFilterClick("Öppnat")}
                  selected={filter.includes("Öppnat")}
                >
                  Öppnade
                </FilterChip>
                <FilterChip
                  onClick={() => handleFilterClick("Sparat")}
                  selected={filter.includes("Sparat")}
                >
                  Sparade
                </FilterChip>
                <FilterChip
                  onClick={() => handleFilterClick("Inlöst")}
                  selected={filter.includes("Inlöst")}
                >
                  Inlösta
                </FilterChip>
              </div>
              <div className="flex flex-col w-full my-4 text-body-medium">
                {offerEventsQuery.isPending ? (
                  <DefaultPendingComponent />
                ) : (
                  <>
                    <Result events={offerEventsQuery?.data} roles={roles} />
                  </>
                )}
                <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EventDialog;
