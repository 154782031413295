import { queryOptions, useMutation } from "@tanstack/react-query";
import { addChain, deleteChain, fetchChains, updateChain } from "./chains";
import { queryClient } from "../../../App";

export const chainsQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["chains"],
    queryFn: () => fetchChains(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

export const useUpdateChainMutation = (id: number) => {
  return useMutation({
    mutationKey: ["chain", "update", id],
    mutationFn: updateChain,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useAddChainMutation = () => {
  return useMutation({
    mutationKey: ["chain", "add"],
    mutationFn: addChain,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};

export const useDeleteChainMutation = () => {
  return useMutation({
    mutationKey: ["chain", "delete"],
    mutationFn: deleteChain,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
