import React from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { AddressType, CompanyType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";

import { SubmitHandler, useForm } from "react-hook-form";
import { Route } from "../route";
import { useUpdateCompanyAddressMutation } from "../-api/queryOptions";

export default function Address({ company }: { company: CompanyType }) {
  const [edit, setEdit] = React.useState(false);
  const params = Route.useParams();
  const { address } = company;

  const mutation = useUpdateCompanyAddressMutation(params.companyId);

  const { control, handleSubmit, reset } = useForm<AddressType>({
    defaultValues: {
      contact: address.contact,
      email: address.email,
      cellphone: address.cellphone,
      phone: address.phone,
      webSite: address.webSite,
      webSiteDisplayText: address.webSiteDisplayText,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      addressLine4: address.addressLine4,
      longitude: address.longitude,
      latitude: address.latitude,
    },
  });

  const onSubmit: SubmitHandler<AddressType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        id: address.id,
        companyId: params.companyId,
      },
      {
        onSuccess: () => {
          setEdit(false)
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title="Adress"
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />

          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Kontaktperson
              </div>
              <div className="text-body-medium">
                {address.contact ? (
                  address.contact
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Epost
              </div>
              <div className="text-body-medium">
                {address.email ? (
                  address.email
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Telefon
              </div>
              <div className="text-body-medium">
                {address.phone ? (
                  address.phone
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Mobil
              </div>
              <div className="text-body-medium">
                {address.cellphone ? (
                  address.cellphone
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida
              </div>
              <div className="text-body-medium">
                {address.webSite ? (
                  address.webSite
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida visningsnamn
              </div>
              <div className="text-body-medium">
                {address.webSiteDisplayText ? (
                  address.webSiteDisplayText
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Adress
              </div>
              <div className="text-body-medium">
                {address.formattedAddress ? (
                  address.formattedAddress
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={"Adress"}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <CardContent className="border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Kontaktperson
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="contact" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                E-post
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="email" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Telefon
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="phone" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Mobil
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="cellphone" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="webSite" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Hemsida visningsnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="webSiteDisplayText" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Adress
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine1" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                C/o
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="addressLine2" control={control} />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Postnummer
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressLine3" control={control} />
                </div>
              </div>
              <div className="mb-6 grow">
                <div className="text-label-medium text-on-surface-variant">
                  Ort
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="addressLine4" control={control} />
                </div>
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
}
