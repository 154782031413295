import api from "../../api";

export async function fetchStoreLogs(storeId: number | string) {
    const result = await api
      .get(`stores/${storeId}/logs`)
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  }