import {
  Button,
  Icon,
  Dialog,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { Route } from "..";
import { useDeleteOffer } from "../-api/queryOptions";
import { OffersSearchParams } from "../../../offers/route";

const DeleteDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const params = Route.useParams();
  const router = useRouter();
  const navigate = useNavigate({ from: Route.fullPath });

  const deleteMutation = useDeleteOffer(parseInt(params.offerId));

  const goBack = () => {
    const from = router.latestLocation.pathname;

    if (from.startsWith("/companies/")) {
      const id = from.split("/")[2]; // Extract the company ID
      navigate({
        to: "/companies/$companyId/offers",
        params: { companyId: id },
      });
    } else if (from.startsWith("/offers/")) {
      navigate({
        to: "/offers",
        search: (old: Partial<OffersSearchParams> = {}) => ({
          page: old.page ?? 1,
          sortBy: old.sortBy ?? "updatedAt",
          sortOrder: old.sortOrder ?? "desc",
          q: old.q ?? "",
          status: old.status,
          place: old.place,
          product: old.product,
          category: old.category,
        }),
      });
    }
  };

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation(); // Stop the event from bubbling up
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOk = (): void => {
    deleteMutation.mutate(
      {
        offerId: parseInt(params.offerId),
      },
      {
        onSuccess: () => {
          goBack();
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        iconLeft={<Icon icon="delete" size={20} />}
        variant="filled"
        className="bg-error text-on-error lg:flex hidden"
      >
        Ta bort
      </Button>

      <IconButton
        icon={<Icon icon="delete" />}
        className="lg:hidden flex bg-error text-on-error "
        variant="filled"
        onClick={handleOpen}
      />

      <Dialog
        icon={<Icon icon="delete" />}
        open={open}
        onClose={handleClose}
        headline="Ta bort erbjudande?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={deleteMutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={deleteMutation.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default DeleteDialog;
