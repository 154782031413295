import {
  ErrorComponent,
  Link,
  Outlet,
  createFileRoute,
} from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { IconButton, TopAppBar } from "@pnpm-monorepo/core/src/ui/components";

import { MdArrowBack } from "@pnpm-monorepo/core/src/ui/icons";
import DefaultPendingComponent from "../../../components/PendingComponent";
import {
  receiverAffiliationsQuery,
  receiverQueryOptions,
} from "./-api/queryOptions";

import { MoreMenu } from "./-components/MoreMenu";
import Tabs from "../../../components/Tabs";

export const Route = createFileRoute("/receivers/$receiverId")({
  loader: ({ context: { queryClient }, params: { receiverId } }) => {
    queryClient.ensureQueryData(receiverQueryOptions(receiverId));
    queryClient.ensureQueryData(receiverAffiliationsQuery(receiverId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: ReceiverComponent,
});

function ReceiverComponent() {
  const params = Route.useParams();
  const receiverQuery = useSuspenseQuery(
    receiverQueryOptions(params.receiverId)
  );
  const receiver = receiverQuery.data;

  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        left={
          <Link to={"/receivers"} search={true}>
            <IconButton icon={<MdArrowBack />} />
          </Link>
        }
        right={
          <div className="flex gap-2 items-center">
            <MoreMenu receiver={receiver} />
          </div>
        }
        headline={<>Inflyttad {receiver.code}</>}
        className="sticky top-0 z-50 bg-surface"
      />

      <div className="flex-1 flex flex-col lg:flex-row">
        <div className="p-6 flex flex-col gap-4 grow">
          <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "1",
                  header: "Allmänt",
                  to: "/receivers/$receiverId",
                  params: { receiverId: params.receiverId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "2",
                  header: "Företagsknytning",
                  to: "/receivers/$receiverId/affiliations",
                  params: { receiverId: params.receiverId },
                  search: true,
                  activeOptions: { exact: true },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>

          <div className="flex justify-center gap-4 mx-auto w-full lg:max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
