"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { CardHeaderProps } from "./CardHeader.types";

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  const { className, title, actions } = props;

  return (
    <div className={cs("flex w-full items-start gap-2 p-3 py-0 pt-4 pb-3", className)}>
      <div className="text-title-large grow">{title}</div>
      {actions && <div className="ml-4 flex gap-2">{actions}</div>}
    </div>
  );
};

export default CardHeader;
