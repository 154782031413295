import { useState } from "react";

import {
  Button,
  Dialog,
  IconButton,
  Icon,
} from "@pnpm-monorepo/core/src/ui/components";

import { useRemoveOfferEventMutation } from "../-api/queryOptions";

import { MdDelete } from "@pnpm-monorepo/core/src/ui/icons";

import { OfferEventType } from "../../../../../utils/types";

export function EventActions({ event }: { event: OfferEventType }) {
  return (
    <>
      {event && event.event === "Inlöst" ? <DeleteEvent event={event} /> : null}
    </>
  );
}

const DeleteEvent = ({ event }: { event: OfferEventType }) => {
  const [open, setOpen] = useState<boolean>(false);
  const removeOfferEvent = useRemoveOfferEventMutation(event.offerId);

  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    removeOfferEvent.mutate(
      {
        ...event,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <IconButton
        onClick={handleDelete}
        icon={<Icon icon="delete" size={22} />}
      />
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort händelse?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={removeOfferEvent.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};
