import api from "../../../../../utils/api";

export const fetchConsumerOffers = async (id: number | string) => {
  const result = await api
    .get(`consumers/${id}/offers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};