import {
    Button,
    Dialog,
    Icon,
    IconButton,
  } from "@pnpm-monorepo/core/src/ui/components";
  import { useState } from "react";
import { useDeleteSellerMutation } from "../../../../utils/data/seller";
import { SellerType } from "../../../../utils/types";

  
  const DeleteDialog = ({ obj }: { obj: SellerType }) => {
    const [open, setOpen] = useState<boolean>(false);
  
    const mutation = useDeleteSellerMutation();
  
    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation(); // Stop the event from bubbling up
      setOpen(true);
    };
  
    const handleClose = (): void => {
      setOpen(false);
    };
    const handleOk = (): void => {
        mutation.mutate(
        {
          id: obj.id,
        },
        {
          onSuccess: () => {
            setOpen(false);
          },
        }
      );
    };
  
    return (
      <div>
        <IconButton icon={<Icon icon="delete" />} onClick={handleDelete} />
  
        <Dialog
          icon={<Icon icon={"delete"} />}
          open={open}
          onClose={handleClose}
          headline="Vill du ta bort säljare?"
          text={obj.name}
          actions={
            <>
              <Button
                variant="text"
                onClick={handleClose}
                disabled={mutation.isPending}
              >
                Avbryt
              </Button>
              <Button
                variant="filled"
                onClick={handleOk}
                disabled={mutation.isPending}
              >
                Ta bort
              </Button>
            </>
          }
        ></Dialog>
      </div>
    );
  };
  
  export default DeleteDialog;
  