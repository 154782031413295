import { create } from "zustand";

type DeleteConfirmStore = {
  headline: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: (() => void) | undefined;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDeleteConfirm = create<DeleteConfirmStore>((set) => ({
  headline: "",
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false, onConfirm : undefined }),
  onConfirm: undefined
}));

export const onDeleteConfirm = (headline: string, onConfirm: () => void) => {
  useDeleteConfirm.setState({
    headline,
    onConfirm,
    isOpen:  true
  });
};