import { useState } from "react";
import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";
import { useNavigate } from "@tanstack/react-router";
import { Route } from "../route";
import { useCreateCampaignMutation } from "../../../../../../utils/data/campaignbasis";

const CreateCampaignModal = () => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate({
    from: `/companies/${params.companyId}/campaign/${params.campaignId}`,
  });
  const mutation = useCreateCampaignMutation(params.campaignId);

  function handleCreateCampaign(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    mutation.mutate(
      {
        id: Number(params.campaignId),
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/campaigns/$campaignId",
            search: (old) => ({
              ...old,
            }),
            params: { campaignId: data },
          });
          setOpen(false);
        },
      }
    );
  }

  return (
    <>
      <Button
        iconLeft={<MdAdd size={20} />}
        variant="filled"
        onClick={handleCreateCampaign}
      >
        Skapa kampanj
      </Button>
      <Dialog
        icon={<MdAdd />}
        open={open}
        onClose={handleClose}
        headline="Är du säker på att du vill skapa en kampanj utifrån valt
        kampanjunderlag?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={mutation.isPending}
            >
              Skapa kampanj
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default CreateCampaignModal;
