import { queryOptions, useMutation } from "@tanstack/react-query";
import { addOfferWorth, deleteOfferWorth, fetchOfferWorths, updateOfferWorth } from "./offerworths";
import { queryClient } from "../../../App";

export const offerWorthsQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["offerworths"],
    queryFn: () => fetchOfferWorths(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

  export const useUpdateOfferWorthMutation = (id: number) => {
    return useMutation({
      mutationKey: ["offerworth", "update", id],
      mutationFn: updateOfferWorth,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };
  
  export const useAddOfferWorthMutation = () => {
    return useMutation({
      mutationKey: ["offerworth", "add"],
      mutationFn: addOfferWorth,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };
  
  export const useDeleteOfferWorthMutation = () => {
    return useMutation({
      mutationKey: ["offerworth", "delete"],
      mutationFn: deleteOfferWorth,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };