import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchSettings, updateSetting } from "./systemsetting";
import { queryClient } from "../../../App";

export const settingsQueryOptions = (q: string = "", propertyName: string) =>
  queryOptions({
    queryKey: ["settings"],
    queryFn: () => fetchSettings(),
    select: (events) =>
      events.filter((event) => {
        if (propertyName in event) {
          const value = event[propertyName];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        }
        return false;
      }),
  });

  export const useUpdateSettingeMutation = (key: string) => {
    return useMutation({
      mutationKey: ["settings", "update", key],
      mutationFn: updateSetting,
      onSuccess: () => queryClient.invalidateQueries(),
    });
  };