import api from "../../../../../utils/api";

export const fetchConsumerEvents = async (id: number | string) => {
  const result = await api
    .get(`consumers/${id}/events`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export async function removeOfferEvent({
    consumerId,
    offerId,
  }: {offerId: number; consumerId:number}) {
    const result = await api
      .delete(`consumers/${consumerId}/events?offerId=${offerId}`)
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  }