import {
  useFormContext,
  Controller,
} from "react-hook-form";

import {
  Card,
  CardContent,
  CardHeader,
  NumberInput,
} from "@pnpm-monorepo/core/src/ui/components";

type CampaignCheckActivityMonthsBackType = {
  handleSubmit: () => void;
};

const CampaignCheckActivityMonthsBack: React.FC<CampaignCheckActivityMonthsBackType> = ({
  handleSubmit,
}) => {

  const { control } = useFormContext();

  return (
    <Card className="min-h-10">
      <CardHeader title="Välj aktiva konsumenter senaste månaderna" />
      <CardContent>
        <Controller
          name="checkActivityMonthsBack"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberInput
              value={value}
              error={!!error}
              onChange={(e) => {
                onChange(e);
                handleSubmit();
              }}
              max={36}
              name={"checkActivityMonthsBack"}
              className="bg-surface"
            />
          )}
        />
      </CardContent>
    </Card>
  );
};

export default CampaignCheckActivityMonthsBack;
