import { useState } from "react";
import { Button, Dialog, Icon } from "@pnpm-monorepo/core/src/ui/components";
import { Route } from "../route";
import { useQuery } from "@tanstack/react-query";
import { storeLogsQueryOptions } from "../../../../../../utils/data/store";
import DefaultPendingComponent from "../../../../../../components/PendingComponent";
import Logs from "../../../../../../components/Logs";

const LogDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  const params = Route.useParams();
  const storeLogsQuery = useQuery(storeLogsQueryOptions(params.storeId));

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        iconLeft={<Icon icon="history" />}
        variant="text"
        onClick={handleOpen}
      >
        Butikslogg
      </Button>
      <Dialog
        icon={<Icon icon="history" />}
        open={open}
        onClose={handleClose}
        headline="Butikslogg"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Stäng
            </Button>
          </>
        }
      >
        <div>
          {storeLogsQuery.isPending ? (
            <DefaultPendingComponent />
          ) : (
            <Logs data={storeLogsQuery.data} />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LogDialog;
