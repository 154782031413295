export default function DefaultPendingComponent() {
  return (
    <div
      id="pending-page"
      className="flex flex-col h-full w-fill justify-center items-center"
    >
      <div className="rounded-small p-4">
        <p className="text-label-large">Laddar</p>
      </div>
    </div>
  );
}
