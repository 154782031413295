import api from "../../../utils/api";
import {
  IndustryType,
  PlaceType,
  ProductType,
  SortOrder,
  StatusType,
} from "../../../utils/types";

export type OffersSortBy =
  | "number"
  | "companyName"
  | "updatedAt"
  | "productName"
  | "priority"
  | "statusName";

export const fetchOffers = async ({
  q,
  page,
  sortBy,
  sortOrder,
  place,
  status,
  product,
  category
}: {
  q?: string;
  page?: number;
  sortBy?: OffersSortBy;
  sortOrder?: SortOrder;
  place?: PlaceType[];
  status?: StatusType[];
  product?: ProductType[];
  category?: IndustryType[];
} = {}) => {
  const offer = await api
    .get(`offers`, {
      params: {
        search: q,
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        place: place?.join(","),
        status: status?.join(","),
        product: product?.join(","),
        category: category?.join(","),
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return offer;
};
