import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";
import { ContractType } from "../../types";
import { AddContractType, EditContractProductPlaceType } from "./types";

export const fetchContractById = async (contractId: number | string) => {
  const response = await api
    .get(`contracts/${contractId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export const fetchContractProductPlaces = async (
  contractId: number | string
) => {
  const response = await api
    .get(`contracts/${contractId}/products`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};

export async function addContract({
  companyId,
  ...updated
}: PickAsRequired<Partial<AddContractType>, "companyId">) {
  const response = await api
    .post(`contracts`, {
      companyId,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function updateContract({
  id,
  ...updated
}: PickAsRequired<Partial<ContractType>, "id">) {
  const response = await api
    .put(`contracts/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function addContractProductPlace({
  contractId,
  ...newValue
}: Partial<EditContractProductPlaceType>) {
  const response = await api
    .post(`contracts/${contractId}/products`, {
      ...newValue,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function editContractProductPlace({
  contractId,
  ...newValue
}: Partial<EditContractProductPlaceType>) {
  const response = await api
    .put(`contracts/${contractId}/products`, {
      ...newValue,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}