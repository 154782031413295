import { queryOptions } from "@tanstack/react-query";
import { ConsumersSortBy, fetchConsumers } from "./consumers";
import { PlaceType, SortOrder, StatusType } from "../../../utils/types";

export const consumersQueryOptions = (opts: {
    q?: string;
    page?: number;
    sortBy?: ConsumersSortBy;
    sortOrder?: SortOrder;
    place?: PlaceType[];
    status?: StatusType[];
  }) => {
    return queryOptions({
      queryKey: ["consumers", opts],
      queryFn: () => fetchConsumers(opts),
    });
  };