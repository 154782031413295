import { Link, Outlet, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery, useQuery } from "@tanstack/react-query";

import { ErrorComponent } from "../../../components/ErrorComponent";

import DefaultPendingComponent from "../../../components/PendingComponent";
import {
  companyIndustriesQueryOptions,
  companyStoresQueryOptions,
  offerActionTextsQueryOptions,
  offerPlacesQueryOptions,
  offerPrepositionsQueryOptions,
  offerProductsQueryOptions,
  offerQueryOptions,
  offerServiceTextsQueryOptions,
  offerWorthsQueryOptions,
} from "./-api/queryOptions";
import { IconButton, TopAppBar } from "@pnpm-monorepo/core/src/ui/components";
import { MdArrowBack } from "@pnpm-monorepo/core/src/ui/icons";
import Tabs from "../../../components/Tabs";
import LogDialog from "./-components/LogDialog";
import StatusChange2 from "./-components/StatusChange2";

export const Route = createFileRoute("/offers/$offerId")({
  loader: ({ context: { queryClient }, params: { offerId } }) => {
    queryClient.ensureQueryData(offerQueryOptions(offerId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: OfferComponent,
});

function OfferComponent() {
  const params = Route.useParams();
  const offerQuery = useSuspenseQuery(offerQueryOptions(params.offerId));
  const offer = offerQuery.data;

  // prefetch -- can this be done in route?
  useQuery(offerProductsQueryOptions(offer.id));
  useQuery(offerPlacesQueryOptions(offer.id));
  useQuery(companyStoresQueryOptions(offer?.company?.id));
  useQuery(offerActionTextsQueryOptions());
  useQuery(offerWorthsQueryOptions());
  useQuery(offerPrepositionsQueryOptions());
  useQuery(offerServiceTextsQueryOptions(offer?.company?.id));
  useQuery(companyIndustriesQueryOptions(offer?.company?.id));

  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        left={
          <Link to={"/offers"} search={true}>
            <IconButton icon={<MdArrowBack />} />
          </Link>
        }
        headline={<>Erbjudande {offer.number}</>}
        className="sticky top-0 z-50 bg-surface"
      />

      <div className="flex-1 flex flex-col lg:flex-row">
        <div className="p-6 flex flex-col gap-4 grow">
          <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "1",
                  header: "Inställningar",
                  to: "/offers/$offerId",
                  params: { offerId: params.offerId },
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "2",
                  header: "Konsumenthändelser",
                  to: "/offers/$offerId/events",
                  params: { offerId: params.offerId },
                  search: true,
                  activeOptions: { exact: true },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>

          <div className="flex justify-center gap-4 mx-auto w-full lg:max-w-screen-2xl">
            <div className="flex-1 flex flex-col ">
              <div className="flex justify-end mb-4 gap-2 items-center">
                <StatusChange2 offer={offer} />
                <LogDialog />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
