import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchCampaignSelection, removeCampaignOffer, updateCampaignSelection, addCampaignOffer } from "./selections";
import { queryClient } from "../../../../../App";

export const campignQuerySelectionOptions = (campaignId: number | string) =>
  queryOptions({
    queryKey: ["campaigns", "selections", campaignId],
    queryFn: () => fetchCampaignSelection(campaignId),
  });

  export const useUpdateRemoveCampaignOfferMutation = (campaignId: number | string) => {
    return useMutation({
      mutationKey: ["campaigns", "selection", "removeoffer", campaignId],
      mutationFn: removeCampaignOffer,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };

  export const useUpdateAddCampaignOfferMutation = (campaignId: number | string) => {
    return useMutation({
      mutationKey: ["campaigns", "selection", "addoffer", campaignId],
      mutationFn: addCampaignOffer,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };

  export const useUpdateCampaignSelectionMutation = (campaignId: number | string) => { 
    return useMutation({
      mutationKey: ["campaigns", "selection", campaignId],
      mutationFn: updateCampaignSelection,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };