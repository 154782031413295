import { PickAsRequired } from "@tanstack/react-router";
import api from "../../api";

export type UpdateChainCommandType = {
  id: number;
  name: string;
  active: boolean;
};

export type AddChainCommandType = {
  name: string;
  active: boolean;
};

export const fetchChains = async () => {
  const result = await api
    .get(`chains`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};


export async function updateChain({
  id,
  ...updated
}: PickAsRequired<Partial<UpdateChainCommandType>, "id">) {
  const result = await api
    .put(`chains/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addChain({ ...added }: AddChainCommandType) {
  const result = await api
    .post(`chains`, {
      ...added,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function deleteChain({ id }: { id: number }) {
  const result = await api
    .delete(`chains/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}