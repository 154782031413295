"use client";
import { Disclosure as HeadLessDisclosure } from "@headlessui/react";

import { cs } from "../../../utils";


import { DisclosureProps } from "./Disclosure.types";
import Icon from "../Icon";

const Disclosure: React.FC<DisclosureProps> = (props) => {
  const { defaultOpen = false, icon, header, children, className } = props;

  return (
    <HeadLessDisclosure defaultOpen={defaultOpen}>
      <>
        <HeadLessDisclosure.Button
          className={cs(
            "group bg-primary-container text-on-primary-container cursor-pointer px-3 w-full h-12 flex gap-2 items-center justify-start",
            "border-outline-variant border-t-[1px]",
            "last:rounded-medium last:rounded-t-none",
            "ui-open:border-b-[1px] ui-open:border-solid ui-open:h-18",
            "transition-[height] z-10",
            className && className
          )}
        >
          {icon && <div>{icon}</div>}
          <div className="text-body-large flex grow">{header && header}</div>
          <Icon icon="expand_more"  className="ui-open:rotate-180 ui-open:transform duration-200 ease-out text-on-primary-container" />
        </HeadLessDisclosure.Button>

        <HeadLessDisclosure.Panel className="py-3">
          {children}
        </HeadLessDisclosure.Panel>
      </>
    </HeadLessDisclosure>
  );
};

export default Disclosure;
