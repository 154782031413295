import {
  MdAdd,
  MdBusinessCenter,
  MdCampaign,
  MdEmojiPeople,
} from "@pnpm-monorepo/core/src/ui/icons";

import { Dialog, Button } from "@pnpm-monorepo/core/src/ui/components";
import { useAddNew } from "../../utils/useAddNew";

export const AddNewModal = () => {
  const addNew = useAddNew();

  return (
    <Dialog
      open={addNew.isOpen}
      onClose={addNew.onClose}
      headline="Lägg till"
      icon={<MdAdd />}
    >
      <div className="flex flex-col gap-2">
        <Button
          iconLeft={<MdBusinessCenter size={20} />}
          fullwidth
          variant="outlined"
        >
          Företag
        </Button>
        <Button
          iconLeft={<MdEmojiPeople size={20} />}
          fullwidth
          variant="outlined"
        >
          Inflyttad
        </Button>
        <Button
          iconLeft={<MdCampaign size={20} />}
          fullwidth
          variant="outlined"
        >
          Kampanj
        </Button>
      </div>
    </Dialog>
  );
};
