import { queryOptions, useMutation } from "@tanstack/react-query";
import { fetchRequests, updateRequestStatus } from "./request";
import { queryClient } from "../../../App";

export const requestsQueryOptions = (q: string = "", propertyNames: string[]) =>
  queryOptions({
    queryKey: ["requests"],
    queryFn: () => fetchRequests(),
    select: (events) =>
      events.filter((event) => {
        return propertyNames.some((propertyPath) => {
          // Split property path into parts (e.g., 'details.name' -> ['details', 'name'])
          const properties = propertyPath.split(".");

          // Traverse the object to get the nested property value
          let value = event;
          for (const prop of properties) {
            if (value && prop in value) {
              value = value[prop];
            } else {
              value = undefined;
              break;
            }
          }

          // Check if the value is a string and includes the search query
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(q.toLowerCase())
          );
        });
      }),
  });

  export const useUpdateRequestStatusMutation = (requestId: number) => {
    return useMutation({
      mutationKey: ["requests", "status", "update", requestId],
      mutationFn: updateRequestStatus,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };