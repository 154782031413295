import { keepPreviousData, queryOptions, useMutation } from "@tanstack/react-query";
import { PlaceType, SortOrder } from "../../../utils/types";
import {
  ReceviersSortBy,
  fetchReceivers,
  createReceiver,
} from "./receivers";
import { queryClient } from "../../../App";

export const receiversQueryOptions = (opts: {
  q?: string;
  page?: number;
  sortBy?: ReceviersSortBy;
  sortOrder?: SortOrder;
  isConsumer?: boolean;
  consumer?: string[];
  place?: PlaceType[];
}) => {
  return queryOptions({
    queryKey: ["receivers", opts.q, opts.page, opts.sortBy, opts.sortOrder, opts.place, opts.consumer],
    queryFn: () => fetchReceivers(opts),
    placeholderData: keepPreviousData,
  });
};

export const useCreateReceiverMutation = () => {
  return useMutation({
    mutationKey: ["receiver", "create"],
    mutationFn: createReceiver,
    onSuccess: () => queryClient.invalidateQueries(),
  });
};
