import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useEffect, useState } from "react";
import { Route } from "..";
import { useUpdateConsumerLoginMutation } from "../-api/queryOptions";

import { ConsumerType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ChangeConsumerLoginCommandType } from "../-api/consumers";
import { useSnackbar } from "notistack";

const schema = z.object({
  id: z.number(),
  userId: z.string(),
  email: z
    .string()
    .min(1, { message: "E-post/Login är obligatoriskt" })
    .email({ message: "Måste va en giltig e-post" }),
});
const ChangeLogin = ({ consumer }: { consumer: ConsumerType }) => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [canChange, setCanChange] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (consumer.userId.includes("auth0")) {
      setCanChange(false);
    } else {
      setCanChange(true);
    }
  }, [consumer.userId]);

  const mutation = useUpdateConsumerLoginMutation(params.consumerId);

  const { control, handleSubmit, reset, watch, setValue, register } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      id: consumer.id,
      userId: consumer.userId,
      email: consumer.email,
    },
  });

  const watchEmail = watch("email");

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    reset();
    mutation.reset();
  };

  const onSubmit: SubmitHandler<ChangeConsumerLoginCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("login/e-post uppdaterad");
          reset(data);
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button onClick={handleAdd}>Ändra</Button>
      </>

      <Dialog
        icon={<Icon icon={"login"} />}
        open={open}
        onClose={handleClose}
        headline="Ändra Epost/Login"
        text={
          !canChange ? (
            <>
              UserId {consumer.userId} innehåller &quot;auth0&quot;.
              login/e-post kan därför inte ändras.
            </>
          ) : null
        }
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Stäng
            </Button>
            {canChange && (
              <Button
                variant="filled"
                onClick={handleSubmit(onSubmit)}
                disabled={mutation.isPending}
              >
                Spara
              </Button>
            )}
          </>
        }
      >
        <div className="my-2">
          {mutation.error && (
            <div className="flex flex-col text-body-medium break-words my-2 mb-6 p-3 bg-error-container text-on-error-container">
              {Object.entries(mutation.error.response.data.errors).map(
                ([key, obj]) => (
                  <div key={key}>{obj[0]}</div>
                )
              )}
            </div>
          )}

          {/* {mutation.isSuccess && (
            <div className="flex text-body-medium mb-6 my-2 p-3 bg-tertiary-container text-on-tertiary-container">
              E-post/Login uppdaterad
            </div>
          )} */}

          {canChange && (
            <>
              <div className="text-label-medium text-on-surface-variant">
                E-post/Login
              </div>
              <div className="pt-2">
                <TextFieldWrapper
                  name="email"
                  control={control}
                  rightElement={
                    watchEmail ? (
                      <IconButton
                        onClick={() => {
                          setValue("email", "");
                          mutation.reset();
                        }}
                        size="small"
                        disabled={mutation.isPending}
                        icon={<Icon icon={"clear"} />}
                      />
                    ) : null
                  }
                />
                <input type="hidden" {...register("id")} />
                <input type="hidden" {...register("userId")} />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ChangeLogin;
