import { create } from "zustand";

type AddImageStore = {
  isOpen: boolean;
  name?: string;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: ((data: FormData) => void) | undefined;
};

export const useAddImage = create<AddImageStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  onConfirm: undefined,
}));

export const onAddImageConfirm = (
  onConfirm: (data: FormData) => void,
  name?: string
) => {
  useAddImage.setState({
    onConfirm,
    isOpen: true,
    name: name,
  });
};
