import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// merge classnames for tailwindcss
export function cs(...inputs: any[]) {
  return twMerge(clsx(inputs));
}

// helpers

export const isBrowser = typeof window !== "undefined";
export const isServer = typeof window === "undefined";
export { default } from "./debounce";

export { default as TextWithLineBreaks } from "./helpers/TextWithLineBreaks";

// hooks

export * from "./hooks/useCallbackRef";
export * from "./hooks/useLayoutEffect";
export * from "./hooks/useImageLoadingStatus";
export * from "./hooks/usePreviousProps";
export * from "./hooks/useLeadingLetters";

export { default as Pdf } from "./pdf";
export * from "./pdf";
